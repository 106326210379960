import { Auth } from 'aws-amplify';
import { CompanyRoleStatusState, TRoles } from 'features/authentication/store/interfaces';

export const getCompanyTokenData = async (): Promise<CompanyRoleStatusState[]> => {
  const currentSession = await Auth.currentSession();
  const idTokenInfo = currentSession.getIdToken().payload;
  const companyRoleStatus: { companyId: string; role: TRoles }[] = JSON.parse(
    idTokenInfo?.company_role_status,
  );
  return Promise.resolve(
    companyRoleStatus.map(item => ({ companyId: parseInt(item.companyId), role: item.role })),
  );
};
