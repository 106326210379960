import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { UploadTabContainer } from './UploadTab.styles';
import UploadTabForm from './UploadTabForm';
import UploadTabFilesList from './UploadTabFilesList';
import { EmployeeUploadFile } from './UploadTab.types';
import UploadTabErrorBlock from './UploadTabErrorBlock';
import {
  BULK_IMPORT_STEPS,
  FetchingStatus,
  ImportDataValidation,
  ImportedErrorEntry,
  ImportedEmployee,
  ImportedMultipleFieldErrors,
} from '@localTypes/index';
import EmployeeTable from '../EmployeeTable';
import { EmployeeManagementActions } from 'features/employeeManagement/store';
import { EmployeeManagementSelectors } from 'features/employeeManagement/store/employeeManagement.selectors';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { isFuture } from 'date-fns';
import { isValidBirthday } from '@utils/getUpdatedDate';

interface UploadTabProps {
  onValidEmployee: any;
  onSetFileName: any;
  handleSetFileInvalid: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadTab = ({ onValidEmployee, onSetFileName, handleSetFileInvalid }: UploadTabProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { bulkImportCsvFile } = useAppSelector(state => state.employeeManagement.api);
  const [files, setFiles] = useState<Array<EmployeeUploadFile>>([]);
  const [totalSelectedFiles, setTotalSelectedFiles] = useState<number>(0);
  const [tableValidation, setTableValidation] = useState<ImportDataValidation>({
    errorsArray: {},
    success: {},
    allData: {},
  });
  // TODO: Why are you using any here?
  const [error, setError] = useState<any>(false);
  const step = useAppSelector(EmployeeManagementSelectors.currentStep);
  const getBulkUploadFiles = useAppSelector(EmployeeManagementSelectors.getBulkUploadFiles);
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  let fileInput = createRef<HTMLInputElement>();

  useEffect(() => {
    if (activeCompany?.companyId) {
      dispatch(
        EmployeeManagementActions.getBulkUploadFile({ companyId: activeCompany?.companyId }),
      );
    }
  }, [activeCompany?.companyId, dispatch]);

  const handleDataResponse = useCallback(
    (data: ImportDataValidation) => {
      const validation = {
        errorsArray: data?.errorsArray,
        success: data?.success,
        allData: data?.allData,
      };
      setTableValidation(validation);
      onValidEmployee(validation);
    },
    [onValidEmployee],
  );

  useEffect(() => {
    if (
      getBulkUploadFiles.data?.companyBulkUpload &&
      getBulkUploadFiles.fetchingStatus === FetchingStatus.FULFILLED &&
      bulkImportCsvFile?.data &&
      bulkImportCsvFile.fetchingStatus === FetchingStatus.FULFILLED &&
      activeCompany?.companyId
    ) {
      dispatch(EmployeeManagementActions.setCurrentStep(BULK_IMPORT_STEPS.REVIEW_STEP));
      onSetFileName(
        getBulkUploadFiles.data?.companyBulkUpload?.filePath.split(
          `${activeCompany.companyId}/`,
        )[1],
      );
      handleDataResponse(bulkImportCsvFile.data);
    } else if (bulkImportCsvFile.error) {
      setError(bulkImportCsvFile.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBulkUploadFiles, bulkImportCsvFile, activeCompany?.companyId, dispatch]);

  const refreshPage = () => {
    setFiles([]);
    setError(false);
    dispatch(EmployeeManagementActions.setCurrentStep(BULK_IMPORT_STEPS.UPLOAD_STEP));
  };

  const handleUpload = useCallback(async () => {
    if (fileInput && fileInput.current && fileInput.current.files) {
      const companyId = activeCompany?.companyId;
      let fileList: FileList = fileInput.current.files;
      setTotalSelectedFiles(fileList.length);
      dispatch(EmployeeManagementActions.setCurrentStep(BULK_IMPORT_STEPS.UPLOAD_IN_PROGRESS));

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (companyId) {
          const filename = file?.name || '';
          let formData = new FormData();
          formData.append('file', file || '');
          setFiles([
            ...files.filter(f => f.filename !== filename),
            { progress: 0, selectedFile: file, filename },
          ]);
          try {
            await dispatch(
              EmployeeManagementActions.uploadCsvFile({
                payload: formData,
                onUploadProgressCallback: (progress: number) => {
                  setFiles([
                    ...files.filter(f => f.filename !== filename),
                    { progress, selectedFile: file, filename },
                  ]);
                },
              }),
            );
            if (activeCompany?.companyId) {
              dispatch(
                EmployeeManagementActions.getBulkUploadFile({
                  companyId: activeCompany?.companyId,
                }),
              );
            }
          } catch (error) {
            setError(error);
          }
        }
      }
    }
  }, [activeCompany?.companyId, dispatch, fileInput, files]);

  const checkRowDataIsInvalid = (
    error: ImportedErrorEntry | undefined,
    lineData: ImportedEmployee,
  ) => {
    if (lineData.dateOfBirth || lineData.startDate) {
      if (!moment(lineData.dateOfBirth).isValid() || !moment(lineData.startDate).isValid()) {
        return true;
      }

      if (lineData.startDate && !isFuture(new Date(lineData.startDate))) {
        return true;
      }

      if (lineData.dateOfBirth && !isValidBirthday(new Date(lineData.dateOfBirth))) {
        return true;
      }
    }

    if (
      error &&
      error.details &&
      Object.keys(error.details).length === 1 &&
      error.details.hasOwnProperty('gender') &&
      lineData['gender'] &&
      ['w', 'weiblich', 'female', 'f', 'm', 'männlich', 'male'].includes(
        lineData['gender'].toLowerCase(),
      )
    ) {
      return false;
    } else if (error) {
      return true;
    }
    return false;
  };

  const multipleErrorKey = 'firstName, lastName, dateOfBirth';

  const tableData: ImportedEmployee[] = useMemo(() => {
    if (tableValidation?.allData) {
      return Object.entries(tableValidation.allData).map(([line, row]) => {
        const fieldErrorsKeys = Object.keys(tableValidation?.errorsArray[line]?.details || {})
          .filter(key => multipleErrorKey.includes(key))
          .map(key => key.split(', '))
          .flat();
        const fieldErrorsText = Object.values(
          tableValidation?.errorsArray[line]?.details || {},
        ).join(', ');

        const isInvalid =
          tableValidation?.errorsArray?.[line]?.message?.includes('duplicate') ||
          tableValidation?.errorsArray?.[line]?.message?.includes('is_not_unique') ||
          checkRowDataIsInvalid(tableValidation?.errorsArray[line], tableValidation.allData[line]);

        return {
          ...row,
          isTemplateDuplicate: tableValidation?.errorsArray?.[line]?.message?.includes('duplicate'),
          isDatabaseDuplicate:
            tableValidation?.errorsArray?.[line]?.message?.includes('is_not_unique'),
          multipleFieldErrors: fieldErrorsKeys.reduce(
            (acc, key) => ({
              ...acc,
              [key]: fieldErrorsText,
            }),
            {} as ImportedMultipleFieldErrors,
          ),

          importedLineId: line,
          invalid: isInvalid,
        };
      });
    }
    return [];
  }, [tableValidation.allData, tableValidation?.errorsArray]);

  useEffect(() => {
    handleSetFileInvalid(tableData.some(row => row.invalid));
  }, [tableData, handleSetFileInvalid]);

  if (error) {
    return (
      <UploadTabContainer>
        <UploadTabErrorBlock
          onRefresh={refreshPage}
          message={
            error.code === 400
              ? formatMessage({
                  id: 'employee_management.bulk_upload.error.empty_file',
                  defaultMessage: 'Please check if the file is filled with data',
                })
              : error.code === 500
              ? formatMessage({
                  id: 'employee_management.bulk_upload.error.connection',
                  defaultMessage: 'It seems that the connection to server has been lost.',
                })
              : formatMessage({
                  id: 'employee_management.bulk_upload.error.server',
                  defaultMessage: 'Please, check your network connectivity and server status.',
                })
          }
        />
      </UploadTabContainer>
    );
  }

  return (
    <UploadTabContainer>
      <>
        {step === BULK_IMPORT_STEPS.UPLOAD_STEP ? (
          <UploadTabForm fileInput={fileInput} handleUpload={handleUpload} />
        ) : (
          ''
        )}

        {step === BULK_IMPORT_STEPS.UPLOAD_IN_PROGRESS ? (
          <UploadTabFilesList files={files} total={totalSelectedFiles} />
        ) : (
          ''
        )}

        {step === BULK_IMPORT_STEPS.REVIEW_STEP && tableData.length > 0 ? (
          <EmployeeTable data={tableData} dataValidation={tableValidation} />
        ) : (
          ''
        )}
      </>
    </UploadTabContainer>
  );
};

export default UploadTab;
