import { Box, Snackbar, styled } from '@mui/material';

export const SnackbarContainer = styled(Snackbar)(({ theme }) => ({
  top: '40px!important',
  '.MuiSnackbarContent-root': {
    backgroundColor: theme.palette.neutralLight.light,
    padding: 0,
    borderRadius: 4,
    boxShadow: `0 10px 10px 0 ${theme.palette.transparent.boxShadowDark01}`,
    minWidth: 120,
  },
  '.MuiSnackbarContent-message': {
    color: theme.palette.neutralDark.contrastText,
    fontFamily: 'Roboto',
    fontSize: 14,
    paddingLeft: 4,
    paddingRight: 14,
  },
}));

export const Message = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const SuccessIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',

  svg: {
    height: 14,
    fill: theme.palette.success.main,
  },
}));
