import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { EmptyTableWrapper, FileTemplate } from './EmptyTable.style';
import Spacer from '@components/Spacer';

export const EmptyCompanyCustomFieldTable = () => {
  const intl = useIntl();
  const templateHeaders = [
    intl.formatMessage({
      id: 'setting.custom_field.table.header.name',
      defaultMessage: 'Custom field name',
    }),
    intl.formatMessage({
      id: 'setting.custom_field.table.header.type',
      defaultMessage: 'Type',
    }),
    intl.formatMessage({
      id: 'setting.custom_field.table.header.language',
      defaultMessage: 'Language',
    }),
  ];
  return (
    <EmptyTableWrapper maxWidth='71.56%'>
      <FileTemplate>
        <thead>
          <tr>
            {templateHeaders.map((header, index) => (
              <th key={header} colSpan={index % 3 === 0 ? 2 : 1}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from(Array(templateHeaders.length).keys()).map((_item, index) => (
            <tr key={index}>
              {templateHeaders.map((_, i) => (
                <td key={i} colSpan={i % 3 === 0 ? 2 : 1} />
              ))}
            </tr>
          ))}
        </tbody>
      </FileTemplate>
      <Box
        position='absolute'
        top={0}
        left={0}
        width='100%'
        height='100%'
        display='flex'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'>
        <Typography variant='h2'>
          {intl.formatMessage({
            id: 'setting.custom_field.table.empty.title',
            defaultMessage: 'No custom fields created',
          })}
        </Typography>
        <Spacer size='md' />
        <Typography variant='caption'>
          {intl.formatMessage({
            id: 'setting.custom_field.table.empty.label',
            defaultMessage: 'Click on add new custom fields.',
          })}
        </Typography>
      </Box>
    </EmptyTableWrapper>
  );
};
