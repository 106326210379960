import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import { EmployeeStatus, EmployeeStatusBulkActionBody, PimcoreErrorResponse } from 'types';
import { showToast } from 'utils';

type EmployeeStatusBulkActionItem = {
  status: EmployeeStatus;
  endUserId: string;
  planedTransition: { status: string };
  transitions: Array<{ transition: string; requiredParameters: [] }>;
};

export const bulkStatusChange = createAsyncThunk<
  any,
  EmployeeStatusBulkActionBody,
  { rejectValue: PimcoreErrorResponse }
>('employeeManagement/bulkStatusChange', async (body, { rejectWithValue }) => {
  const { companyId, employeeTransitions } = body;
  const axios = await axiosWithConfig();
  const endpoint =
    process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/${companyId}/endusers/status`;

  try {
    const { data, status } = await axios.patch(endpoint, employeeTransitions, {
      headers: {
        'content-type': 'application/merge-patch+json',
      },
    });
    const bulkResponse: {
      changed: Array<EmployeeStatusBulkActionItem>;
      unchanged: Array<EmployeeStatusBulkActionItem>;
    } = { ...data, status };

    switch (employeeTransitions[0].transition) {
      case EmployeeStatus.DENIED:
        showToast(
          'success',
          {
            id: 'employee.transition.denied',
            defaultMessage: `Request has been denied. Employee was not added to the employee list`,
          },
          undefined,
        );
        break;
      case EmployeeStatus.CONFIRMED:
        showToast(
          'success',
          {
            id: 'employee.transition.confirmed',
            defaultMessage: `Request has been approved Employee has been added to the employee list`,
          },
          undefined,
        );
        break;
      case EmployeeStatus.PENDING:
        showToast(
          'success',
          {
            id: 'employee.transition.confirmed',
            defaultMessage: `Request has been approved Employee has been added to the employee list`,
          },
          undefined,
        );
        break;
      case EmployeeStatus.ACTIVE:
        showToast(
          'success',
          {
            id: 'employee.transition.start_date_changed',
            defaultMessage: `Start date was successfully changed`,
          },
          undefined,
        );
        break;
      case EmployeeStatus.PAUSED:
        showToast(
          'success',
          {
            id: 'employee.transition.pause_set',
            defaultMessage: `Pause was successfully set`,
          },
          undefined,
        );
        break;
      case EmployeeStatus.INACTIVE:
        showToast(
          'success',
          {
            id: 'employee.transition.deactivation_date_set',
            defaultMessage: `End date is set. Membership will be terminated`,
          },
          undefined,
        );
        break;
      case 'pause_ended':
        showToast(
          'success',
          {
            id: 'employee.transition.pause_removed',
            defaultMessage: `Pause has been removed`,
          },
          undefined,
        );
        break;
      case EmployeeStatus.UNASSIGNED:
        showToast(
          'success',
          bulkResponse.changed.every(item => item.planedTransition.status === 'delete')
            ? {
                id: 'employee.transition.bulk_remove_pending',
                defaultMessage: `The employees were removed`,
              }
            : bulkResponse.changed.some(item => item.planedTransition.status === 'delete')
            ? {
                id: 'employee.transition.bulk_remove_all',
                defaultMessage: `End date is set. The employees will be removed, some employees were removed immediately`,
              }
            : {
                id: 'employee.transition.bulk_remove',
                defaultMessage: `End date is set. The employees will be removed`,
              },
          undefined,
        );
        break;
    }

    return bulkResponse;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;
    showToast('error', {
      id: 'toast.bulk_status_change.error',
      defaultMessage: 'Error',
    });

    return rejectWithValue(data);
  }
});
