import { styled } from '@mui/material';
import { Box } from '@mui/system';

export const FileTemplate = styled('table')(({ theme }) => ({
  height: '100%',
  width: '100%',
  marginTop: '70px',
  marginBottom: '130px',
  borderSpacing: '7px',
  tableLayout: 'fixed',
  th: {
    fontSize: '12px',
    color: theme.palette.tableTemplate.fontColor,
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  td: {
    height: '30.6px',
    margin: '9.2px 8px 0 0',
    backgroundColor: theme.palette.tableTemplate.backgroundColor,
  },
}));

type EmptyTableWrapperProps = {
  maxWidth?: string;
};

export const EmptyTableWrapper = styled(Box, {
  shouldForwardProp: props => props !== 'maxWidth',
})<EmptyTableWrapperProps>(({ theme, maxWidth }) => ({
  height: 146,
  maxWidth: maxWidth ? maxWidth : 644,
  margin: '0 auto',
  position: 'relative',
  '&:before': {
    content: '""',
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: theme.palette.transparent.main,
  },
}));
