import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { SupportCardProps } from 'types/support';
import { useIntl } from 'react-intl';
import { CCPDefaultButton } from '@components/index';
import { useCallback } from 'react';

const SupportCard: React.FC<SupportCardProps> = ({ data }) => {
  const { language } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const { id, categoryName, categoryDescription, faqs } = data;

  const goToAllQuestions = useCallback(() => {
    navigate(`/${language}/support/${id}`);
  }, [navigate, language, id]);

  const renderFaqsList = faqs.slice(0, 3).map(item => (
    <Link
      key={item.id}
      to={`/${language}/support/${id}/articles/${item.id}`}
      className='questions__card_link'>
      {item.question}
    </Link>
  ));

  return (
    <Box className='questions__card'>
      <Box>
        <Typography className='questions__card_title' variant='h4'>
          {categoryName}
        </Typography>
        <Typography className='questions__card_text' variant='body1'>
          {categoryDescription}
        </Typography>
        {renderFaqsList}
      </Box>
      <CCPDefaultButton variant='contained' onClick={goToAllQuestions}>
        {intl.formatMessage({
          id: 'support.card.button.more',
          defaultMessage: 'See all questions',
        })}
      </CCPDefaultButton>
    </Box>
  );
};

export default SupportCard;
