import { EmployeeStatusResponse, IEUConditions, contractPermissionsName } from '@localTypes/index';
import { checkTypeParameter } from './contractDependencies';

export const checkShowButton = (
  activeContract: IEUConditions | null,
  contractPermissionName: contractPermissionsName,
  employeeStatusData: EmployeeStatusResponse | null,
  transitionName: string,
  planedTransitionArray: string[],
) => {
  if (!checkTypeParameter(activeContract, contractPermissionName)) {
    return false;
  }
  if (!employeeStatusData) {
    return false;
  }
  return (
    employeeStatusData.transitions.filter(item => item.transition === transitionName).length > 0 &&
    !planedTransitionArray.includes(employeeStatusData.planedTransition?.status || '')
  );
};
