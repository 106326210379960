import { EmployeeStatus, TransitionStatus } from '@localTypes/index';
import { manualFormatDateToString } from 'components/FormatDate';
import { useLanguage } from 'hooks';
import { useIntl } from 'react-intl';

export const useUserStatus = ({
  status,
  entryDate,
  isPlannedTransition,
}: {
  status: EmployeeStatus | TransitionStatus | '';
  entryDate?: string;
  isPlannedTransition?: boolean;
}) => {
  const intl = useIntl();
  const language = useLanguage();
  if (isPlannedTransition) {
    switch (status) {
      case TransitionStatus.PAUSE: {
        return intl.formatMessage(
          {
            id: 'user.status.paused_from',
            defaultMessage: 'Paused from {date}',
          },
          { date: entryDate ? manualFormatDateToString(entryDate, language) : '' },
        );
      }
      case TransitionStatus.PAUSED_UNLIMITED: {
        return intl.formatMessage(
          {
            id: 'user.status.suspending_from',
            defaultMessage: 'Membership suspended from {date}',
          },
          { date: entryDate ? manualFormatDateToString(entryDate, language) : '' },
        );
      }
      case TransitionStatus.DEACTIVATING:
      case TransitionStatus.DEACTIVATED:
      case TransitionStatus.DELETED: {
        return intl.formatMessage(
          {
            id: 'user.status.deactivating_from',
            defaultMessage: 'Deleted from {date}',
          },
          { date: entryDate ? manualFormatDateToString(entryDate, language) : '' },
        );
      }
      case TransitionStatus.PAUSED:
      case TransitionStatus.PENDING: {
        return intl.formatMessage(
          {
            id: 'user.status.pending',
            defaultMessage: 'Active from {date}',
          },
          { date: entryDate ? manualFormatDateToString(entryDate, language) : '' },
        );
      }
      default:
        return status || '';
    }
  }
  switch (status) {
    case EmployeeStatus.PENDING:
      return intl.formatMessage(
        {
          id: 'user.status.pending',
          defaultMessage: 'Active from {date}',
        },
        { date: entryDate ? manualFormatDateToString(entryDate, language) : '' },
      );
    case EmployeeStatus.ACTIVE:
    case EmployeeStatus.ELIGIBLE:
      return intl.formatMessage({ id: 'user.status.active', defaultMessage: 'Active' });
    case EmployeeStatus.LOCKED:
    case EmployeeStatus.BLOCKED:
      return intl.formatMessage({ id: 'user.status.blocked', defaultMessage: 'Blocked' });
    case EmployeeStatus.PAUSED:
      return intl.formatMessage({ id: 'user.status.paused', defaultMessage: 'Paused' });
    case EmployeeStatus.APPLIED:
      return intl.formatMessage({ id: 'user.status.applied', defaultMessage: 'Applied' });
    case EmployeeStatus.INACTIVE:
      return intl.formatMessage({ id: 'user.status.inactive', defaultMessage: 'Inactive' });
    case EmployeeStatus.DENIED:
      return intl.formatMessage({ id: 'user.status.denied', defaultMessage: 'Denied' });
    default:
      return status || '';
  }
};
