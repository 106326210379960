import { CircularProgress } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { LoaderWrapper } from './Loader.styles';

interface Props {
  show: boolean;
  inCard?: boolean;
  portalId?: string;
  transparentLoader?: boolean;
}
//for use loader in the card you need:
//pass inCard = true
//pass portalId - id of your card(where you want to show loader)
//add postion="relative" to your card element
const Loader: React.FC<Props> = ({
  show,
  inCard = false,
  portalId = '',
  transparentLoader = true,
}) => {
  useEffect(() => {
    if (!inCard && show) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      if (!inCard) document.body.style.overflow = 'auto';
    };
  }, [show, inCard]);

  const portalParent = inCard && portalId ? document.getElementById(portalId) : null;

  const classes = useMemo(() => {
    const classNames = [];
    if (inCard) {
      classNames.push('cardLoader');
    }
    if (transparentLoader) {
      classNames.push('transparentLoader');
    }
    return classNames.join(' ');
  }, [inCard, transparentLoader]);

  return show
    ? createPortal(
        <LoaderWrapper className={classes}>
          <CircularProgress />
        </LoaderWrapper>,
        portalParent ? portalParent : document.body,
      )
    : null;
};

export default Loader;
