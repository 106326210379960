import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { CCPDefaultButton, CCPSelect, CCPTextField, Spacer } from 'components';
import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import { useAppDispatch } from 'store/store';
import { S3Credentials } from 'types';
import { S3 } from 'aws-sdk';
import { S3CredentialsActions } from 'features/shared/s3Credentials/store';
import { MarketingMaterialAction } from 'features/marketingMaterials/store';
import { MaterialsManagementSelectors } from 'features/marketingMaterials/store/marketingMaterialsManagement.selector';
import { showToast } from 'utils';
import { useIntl } from 'react-intl';

const validationSchema = yup
  .object({
    filename: yup.string().required('filename is required'),
    category: yup.number().required('category is required'),
  })
  .required();

interface UploadDocumentModalProps {
  open: boolean;
  onClose: () => void;
}

const UploadDocumentModal: React.FC<UploadDocumentModalProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [file, setFile] = useState<File | null>(null);
  const { userInfo } = useSelector(AuthenticationSelectors.getAuthentication);
  const companyId = userInfo?.companyId || 0;
  const fileCategory = useSelector(MaterialsManagementSelectors.fileCategories) || [];
  const marketingMaterials = useSelector(MaterialsManagementSelectors.materialList);
  const language = marketingMaterials?.language;

  const methods = useForm({
    defaultValues: {
      filename: '',
      description: '',
      category: null,
      prefix: '',
      // file: null,
    },
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const { control, handleSubmit, formState, getValues, setValue, reset } = methods;
  const { isValid, isSubmitting } = formState;

  const { getRootProps, getInputProps } = useDropzone({
    // accept: {
    //   '*.pdf': ['.pdf'],
    // },
    maxFiles: 1,
    maxSize: 1024 * 1024 * 5,
    onDrop: async files => {
      if (files && files.length) {
        setFile(files[0]);
        setValue('prefix', Math.floor(Date.now() / 1000).toString());
      }
    },
    onDropRejected: Files => {
      console.log(Files);
      const message = Files[0]?.errors?.[0]?.message;
      showToast('error', message, 3000);
    },
    disabled: isSubmitting,
  });

  const uploadFileToS3 = (credentials: S3Credentials) => {
    if (!file) {
      return;
    }
    const { prefix } = getValues();
    const filename = prefix;
    const s3 = new S3({
      accessKeyId: credentials.credentials.AccessKeyId,
      secretAccessKey: credentials.credentials.SecretAccessKey,
      region: 'eu-central-1',
      sessionToken: credentials.credentials.SessionToken,
    });

    const params = {
      Bucket: process.env.REACT_APP_PIMCORE_S3!,
      Key: `assets/Companies/${companyId}/Documents/${language}/${filename}`,
      Body: file,
      ContentType: file.type,
      // ACL: 'public-read',
    };
    s3.upload(params, async function (err: Error, data: unknown) {
      if (err) throw err;
      const values = getValues();
      if (language && values.category && data) {
        const body = {
          description: values.description,
          filename,
          originalFilename: file.name,
          companyId,
          language,
          categoryId: values.category,
        };
        await dispatch(MarketingMaterialAction.addMarketingMaterial(body));
        setFile(null);
        onClose();
        reset();
      }
    });
  };

  const onSubmit = async () => {
    if (!file || isSubmitting) {
      return;
    }

    try {
      const credentialResponse = await dispatch(
        S3CredentialsActions.getS3CredentialsCompany({ companyId }),
      );
      if (credentialResponse.payload && 'credentials' in credentialResponse?.payload) {
        uploadFileToS3(credentialResponse.payload);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            {formatMessage({
              id: 'modal.upload.document.title',
              defaultMessage: 'UPLOAD DOCUMENT',
            })}
          </DialogTitle>
          <DialogContent>
            <Typography variant='subtitle1'>
              {formatMessage({
                id: 'modal.upload.document.subtitle',
                defaultMessage: 'You can also upload your own documents',
              })}
            </Typography>
            <Spacer size='xl' />

            <CCPTextField
              control={control}
              name='filename'
              label={formatMessage({
                id: 'modal.upload.document.file_name',
                defaultMessage: 'File Name',
              })}
            />

            <Spacer size='md' />
            <CCPTextField
              control={control}
              name='description'
              label={formatMessage({
                id: 'modal.upload.document.description',
                defaultMessage: 'Description(max. 60characters)',
              })}
            />
            <Spacer size='md' />
            <CCPSelect
              control={control}
              name='category'
              label={formatMessage({
                id: 'modal.upload.document.category',
                defaultMessage: 'Category',
              })}>
              {fileCategory?.map(category => (
                <MenuItem
                  key={category.id}
                  value={category.id}
                  selected={category.id === getValues('category')}>
                  {category.name}
                </MenuItem>
              ))}
            </CCPSelect>
            <Spacer size='md' />

            <div {...getRootProps()} className='dropzone'>
              <input {...getInputProps()} />
              {file ? (
                <p>{file.name}</p>
              ) : (
                <>
                  <p>Select file or add via drag & drop</p>
                  <CCPDefaultButton variant='contained'>
                    {formatMessage({
                      id: 'modal.upload.document.browse_files',
                      defaultMessage: 'Browse files',
                    })}
                  </CCPDefaultButton>
                </>
              )}
            </div>
          </DialogContent>

          <DialogActions>
            <CCPDefaultButton variant='outlined' onClick={onClose}>
              {formatMessage({
                id: 'modal.upload.document.btn.cancel',
                defaultMessage: 'Cancel',
              })}
            </CCPDefaultButton>
            <CCPDefaultButton
              disabled={!isValid || !file || isSubmitting}
              variant='contained'
              type='submit'>
              {formatMessage({
                id: 'modal.upload.document.btn.upload',
                defaultMessage: 'Upload',
              })}
            </CCPDefaultButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default UploadDocumentModal;
