import {
  Table,
  Paper,
  TableRow,
  TableCell,
  TableContainer,
  Box,
  CircularProgress,
} from '@mui/material';

import TableCellRow from './TableCellRow';
import { StyledTableHead, StyledTableBody, ShowAllDataWrapper } from './Table.styles';
import { useIntl } from 'react-intl';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { EmployeeManagementSelectors } from 'features/employeeManagement/store/employeeManagement.selectors';
import { EmployeeManagementActions } from 'features/employeeManagement/store';

export const MembershipTable = ({ employeeId }: { employeeId: number }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [showAllData, setShowAllData] = useState<boolean>(false);
  const employeeLicenseData = useAppSelector(EmployeeManagementSelectors.getEmployeeLicense);
  const allContracts = useAppSelector(state => state.contracts.getContracts.data);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleGetEmployeeData = async () => {
    if (!employeeId) {
      return;
    }
    setIsLoading(true);
    await dispatch(EmployeeManagementActions.getEmployeeLicense({ employeeId }));
    setIsLoading(false);
  };

  useEffect(() => {
    if (!employeeId) {
      return;
    }
    handleGetEmployeeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);

  const membershipData = useMemo(
    () =>
      employeeLicenseData?.data?.map(licenseItem => {
        let newItem = { ...licenseItem };
        const currentContract = allContracts.find(
          contract => contract.id === licenseItem.contractId,
        );
        if (currentContract) {
          newItem['product'] = currentContract.products.reduce(
            (result: { name: string }[], currentProduct) => {
              result.push({ name: currentProduct.name });
              return result;
            },
            [],
          );
        }

        return newItem;
      }) || [],
    [employeeLicenseData.data, allContracts],
  );

  const data = useMemo(() => {
    const sortMembershipData = [...membershipData].sort((firstLicense, secondLicense) =>
      firstLicense.sequenceNumber > secondLicense.sequenceNumber ? -1 : 1,
    );
    if (showAllData || sortMembershipData?.length < 7) {
      return sortMembershipData;
    }
    return sortMembershipData.slice(0, 7);
  }, [showAllData, membershipData]);

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell className='table_cell'>
                {intl.formatMessage({
                  id: 'employee.membership.table.header.event',
                  defaultMessage: 'Event',
                })}
              </TableCell>
              <TableCell className='table_cell'>
                {intl.formatMessage({
                  id: 'employee.membership.table.header.date',
                  defaultMessage: 'Date',
                })}
              </TableCell>
              <TableCell className='table_cell'>
                {intl.formatMessage({
                  id: 'employee.membership.table.header.product',
                  defaultMessage: 'Product',
                })}
              </TableCell>
            </TableRow>
          </StyledTableHead>
          {!isLoading && (
            <StyledTableBody>
              {data?.map(item => (
                <TableCellRow key={item.sequenceNumber} item={item} />
              ))}
            </StyledTableBody>
          )}
        </Table>
        {!isLoading && membershipData?.length > 7 && !showAllData && (
          <ShowAllDataWrapper onClick={() => setShowAllData(true)}>
            {intl.formatMessage({
              id: 'employee.membership.table.show_all',
              defaultMessage: 'Show all history',
            })}
          </ShowAllDataWrapper>
        )}
      </TableContainer>
      {isLoading && (
        <Box display='flex' justifyContent='center' mt={2}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
