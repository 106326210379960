import AWS, { S3 } from 'aws-sdk';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { useAppDispatch } from 'store';
import { getS3Credential } from 'utils/getS3Credential';
import { InvoicesListProps } from 'types';
import { S3CredentialsActions } from 'features/shared/s3Credentials/store';
import { jsonReg, binArrayToJson } from 'utils';

import InvoicesTable from './InvoicesTable';
import InvoicesNoData from './InvoicesNoData';
import InvoicesLoading from './InvoicesLoading';
import { InvoicesWrapper } from './Invoices.styles';
import { useIntl } from 'react-intl';
import { CompanySelectors } from 'features/company/store/company.selectors';
import moment from 'moment';
import { CCPDefaultButton, Spacer } from '@components/index';

const Invoices = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { language } = useParams();
  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invoicesList, setInvoicesList] = useState<InvoicesListProps | null>(null);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

  const getS3Invoices = async (companyId: number) => {
    try {
      setIsLoading(true);
      const s3Response = await dispatch(
        S3CredentialsActions.getS3CredentialsCompany({
          companyId,
        }),
      ).unwrap();
      if (!s3Response) {
        return;
      }
      const s3Invoice = getS3Credential({ data: s3Response, type: 'invoice' });

      if (!s3Invoice) {
        return;
      }

      const { AccessKeyId, SecretAccessKey, SessionToken } = s3Invoice;

      const s3 = new S3({
        region: 'eu-central-1',
        accessKeyId: AccessKeyId,
        secretAccessKey: SecretAccessKey,
        sessionToken: SessionToken,
      });

      const params: any = {
        Bucket: s3Invoice.s3Url?.split('/')[2],
        Prefix: `company/${companyId}/`,
        Marker: `company/${companyId}/`,
      };

      s3.listObjects(params, (err, data: any) => {
        if (err) {
          setIsLoading(false);
          console.log(err, 'err');
        } else {
          setIsLoading(false);
          const filteredList = data?.Contents?.map((invoice: { Key: string }) => invoice.Key);
          setInvoicesList({
            bucketName: data.Name,
            data: filteredList,
            s3Credential: { AccessKeyId, SecretAccessKey, SessionToken },
          });
        }
      });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const convertFileInformation = async (key: string) => {
    const s3 = new AWS.S3({
      accessKeyId: invoicesList?.s3Credential.AccessKeyId,
      secretAccessKey: invoicesList?.s3Credential.SecretAccessKey,
      region: 'eu-central-1',
      sessionToken: invoicesList?.s3Credential.SessionToken,
    });
    const params: any = {
      Bucket: invoicesList?.bucketName,
      Key: key,
      ResponseContentType: 'application/json',
    };
    const file = await s3.getObject(params).promise();

    if (!file.Body) return;
    const fileInformation = binArrayToJson(file.Body as Uint8Array);

    return { file, fileInformation };
  };

  const filterInvoicesList = async () => {
    const jsonList = invoicesList?.data?.filter(
      invoice =>
        jsonReg.test(invoice) &&
        invoicesList?.data.filter(
          inv => inv.toUpperCase() === invoice.toUpperCase().replace('.JSON', '.PDF'),
        ).length === 1,
    );

    const convertJsonList: any = jsonList?.map(item => convertFileInformation(item));

    const filteredJsonList = await Promise.all(convertJsonList);

    const newFilteredList: any = filteredJsonList?.map((item: any, index) => {
      const invoiceId = item.fileInformation['invoice-id'];
      const invoicePeriod = item.fileInformation['invoice-data']['invoice-service-period'];
      const invoiceType = item.fileInformation['invoice-type'];
      const invoiceDate = item.fileInformation['invoice-date'];

      return {
        id: invoiceId,
        invoiceId,
        period: moment.utc(invoicePeriod).format('YYYY-MM-DD'),
        invoiceType,
        dateIssue: moment.utc(invoiceDate).format('YYYY-MM-DD'),
        file: item.file,
        index,
      };
    });

    setData(newFilteredList);
    setIsDataLoading(true);
  };

  useEffect(() => {
    if (activeCompany?.companyId) {
      getS3Invoices(activeCompany.companyId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  useEffect(() => {
    if (invoicesList) {
      filterInvoicesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesList]);

  return (
    <InvoicesWrapper>
      <Box className='header'>
        <Typography variant='h2'>
          {intl.formatMessage({ id: 'navigation.invoices', defaultMessage: 'Invoices' })}
        </Typography>
        <Link to={`/${language}/settings/0`}>
          <CCPDefaultButton variant='outlined'>
            {intl.formatMessage({
              id: 'view.invoice.go_to',
              defaultMessage: 'Go to distribution settings',
            })}
          </CCPDefaultButton>
        </Link>
      </Box>
      <Spacer size='lg' />
      {!isLoading && isDataLoading ? (
        data.length ? (
          <InvoicesTable invoicesList={invoicesList} data={data} />
        ) : (
          <InvoicesNoData />
        )
      ) : (
        <InvoicesLoading />
      )}
    </InvoicesWrapper>
  );
};

export default Invoices;
