import { Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useIntl } from 'react-intl';
import { default as theme } from '@theme/theme';
import { TableErrorDataWrapper } from './TableErrorData.styles';

export const TableErrorData = () => {
  const intl = useIntl();
  return (
    <TableErrorDataWrapper>
      <ErrorOutlineIcon htmlColor={theme.palette.error.main} />
      <Typography variant='body1' className='title'>
        {intl.formatMessage({
          id: 'statistics.error_data',
          defaultMessage: 'No data at the moment',
        })}
      </Typography>
      <Typography variant='body1' className='text'>
        {intl.formatMessage({
          id: 'statistics.error_data.content',
          defaultMessage: 'Sorry, something went wrong, please try again later',
        })}
      </Typography>
    </TableErrorDataWrapper>
  );
};
