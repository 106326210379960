import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../../initialState';
import { CompanyResponse, Employee, EmployeeManagementState, BULK_IMPORT_STEPS } from 'types';
import { GridFilterModel } from '@mui/x-data-grid-pro';

export const resetGetEmployeesStatus: CaseReducer<EmployeeManagementState> = state => {
  state.api.getEmployees.fetchingStatus = initialState.api.getEmployees.fetchingStatus;
};

export const resetAddEmployeeApi: CaseReducer<EmployeeManagementState> = state => {
  state.api.addEmployee = initialState.api.addEmployee;
};

export const resetChangeEmployeeApi: CaseReducer<EmployeeManagementState> = state => {
  state.api.changeEmployee = initialState.api.changeEmployee;
};

export const resetApi: CaseReducer<EmployeeManagementState> = state => {
  state.api = initialState.api;
};

export const resetBulkImportCsvFile: CaseReducer<EmployeeManagementState> = state => {
  state.api.bulkImportCsvFile = initialState.api.bulkImportCsvFile;
  state.api.getBulkUploadFile = initialState.api.getBulkUploadFile;
};

export const setInitialTableConfiguration: CaseReducer<
  EmployeeManagementState,
  PayloadAction<{ company: CompanyResponse; employees: Employee[] }>
> = (state, action) => {
  const { id, customFields } = action.payload.company;
  const employees = action.payload.employees;
  const columnsFromLocalStorage = sessionStorage.getItem(
    `ccp_employee_table_configuration_columns_${id}`,
  );
  const rowsPerPageFromLocalStorage = sessionStorage.getItem(
    `ccp_employee_table_configuration_rows_per_page_${id}`,
  );
  const filtersFromLocalStorage = sessionStorage.getItem(
    `ccp_employee_table_configuration_filters_${id}`,
  );

  let columns: { [key: string]: boolean } = {};
  let rowsPerPage: number = -1;
  let filters: any = {};

  if (rowsPerPageFromLocalStorage) {
    rowsPerPage = parseInt(rowsPerPageFromLocalStorage);
  } else {
    rowsPerPage = 25;
  }

  if (columnsFromLocalStorage) {
    columns = JSON.parse(columnsFromLocalStorage);
  } else if (!columnsFromLocalStorage && Object.keys(employees).length > 0) {
    const initialColumns: { [key: string]: boolean } = {};
    if (employees.length > 0) {
      const properties = Object.keys(employees[0]);

      properties.forEach(property => {
        if (property !== 'customFields' && property !== 'lastName') {
          if (property === 'firstName') {
            initialColumns['fullName'] = true;
          } else if (property === 'id') {
            initialColumns['userId'] = true;
          } else {
            initialColumns[property] = true;
          }
        }
      });

      customFields.forEach(customField => {
        initialColumns[customField.key] = true;
      });
    }

    columns = initialColumns;
  }

  if (filtersFromLocalStorage) {
    filters = JSON.parse(filtersFromLocalStorage);
  } else {
    filters = { items: [] };
  }

  state.tableConfigurations[id] = {
    rowsPerPage,
    currentPage: 0,
    filters,
    columns,
    searchData: { hasVisibleSearchField: false, searchTerm: '' },
  };
};

export const resetTableConfiguration: CaseReducer<
  EmployeeManagementState,
  PayloadAction<{ company: CompanyResponse; employees: Employee[] }>
> = (state, action) => {
  const { id, customFields } = action.payload.company;
  const employees = action.payload.employees;
  const columns: { [key: string]: boolean } = {};
  const rowsPerPage = 25;
  const filters = { items: [] };
  const searchData = { hasVisibleSearchField: false, searchTerm: '' };

  if (employees.length > 0) {
    const properties = Object.keys(employees[0]);

    properties.forEach(property => {
      if (property !== 'customFields' && property !== 'lastName') {
        if (property === 'firstName') {
          columns['fullName'] = true;
        } else if (property === 'id') {
          columns['userId'] = true;
        } else {
          columns[property] = true;
        }
      }
    });
  }

  customFields.forEach(customField => {
    columns[customField.key] = true;
  });

  state.tableConfigurations[id] = {
    rowsPerPage,
    currentPage: 0,
    filters,
    columns,
    searchData,
  };

  sessionStorage.setItem(
    `ccp_employee_table_configuration_columns_${id}`,
    JSON.stringify(state.tableConfigurations[id].columns),
  );

  sessionStorage.setItem(
    `ccp_employee_table_configuration_rows_per_page_${id}`,
    JSON.stringify(rowsPerPage),
  );

  sessionStorage.setItem(
    `ccp_employee_table_configuration_filters_${id}`,
    JSON.stringify(state.tableConfigurations[id].filters),
  );
};

export const toggleColumn: CaseReducer<
  EmployeeManagementState,
  PayloadAction<{ companyId: number; property: string; visible: boolean }>
> = (state, action) => {
  const { companyId, property, visible } = action.payload;
  state.tableConfigurations[companyId].columns[property] = visible;
  sessionStorage.setItem(
    `ccp_employee_table_configuration_columns_${companyId}`,
    JSON.stringify(state.tableConfigurations[companyId].columns),
  );
};

export const toggleAllColumns: CaseReducer<
  EmployeeManagementState,
  PayloadAction<{ companyId: number; visible: boolean }>
> = (state, action) => {
  const { companyId, visible } = action.payload;
  const properties = Object.keys(state.tableConfigurations[companyId].columns);

  const toggledProperties: { [key: string]: boolean } = {};
  properties.forEach(property => {
    toggledProperties[property] = visible;
  });

  sessionStorage.setItem(
    `ccp_employee_table_configuration_columns_${companyId}`,
    JSON.stringify(toggledProperties),
  );
  state.tableConfigurations[companyId].columns = toggledProperties;
};

export const updateRowsPerPage: CaseReducer<
  EmployeeManagementState,
  PayloadAction<{ companyId: number; rowsPerPage: number }>
> = (state, action) => {
  const { companyId, rowsPerPage } = action.payload;
  state.tableConfigurations[companyId].rowsPerPage = rowsPerPage;
  sessionStorage.setItem(
    `ccp_employee_table_configuration_rows_per_page_${companyId}`,
    JSON.stringify(rowsPerPage),
  );
};

export const updateCurrentPage: CaseReducer<
  EmployeeManagementState,
  PayloadAction<{ companyId: number; currentPage: number }>
> = (state, action) => {
  const { companyId, currentPage } = action.payload;
  state.tableConfigurations[companyId].currentPage = currentPage;
};

export const updateFilters: CaseReducer<
  EmployeeManagementState,
  PayloadAction<{
    companyId: number;
    model: GridFilterModel;
  }>
> = (state, action) => {
  const { companyId, model } = action.payload;

  state.tableConfigurations[companyId].filters = model;

  sessionStorage.setItem(
    `ccp_employee_table_configuration_filters_${companyId}`,
    JSON.stringify(state.tableConfigurations[companyId].filters),
  );
};

export const resetFilters: CaseReducer<
  EmployeeManagementState,
  PayloadAction<{ companyId: number }>
> = (state, action) => {
  const { companyId } = action.payload;
  state.tableConfigurations[companyId].filters = { items: [] };
  state.tableConfigurations[companyId].searchData = {
    hasVisibleSearchField: false,
    searchTerm: '',
  };
  if (state.tableConfigurations[companyId].currentPage !== 0) {
    state.tableConfigurations[companyId].currentPage = 0;
  }

  sessionStorage.setItem(
    `ccp_employee_table_configuration_filters_${companyId}`,
    JSON.stringify(state.tableConfigurations[companyId].filters),
  );
};

export const updateSearchTerm: CaseReducer<
  EmployeeManagementState,
  PayloadAction<{ companyId: number; searchTerm: string }>
> = (state, action) => {
  const { companyId, searchTerm } = action.payload;
  state.tableConfigurations[companyId].searchData.searchTerm = searchTerm;
  if (state.tableConfigurations[companyId].currentPage !== 0) {
    state.tableConfigurations[companyId].currentPage = 0;
  }
};

export const updateSearchVisibility: CaseReducer<
  EmployeeManagementState,
  PayloadAction<{ companyId: number; hasVisibleSearchField: boolean }>
> = (state, action) => {
  const { companyId, hasVisibleSearchField } = action.payload;
  state.tableConfigurations[companyId].searchData.hasVisibleSearchField = hasVisibleSearchField;
};

export const resetSearchData: CaseReducer<
  EmployeeManagementState,
  PayloadAction<{ companyId: number }>
> = (state, action) => {
  const { companyId } = action.payload;
  state.tableConfigurations[companyId].searchData = {
    hasVisibleSearchField: false,
    searchTerm: '',
  };
  if (state.tableConfigurations[companyId].currentPage !== 0) {
    state.tableConfigurations[companyId].currentPage = 0;
  }
};

export const setCurrentStep: CaseReducer<
  EmployeeManagementState,
  PayloadAction<BULK_IMPORT_STEPS>
> = (state, { payload }) => {
  state.currentStep = payload;
};

export const setAddEmployeeModalOpen: CaseReducer<
  EmployeeManagementState,
  PayloadAction<{ isOpen: boolean }>
> = (state, action) => {
  const { isOpen } = action.payload;
  state.addEmployeeModalOpen = isOpen;
};
