import { styled, Box } from '@mui/material';

export const SupportArticlesWrapper = styled(Box)(({ theme }) => ({
  '.breadcrumb': {
    gap: 6,
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',

    '&__item': {
      fontSize: 14,
      lineHeight: 1.57,
      letterSpacing: 0.13,
      color: theme.palette.primary.main,
      textDecoration: 'none',

      '&:last-child': {
        color: theme.palette.neutralDark.contrastText,
      },
    },

    div: {
      width: 'auto',
    },
  },

  '.content': {
    background: theme.palette.neutralLight.light,
    padding: 36,
    borderRadius: 4,
  },

  '.wrapper': {
    padding: '44px 156px 0',
  },

  '.form': {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 60,

    '&__title': {
      fontFamily: 'NutmegHeadline-UltraBlack',
      fontSize: 14,
      fontWeight: 900,
      lineHeight: 1.57,
      color: theme.palette.neutralDark.contrastText,
      textTransform: 'uppercase',
    },

    '&__wrapper': {
      maxWidth: 466,
      textAlign: 'center',

      '&_subtitle': {
        marginBottom: 36,

        fontSize: 14,
        lineHeight: 1.14,
        letterSpacing: 0.18,
        color: theme.palette.neutralDark.main,
      },

      '&_text': {
        margin: '24px 0 42px',
      },

      '&_field': {
        display: 'block',
        marginBottom: 30,

        '&_subtitle': {
          fontSize: 14,
          lineHeight: 1.14,
          letterSpacing: 0.18,
          color: theme.palette.neutralDark.main,
        },

        div: {
          width: '100%',
        },
      },
    },

    '&__footer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      svg: {
        maxWidth: 164,
      },
    },
  },

  '.banner': {
    margin: '40px 0 80px',
  },

  '.title': {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 20,
    fontWeight: 900,
    lineHeight: 1.6,
    color: theme.palette.neutralDark.contrastText,
    textTransform: 'uppercase',
  },

  '.answer': {
    fontSize: 18,
    lineHeight: 1.44,
    color: theme.palette.neutralDark.main,
    marginBottom: 20,
    marginTop: 20,
  },

  button: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.38,
    boxShadow: 'unset',
    textTransform: 'unset',
    padding: '7px 0px',
    borderRadius: 18.5,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
