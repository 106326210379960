import { Auth } from 'aws-amplify';
import { AxiosError } from 'axios';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { SerializedError, createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { SignInRequestParams, UserManagementBaseResponse } from '@localTypes/index';

const signIn = createAsyncThunk<CognitoUser, SignInRequestParams, { rejectValue: SerializedError }>(
  'authentication/signIn',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await Auth.signIn(email, password);
      Sentry.setUser({ email });

      return response;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;

      return rejectWithValue({ message: error.message });
    }
  },
);

export default signIn;
