import { styled, Box, TableHead, TableBody, TableRow } from '@mui/material';

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '.table_cell': {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '23px',
    color: theme.palette.neutralDark.main,
    padding: '14px 24px 12px',
    borderBottom: `2px solid ${theme.palette.primaryLight.main}`,
    textTransform: 'uppercase',
  },
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  '.title': {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '22px',
    color: theme.palette.neutralDark.contrastText,
    padding: '21px 24px',
    borderBottom: `2px solid ${theme.palette.primaryLight.main}`,
  },

  '.provider': {
    fontSize: 16,
    lineHeight: '22px',
    color: theme.palette.neutralDark.contrastText,
    padding: '21px 24px',
    borderBottom: `2px solid ${theme.palette.primaryLight.main}`,
  },

  '.MuiTableRow-root': {
    borderRadius: 'unset',
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child': {
    '.title': {
      lineHeight: '21px',
    },

    '.provider': {
      lineHeight: '21px',
    },
  },

  '.green': {
    color: theme.palette.success.main,
  },
}));

export const PaginationWrapper = styled(Box)(({ theme }) => ({
  borderTop: `2px solid ${theme.palette.primaryLight.main}`,
  display: 'flex',
  padding: '14px 16px',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.results': {
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
  },
}));
