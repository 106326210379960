import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
  persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { rootReducer } from './rootReducer';

import { AppDispatch, RootState } from 'types';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    'auth',
    'stats',
    'support',
    'invoices',
    'settings',
    's3Credentials',
    'userManagement',
    'initialPassword',
    'selfRegistration',
    'employeeManagement',
    'marketingMaterialsManagement',
    'tablesManagement',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer());

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          'authentication/signIn/pending',
          'authentication/signIn/fulfilled',
          'authentication/signIn/rejected',
        ],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
