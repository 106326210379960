import { TableCell } from '@mui/material';

import { activeMembersTableProps, TranslationsKeys } from 'types';

import { StyledTableRow } from './MembersTable.styles';
import { FormatDate } from 'components/FormatDate';

export interface TableCellRowProps {
  data: activeMembersTableProps;
  borderEnd: boolean;
  activeLanguage?: TranslationsKeys;
}

const TableCellRow: React.FC<TableCellRowProps> = ({ data, borderEnd, activeLanguage }) => {
  const { customer_id, first_name, last_name, registration_date } = data;
  const fullName = `${first_name} ${last_name}`;
  const formattedDate = FormatDate(registration_date);

  return (
    <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: borderEnd ? '' : 0 } }}>
      <TableCell className='provider'>{customer_id}</TableCell>
      <TableCell className='title notranslate'>{fullName}</TableCell>
      <TableCell className='provider'>{formattedDate}</TableCell>
    </StyledTableRow>
  );
};

export default TableCellRow;
