import { TableCell } from '@mui/material';

import { formatNumber } from 'utils';
import { CheckInsTableProps, TranslationsKeys } from 'types';

import { StyledTableRow } from './CheckInsTable.styles';

export interface TableCellRowProps {
  data: CheckInsTableProps;
  borderEnd: boolean;
  activeLanguage?: TranslationsKeys;
}

const TableCellRow: React.FC<TableCellRowProps> = ({ data, borderEnd, activeLanguage }) => {
  const { checkins, eus, partner_name } = data;

  return (
    <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: borderEnd ? '' : 0 } }}>
      <TableCell className='title notranslate'>{partner_name}</TableCell>
      <TableCell className='title'> {formatNumber(eus)}</TableCell>
      <TableCell className='title'>{formatNumber(checkins)}</TableCell>
    </StyledTableRow>
  );
};

export default TableCellRow;
