import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { updateLanguageExtraReducer } from './actions/async/updateLanguage/updateLanguage.reducer';
import { selfRegistration } from './actions/sync/selfRegistration.actions';
import { getRegistrationUsersExtraReducer } from './actions/async/getRegistrationUsers/getRegistrationUsers.reducer';
import { getRegistrationSettingsExtraReducer } from './actions/async/getRegistrationSettings/getRegistrationSettings.reducer';
import { addNewEmployeeInvitationExtraReducer } from './actions/async/addNewEmployeeInvitation/addNewEmployeeInvitation.reducer';
import { removeEmployeeInvitationExtraReducer } from './actions/async/removeEmployeeInvitation/removeEmployeeInvitation.reducer';
import { getDistributionListExtraReducer } from './actions/async/getDistributionList/getDistributionList.reducer';
import { setRegistrationSettingsExtraReducer } from './actions/async/setRegistrationSettings/setRegistrationSettings.reducer';
import { getOwnUserDataExtraReducer } from './actions/async/getOwnUserData/getOwnUserData.reducer';
import { changeOwnUserDataExtraReducer } from './actions/async/changeOwnUserData/changeOwnUserData.reducer';
import { getCompanyCustomFieldReducer } from './actions/async/getCompanyCustomFIeld/getCompanyCustomField.reducer';
import { addCompanyCustomFieldExtraReducer } from './actions/async/addCompanyCustomField/addCompanyCustomField.reducer';
import { deleteCompanyCustomFieldExtraReducer } from './actions/async/deleteCompanyCustomField/deleteCompanyCustomField.reducer';
import { updateCompanyCustomFieldExtraReducer } from './actions/async/updateCompanyCustomField/updateCompayCustomField.reducer';
import { getSettingsReducer } from './actions/async/getSettings/getSettings.reducer';
import updateRegistrationFormExtraReducer from './actions/async/spUpdateRegistrationForm/updateRegistrationForm.reducer';
import updateLandingSettingsExtraReducer from './actions/async/spUpdateLandingSettings/updateLandingSettings.reducer';
import updateGeneralSettingsExtraReducer from './actions/async/spUpdateGeneralSettings/updateGeneralSettings.reducer';
import { setRegistrationIdentifierExtraReducer } from './actions/async/setRegistrationIdentifier/setRegistrationIdentifier.reducer';
import { updateSettingsExtraReducer } from './actions/async/updateSettings/updateSettings.reducer';

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    selfRegistration,
  },
  extraReducers: builder => {
    updateLanguageExtraReducer(builder);
    getDistributionListExtraReducer(builder);
    getRegistrationUsersExtraReducer(builder);
    getRegistrationSettingsExtraReducer(builder);
    setRegistrationIdentifierExtraReducer(builder);
    setRegistrationSettingsExtraReducer(builder);
    addNewEmployeeInvitationExtraReducer(builder);
    removeEmployeeInvitationExtraReducer(builder);
    getOwnUserDataExtraReducer(builder);
    changeOwnUserDataExtraReducer(builder);
    getCompanyCustomFieldReducer(builder);
    addCompanyCustomFieldExtraReducer(builder);
    deleteCompanyCustomFieldExtraReducer(builder);
    updateCompanyCustomFieldExtraReducer(builder);
    getSettingsReducer(builder);
    updateRegistrationFormExtraReducer(builder);
    updateLandingSettingsExtraReducer(builder);
    updateGeneralSettingsExtraReducer(builder);
    updateSettingsExtraReducer(builder);
  },
});

export const { name, actions } = settingsSlice;
export const settingsReducer = settingsSlice.reducer;
