import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { TableRow, Typography, TableCell, Box } from '@mui/material';
import { default as theme } from '@theme/theme';

import { PermissionsChipGroup } from 'components';
import { CompanyRole, IUserInfo, ListType, OwnUserData } from 'types';
import { useAppDispatch, useAppSelector } from 'store';
import { useIntl } from 'react-intl';
import EditNotificationsModal from 'components/Modal/EditNotifications';
import { SettingsActions } from 'features/settings/store';
import { useNotificationsSettingsList } from 'utils';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';

export const tableColumns = [
  { columnName: 'companyName', columnTitle: 'Company name', intlId: 'user.info.company_name' },
  {
    columnName: 'notifications',
    columnTitle: 'Notifications',
    intlId: 'account.tab.notifications',
  },
  { columnName: 'edit', columnTitle: '', intlId: '' },
];

const NotificationTableRow: React.FC<{
  item: IUserInfo & { notifications: ('invoices' | 'onboarding')[] };
  updateLocalData: (companyId: string, notifications: ('invoices' | 'onboarding')[]) => void;
}> = ({ item, updateLocalData }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const notificationsSettingsList = useNotificationsSettingsList();
  const ownUserData = useAppSelector(SettingsSelectors.getOwnUserData);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);

  const closeEditModalToggler = (): void => {
    setIsOpenEditModal(!isOpenEditModal);
  };

  const submitEditModalHandler = (data: { onboarding: boolean; invoices: boolean }): void => {
    if (!ownUserData) {
      return;
    }
    const payload: OwnUserData = {} as OwnUserData;
    payload.userdetails = ownUserData.userdetails;
    const companydetails = ownUserData.companydetails.find(
      company => Number(company.companyId) === Number(item.companyId),
    );
    if (!companydetails) {
      return;
    }
    let { distributionListSubscribed } = companydetails;
    if (data.invoices && !distributionListSubscribed.includes(ListType.INVOICES)) {
      distributionListSubscribed = [...distributionListSubscribed, ListType.INVOICES];
    }
    if (!data.invoices && distributionListSubscribed.includes(ListType.INVOICES)) {
      distributionListSubscribed = distributionListSubscribed.filter(
        item => item !== ListType.INVOICES,
      );
    }
    if (data.onboarding && !distributionListSubscribed.includes(ListType.NEW_EU_REQUEST)) {
      distributionListSubscribed = [...distributionListSubscribed, ListType.NEW_EU_REQUEST];
    }
    if (!data.onboarding && distributionListSubscribed.includes(ListType.NEW_EU_REQUEST)) {
      distributionListSubscribed = distributionListSubscribed.filter(
        item => item !== ListType.NEW_EU_REQUEST,
      );
    }
    payload.companydetails = [
      {
        ...companydetails,
        distributionListSubscribed,
      },
    ];
    dispatch(SettingsActions.changeOwnUserData(payload));
    closeEditModalToggler();
  };

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell sx={{ fontWeight: 'bold' }} className='notranslate'>
          {item.companyName}
        </TableCell>
        <TableCell>
          {item.notifications.length > 0 ? (
            <Box className='added'>
              <PermissionsChipGroup
                role={item.notifications}
                customChipList={notificationsSettingsList}
              />
            </Box>
          ) : item.role.includes(CompanyRole.Invoice) ||
            item.role.includes(CompanyRole.Usermgmt) ? (
            intl.formatMessage({
              id: 'account.notifications.empty_list',
              defaultMessage: 'Select notifications for this company',
            })
          ) : null}
        </TableCell>
        <TableCell style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {item.role.includes(CompanyRole.Invoice) || item.role.includes(CompanyRole.Usermgmt) ? (
            <Box className='action' height='26px' onClick={closeEditModalToggler}>
              <EditIcon htmlColor={theme.palette.neutralDark.main} fontSize='small' />
              <Typography variant='body1'>
                {intl.formatMessage({
                  id: 'employee_management.bulk_upload.table.column.action.edit',
                  defaultMessage: 'Edit',
                })}
              </Typography>
            </Box>
          ) : (
            <Typography variant='body1' fontStyle='italic'>
              {intl.formatMessage({
                id: 'account.notifications.no_authorization',
                defaultMessage: 'No authorisation for notifications',
              })}
            </Typography>
          )}
        </TableCell>
      </TableRow>
      {isOpenEditModal && (
        <EditNotificationsModal
          roles={item.role}
          notifications={item.notifications}
          isOpen={isOpenEditModal}
          onSubmit={submitEditModalHandler}
          closeModal={closeEditModalToggler}
        />
      )}
    </>
  );
};

export default NotificationTableRow;
