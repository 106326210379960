import { styled, Box, TableHead, TableBody } from '@mui/material';

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  borderRadius: 4,
  background: theme.palette.transparent.blue05,

  '.table_cell': {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    color: theme.palette.neutralDark.main,
    padding: '12px 16px',
    borderBottom: `2px solid ${theme.palette.neutralLight.light}`,
    textTransform: 'uppercase',
  },
  '.right-align': {
    textAlign: 'right',
  },
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  'tr:hover td': {
    background: theme.palette.transparent.blue07,
  },
  td: {
    background: theme.palette.transparent.blue05,
    padding: '12px 16px',
    borderBottom: `2px solid ${theme.palette.neutralLight.light}`,

    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
  },

  '.right-align': {
    textAlign: 'right',
  },

  '.added': {
    gap: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minWidth: 360,
    flexWrap: 'wrap',
  },

  '.action': {
    gap: 10,
    display: 'flex',
    cursor: 'pointer',
  },
}));

export const StyledTablePagination = styled(Box)(() => ({
  marginTop: 12,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const NoDataWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.neutralLight.light,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 571,
  borderRadius: 4,
  padding: 20,

  '.skeleton': {
    opacity: 0.5,

    '&__top': {
      display: 'grid',
      gridGap: 8,
      marginBottom: 8,

      '&_text': {
        fontFamily: 'Roboto-Medium',
        fontSize: 13,
        fontWeight: 500,
        lineHeight: 1.69,
        color: theme.palette.neutralDark.main,
      },
    },

    '&__content': {
      display: 'grid',
      gridGap: 8,

      '&_item': {
        background: theme.palette.tableTemplate.backgroundColor,
        height: 20,
      },
    },
  },

  '.skeleton_wrapper_content': {
    width: '100%',
    maxWidth: 614,
    position: 'relative',
    height: 230,

    '&__info': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));
