import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, Typography } from '@mui/material';

import { getStatisticCount, useStatsList } from 'utils';
import { ContractStatus, FetchingStatus, RootState } from 'types';
import { StatsActions } from 'features/stats/store';
import { useAppDispatch, useAppSelector } from 'store';
import { CompanySelectors } from 'features/company/store/company.selectors';

import { StatsCard } from '../../components';
import { StatsWrapper } from './Stats.styles';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { ContractsSelectors } from 'features/contract/store/contracts.selectors';
import Spacer from '@components/Spacer';

const currentYear = moment().year();
const currentMonth = moment().month() + 1;

const StatsPage = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);
  const statisticsData = useSelector((state: RootState) => state.stats);
  const { coupons, activeMembers, checkIns } = statisticsData;
  const isLoading = coupons.isLoading || activeMembers.isLoading || checkIns.isLoading;
  const contractsApi = useAppSelector(ContractsSelectors.getContracts);
  const contracts = contractsApi.getContracts.data;
  const isFullAccess = contracts.some(
    contract =>
      contract.status === ContractStatus.Active &&
      contract.products.filter(item => item.name === 'Online+').length > 0,
  );

  const getCheckInsList = async () => {
    const month = currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
    const filterMonth = month + `.${currentYear}`;

    dispatch(
      StatsActions.getActiveMembers({
        companyId: activeCompany?.companyId,
        startMonth: filterMonth,
        endMonth: filterMonth,
      }),
    );
    dispatch(
      StatsActions.getCheckIns({
        companyId: activeCompany?.companyId,
        startMonth: filterMonth,
        endMonth: filterMonth,
      }),
    );
    if (isFullAccess) {
      dispatch(
        StatsActions.getCoupons({
          companyId: activeCompany?.companyId,
          startMonth: filterMonth,
          endMonth: filterMonth,
        }),
      );
    }
  };

  useEffect(() => {
    if (activeCompany && activeCompany.companyId) {
      getCheckInsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany?.companyId]);

  const statsList = useStatsList();
  const availableStatsList = isFullAccess
    ? statsList
    : statsList.filter(el => el.name !== 'coupons');
  const renderStatsList = availableStatsList.map(item => {
    const data = statisticsData?.[item.name as keyof typeof statisticsData];
    const statisticCount = getStatisticCount({ data: data?.data, type: item.name });

    const hasError = data?.fetchingStatus === FetchingStatus.REJECTED;

    return <StatsCard key={item.title} item={item} count={statisticCount} hasError={hasError} />;
  });

  return (
    <StatsWrapper>
      <Typography variant='h2'>
        {intl.formatMessage({ id: 'navigation.stats', defaultMessage: 'Statistics' })}
      </Typography>
      <Spacer size='sm' />
      <Typography variant='caption'>
        {intl.formatMessage({
          id: 'view.stats.title',
          defaultMessage: 'View stats summary for the current month.',
        })}
      </Typography>
      <Spacer size='lg' />
      {isLoading && (
        <Box className='loadingWrapper'>
          <CircularProgress size={40} />
          <Typography className='loadingTitle'>
            {intl.formatMessage({
              id: 'view.stats.loading.title',
              defaultMessage: 'Loading statistics',
            })}
          </Typography>
          <Typography className='loadingContent'>
            {intl.formatMessage({
              id: 'view.stats.loading.content',
              defaultMessage: 'Please, wait for a moment…',
            })}
          </Typography>
        </Box>
      )}
      {!isLoading && <Box className='cards'>{renderStatsList}</Box>}
    </StatsWrapper>
  );
};

export default StatsPage;
