import { EmployeeTableOverwiewData, FetchingStatus, RootState } from 'types';
import { createSelector } from '@reduxjs/toolkit';

const getApi = (state: RootState) => state.employeeManagement.api;

const getAddEmployeeApi = (state: RootState) => state.employeeManagement.api.addEmployee;

const getGetEmployeesApi = (state: RootState) => state.employeeManagement.api.getEmployees;

const getGetEmployeeApi = (state: RootState) => state.employeeManagement.api.getEmployee;

const getGetEmployeeStatusApi = (state: RootState) =>
  state.employeeManagement.api.getEmployeeStatus;

const getChangeEmployeeApi = (state: RootState) => state.employeeManagement.api.changeEmployee;

const cancelImportProgressApi = (state: RootState) =>
  state.employeeManagement.api.cancelImportProgress;

const currentStep = (state: RootState) => state.employeeManagement.currentStep;

const addEmployeeModalOpen = (state: RootState) => state.employeeManagement.addEmployeeModalOpen;

const getNewEmployeeIds = (state: RootState) => state.employeeManagement.newEmployees;

const getBulkUploadFiles = (state: RootState) => state.employeeManagement.api.getBulkUploadFile;

const getEmployeeLicense = (state: RootState) => state.employeeManagement.api.employeeLicense;

const getTableOverviewEmployees = createSelector(
  getGetEmployeesApi,
  (employeesApi): Array<EmployeeTableOverwiewData> => {
    if (employeesApi.fetchingStatus === FetchingStatus.FULFILLED && employeesApi.data) {
      return employeesApi.data?.map(employee => ({
        id: employee.id,
        userId: employee.id,
        firstName: employee.firstName,
        lastName: employee.lastName,
        gender: employee.gender,
        userTitle: employee.title,
        businessEmail: employee.businessEmail,
        birthDate: employee.dateOfBirth,
        phoneNumber: employee.phoneNumber,
        entryDate: employee.entryDate,
        status: employee.status,
        customFields: employee.customFields,
        globalCustomFields: employee.globalCustomFields,
        plannedTransition: employee.plannedTransition,
      }));
    } else {
      return [];
    }
  },
);

export const EmployeeManagementSelectors = {
  getApi,
  getAddEmployeeApi,
  getGetEmployeesApi,
  getTableOverviewEmployees,
  getGetEmployeeApi,
  getGetEmployeeStatusApi,
  getChangeEmployeeApi,
  cancelImportProgressApi,
  currentStep,
  addEmployeeModalOpen,
  getNewEmployeeIds,
  getBulkUploadFiles,
  getEmployeeLicense,
};
