import { styled } from '@mui/material';

export const StepContainer = styled('div')(({ theme }) => ({
  '.hint': {
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: theme.palette.neutralDark.contrastText,
  },
  '.ctaContainer': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  '.backBtn': {
    borderRadius: 18,
    borderColor: theme.palette.primary.main,
    width: 142,
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'none',
    padding: '3px 21px',
  },
  button: {
    ':disabled': {
      opacity: '0.5',
      color: theme.palette.neutralLight.light,
      border: 'none',
    },
  },
  '.resendConfirmationLink': {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Medium',
    textDecoration: 'none',
  },
}));

export default StepContainer;
