import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper, Table, TableRow, TableCell, TableContainer, Pagination } from '@mui/material';

import { FetchingStatus, RootState } from 'types';
// import { couponUsedRowsList } from 'utils';

import TableLoader from '../TableLoader';
import TableCellRow from './TableCellRow';
import TableEmptyData from '../TableEmptyData';
import { StyledTableHead, StyledTableBody, PaginationWrapper } from './CouponTable.styles';
import { useIntl } from 'react-intl';
import { TableErrorData } from '../TableErrorData.ts';

const CouponTable = () => {
  const intl = useIntl();
  const { coupons } = useSelector((state: RootState) => state.stats);
  const [page, setPage] = useState<number>(0);
  const couponsList = coupons.data!?.couponsList;
  const hasError = coupons.fetchingStatus === FetchingStatus.REJECTED;

  const rowsPerPage = 10;
  const offset = page * rowsPerPage;
  const limit = offset + rowsPerPage;
  const paginationListCount = Math.ceil(couponsList?.length / rowsPerPage);
  const calculatePatinationResults = limit + 1 >= couponsList?.length ? couponsList?.length : limit;

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  if (coupons.isLoading) {
    return <TableLoader />;
  }

  if (hasError) {
    return <TableErrorData />;
  }

  return couponsList?.length ? (
    <>
      <TableContainer className="coupon_table" component={Paper} elevation={0}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell className="table_cell">
                {intl.formatMessage({
                  id: 'view.stats.table.coupon',
                  defaultMessage: 'Coupon title',
                })}
              </TableCell>
              <TableCell className="table_cell">
                {intl.formatMessage({
                  id: 'view.stats.table.service',
                  defaultMessage: 'Service provider',
                })}
              </TableCell>
              <TableCell className="table_cell">
                {intl.formatMessage({
                  id: 'view.stats.table.id',
                  defaultMessage: 'ID',
                })}
              </TableCell>
              <TableCell className="table_cell">
                {intl.formatMessage({
                  id: 'view.stats.table.coupon_used',
                  defaultMessage: '# Coupon used',
                })}
              </TableCell>
            </TableRow>
          </StyledTableHead>
          <StyledTableBody>
            {couponsList?.slice(offset, limit).map((item, index) => (
              <TableCellRow
                key={`${item.partner_id}-${index}`}
                data={item}
                borderEnd={page + 1 === paginationListCount}
              />
            ))}
          </StyledTableBody>
        </Table>
      </TableContainer>
      {couponsList?.length > 11 && (
        <PaginationWrapper>
          <Box className="results">
            {intl.formatMessage(
              {
                id: 'table.pagination.result',
                defaultMessage: '{first}-{last} from Results {result}',
              },
              {
                first: offset + 1,
                last: calculatePatinationResults,
                result: couponsList?.length,
              },
            )}
          </Box>
          <Pagination
            showFirstButton
            showLastButton
            page={page + 1}
            count={paginationListCount}
            onChange={handleChange}
          />
        </PaginationWrapper>
      )}
    </>
  ) : (
    <>
      <TableContainer className="coupon_table" component={Paper} elevation={0}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell className="table_cell">
                {intl.formatMessage({
                  id: 'view.stats.table.coupon',
                  defaultMessage: 'Coupon title',
                })}
              </TableCell>
              <TableCell className="table_cell">
                {intl.formatMessage({
                  id: 'view.stats.table.service',
                  defaultMessage: 'Service provider',
                })}
              </TableCell>
              <TableCell className="table_cell">
                {intl.formatMessage({
                  id: 'view.stats.table.id',
                  defaultMessage: 'ID',
                })}
              </TableCell>
              <TableCell className="table_cell">
                {intl.formatMessage({
                  id: 'view.stats.table.coupon_used',
                  defaultMessage: '# Coupon used',
                })}
              </TableCell>
            </TableRow>
          </StyledTableHead>
        </Table>
      </TableContainer>
      <TableEmptyData />
    </>
  );
};

export default CouponTable;
