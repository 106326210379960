import { styled, Paper, Box } from '@mui/material';

export const MainBlock = styled(Paper)(() => ({
  height: '100%',
  padding: '36px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
  textAlign: 'center',
  overflow: 'auto',
}));

export const NoDataWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.neutralLight.light,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 340,
  borderRadius: 4,
  padding: 20,

  '.skeleton': {
    opacity: 0.5,

    '&__top': {
      display: 'grid',
      gridGap: 8,
      gridTemplateColumns: 'repeat(8,1fr)',
      marginBottom: 8,

      '&_text': {
        fontFamily: 'Roboto-Medium',
        fontSize: 13,
        fontWeight: 500,
        lineHeight: 1.69,
        color: theme.palette.neutralDark.main,
        opacity: 0.0,
      },
    },

    '&__content': {
      marginBottom: 8,
      display: 'grid',
      gridColumnGap: 8,
      gridTemplateColumns: 'repeat(2, 2fr) repeat(3, 1fr)',

      '&_item': {
        background: theme.palette.tableTemplate.backgroundColor,
        height: 32,
      },

      '.gridTop': { height: 58, gridArea: '1 / 1 / 2 / 6' },
      '.gridBottom': { gridArea: '2 / 1 / 3 / 4' },
      '.gridSideLeft': { height: 24, alignSelf: 'end', gridArea: '2 / 4 / 3 / 5' },
      '.gridSideRight': { height: 24, alignSelf: 'end', gridArea: '2 / 5 / 3 / 6' },
    },
  },

  '.content': {
    width: '100%',
    maxWidth: 814,
    position: 'relative',

    '&__info': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '&__title': {
      fontFamily: 'NutmegHeadline-UltraBlack',
      fontSize: 20,
      fontWeight: 900,
      lineHeight: 1.6,
      color: theme.palette.neutralDark.contrastText,
      textTransform: 'uppercase',
      marginTop: 16,
      marginBottom: 16,
    },

    '&__text': {
      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.main,
    },
  },
}));
