import { Stack, TableCell, Typography } from '@mui/material';
import { Spacer } from 'components';
import Loader from 'components/Loader';
import CustomTable from 'components/Table';
import { SettingsActions } from 'features/settings/store';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store';
import { FetchingStatus, ListEntries, ListType } from 'types';
import { FormatDate } from 'components/FormatDate';
import { OnboardingTableRowStyled } from './Onboarding.styles';
import { distributionTableColumns } from '..';

const Onboarding = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { data: tableData, fetchingStatus } = useAppSelector(
    state => state.settings.distributionLists.NEW_EU_REQUEST,
  );

  useEffect(() => {
    dispatch(SettingsActions.getDistributionList({ listType: ListType.NEW_EU_REQUEST }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRow = (item: ListEntries) => (
    <OnboardingTableRowStyled key={item.unsubscribeKey}>
      <TableCell>{item.email}</TableCell>
      <TableCell className='right-align'>{FormatDate(item.creationDate)}</TableCell>
    </OnboardingTableRowStyled>
  );

  return (
    <Stack id='onboardingSettingsWrapper' height='100%'>
      <Loader
        show={fetchingStatus === FetchingStatus.PENDING}
        inCard={true}
        portalId='onboardingSettingsWrapper'
      />
      <Typography variant='h6'>
        {formatMessage({
          id: 'user_setting.onboarding_emails',
          defaultMessage: 'Onboarding emails',
        })}
      </Typography>
      <Spacer size='md' />
      <Typography variant='subtitle1'>
        {formatMessage({
          id: 'user_setting.onboarding_emails.subtitle',
          defaultMessage:
            'This overview shows which company users receive information about new registrations.',
        })}
      </Typography>
      <Spacer height={40} />
      <CustomTable
        columnsList={distributionTableColumns}
        listData={tableData}
        renderRow={renderRow}
      />
    </Stack>
  );
};

export default Onboarding;
