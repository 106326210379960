import { useIntl } from 'react-intl';
import { CompanyRole, TRoles, TranslationsKeys } from 'types';

export const useLanguageItems = (): { value: TranslationsKeys; label: string }[] => {
  const intl = useIntl();
  return [
    {
      value: 'de',
      label: intl.formatMessage({ id: 'language.bar.german', defaultMessage: 'German' }),
    },
    {
      value: 'en',
      label: intl.formatMessage({ id: 'language.bar.english', defaultMessage: 'English' }),
    },
  ];
};

export const useUserAccountTabs = () => {
  const intl = useIntl();
  return [
    {
      value: 'account.tab.profile',
      text: intl.formatMessage({
        id: 'account.tab.profile',
        defaultMessage: 'Profile',
      }),
    },
    {
      value: 'account.tab.profile_password',
      text: intl.formatMessage({
        id: 'account.tab.profile_password',
        defaultMessage: 'Password',
      }),
    },
    {
      value: 'account.tab.notifications',
      text: intl.formatMessage({
        id: 'account.tab.notifications',
        defaultMessage: 'Notification',
      }),
    },
    {
      value: 'account.tab.position_and_permission',
      text: intl.formatMessage({
        id: 'account.tab.position_and_permission',
        defaultMessage: 'Position & permission',
      }),
    },
  ];
};

export const useUserSettingsTabs = (): {
  value: string;
  label: string;
  role: TRoles;
}[] => {
  const intl = useIntl();
  return [
    // {
    //   value: 'Notification',
    //   label: intl.formatMessage({
    //     id: 'user_setting.notification',
    //     defaultMessage: 'Notification',
    //   }),
    // },
    {
      value: 'Invoice email',
      label: intl.formatMessage({
        id: 'user_setting.invoice_email',
        defaultMessage: 'Invoice email',
      }),
      role: [CompanyRole.Admin, CompanyRole.Invoice],
    },
    {
      value: 'Activation code',
      label: intl.formatMessage({
        id: 'user_setting.activation_code',
        defaultMessage: 'Activation code',
      }),
      role: [CompanyRole.Admin, CompanyRole.Usermgmt],
    },
    {
      value: 'Employee self-registration',
      label: intl.formatMessage({
        id: 'user_setting.employee',
        defaultMessage: 'Employee self-registration',
      }),
      role: [CompanyRole.Admin, CompanyRole.Usermgmt],
    },
    {
      value: 'onboarding_emails',
      label: intl.formatMessage({
        id: 'user_setting.onboarding_emails',
        defaultMessage: 'Onboarding emails',
      }),
      role: [CompanyRole.Admin, CompanyRole.Usermgmt],
    },
    {
      value: 'Company custom field',
      label: intl.formatMessage({
        id: 'user_setting.company_custom_field',
        defaultMessage: 'Company custom field',
      }),
      role: [CompanyRole.Admin, CompanyRole.Usermgmt],
    },
  ];
};
