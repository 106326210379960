import { createSlice } from '@reduxjs/toolkit';
import {
  setInitialTableConfiguration,
  updateRowsPerPage,
  toggleAllColumns,
  toggleColumn,
  updateCurrentPage,
  updateFilters,
  updateSearchTerm,
  updateSearchVisibility,
  resetSearchData,
  resetTableConfiguration,
  resetFilters,
} from './actions/sync/tableManagement.actions';
import { TablesManagementState } from './interfaces';

const tablesManagementSlice = createSlice({
  name: 'tablesManagement',
  initialState: {} as TablesManagementState,
  reducers: {
    setInitialTableConfiguration,
    resetTableConfiguration,
    toggleColumn,
    updateRowsPerPage,
    toggleAllColumns,
    updateFilters,
    updateCurrentPage,
    updateSearchTerm,
    updateSearchVisibility,
    resetSearchData,
    resetFilters,
  },
});

export const { name, actions } = tablesManagementSlice;
export const tablesManagementReducer = tablesManagementSlice.reducer;
