import { SerializedError } from '@reduxjs/toolkit';
import { FetchingStatus, UserManagementBaseResponse } from 'types';

export enum SELF_REGISTRATION_STEPS {
  ADD_PERSONAL_INFO,
  SET_NEW_PASSWORD,
  VERIFY_EMAIL,
}

export interface SelfRegistrationState {
  api: {
    registerAdminUser: {
      data: UserManagementBaseResponse | null;
      fetchingStatus: FetchingStatus;
      error: SerializedError | null;
    };
    getSignUpKeyData: {
      data: SignUpKeyData | null;
      fetchingStatus: FetchingStatus;
      error: SerializedError | null;
    };
  };
  currentStep: SELF_REGISTRATION_STEPS;
  steps: SelfRegistrationSteps;
}

export interface SelfRegistrationSteps {
  addPersonalInfoStep: AddPersonalInfoStep | null;
}

export interface AddPersonalInfoStep {
  name: string;
  surname: string;
  position: string;
  email: string;
}

export interface RegisterAdminUserRequestBody {
  signupKey: string;
  password: string;
  email: string;
  companyId: string;
  name: string;
  surname: string;
  position: string;
}

export interface SignUpKeyRequestParams {
  companyId: string;
  signUpKey: string;
}

export interface SignUpKeyResponse extends UserManagementBaseResponse {
  detail: SignUpKeyData;
}

export interface SignUpKeyData {
  companyName: string;
  email: string;
  name: string;
  surname: string;
  position: string;
}

export interface SignInRequestParams {
  email: string;
  password: string;
}
