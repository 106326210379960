import { styled } from '@mui/material';
import { useAppSelector } from '@store/index';
import { ToastContainer } from 'react-toastify';
import { default as theme } from '@theme/theme';

const ToastWrapper = styled(ToastContainer)(() => {
  const { isOpenSidebar } = useAppSelector(state => state.authentication);

  return {
    width: 'auto',
    top: '6em',
    left: `calc(50% + ${isOpenSidebar ? '150' : '36'}px)`,

    '.Toastify__toast': {
      padding: 0,
      minHeight: 'auto',
      boxShadow: `0 10px 10px 0 ${theme.palette.transparent.boxShadowDark01}`,
      width: 'fit-content',
      alignText: 'center',
    },

    ':has(.has-backdrop)': {
      left: 'calc(50%)',
    },

    '.Toastify__toast-body': {
      margin: 0,
      padding: 0,
    },

    '.toast__wrapper_content': {
      gap: 12,
      alignItems: 'center',
      padding: '9px 16px 9px 12px',
      background: theme.palette.neutralLight.light,

      '.MuiAlert-icon': {
        padding: 0,
        margin: 0,
      },

      '.MuiAlert-message': {
        padding: 0,

        fontSize: 16,
        lineHeight: 1.38,
        color: theme.palette.neutralDark.contrastText,

        span: {
          fontFamily: 'Roboto-Bold',
          fontWeight: 'bold',
        },
      },
    },
  };
});

export default ToastWrapper;
