import { GridFilterModel } from '@mui/x-data-grid-pro';
import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { InvoicesData } from 'types';
import { InvoicesState } from '../../interfaces';

export const setInitialTableConfiguration: CaseReducer<
  InvoicesState,
  PayloadAction<{ companyId: number; invoices: InvoicesData[] }>
> = (state, action) => {
  const companyId = action.payload.companyId;
  const invoices = action.payload.invoices;
  const columnsFromLocalStorage = sessionStorage.getItem(
    `ccp_invoice_table_configuration_columns_${companyId}`,
  );
  const rowsPerPageFromLocalStorage = sessionStorage.getItem(
    `ccp_invoice_table_configuration_rows_per_page_${companyId}`,
  );
  const filtersFromLocalStorage = sessionStorage.getItem(
    `ccp_invoice_table_configuration_filters_${companyId}`,
  );

  let columns: { [key: string]: boolean } = {};
  let rowsPerPage: number = -1;
  let filters: any = {};

  if (rowsPerPageFromLocalStorage) {
    rowsPerPage = parseInt(rowsPerPageFromLocalStorage);
  } else {
    rowsPerPage = 12;
  }

  if (columnsFromLocalStorage) {
    columns = JSON.parse(columnsFromLocalStorage);
  } else if (!columnsFromLocalStorage && Object.keys(invoices).length > 0) {
    const initialColumns: { [key: string]: boolean } = {};
    if (invoices.length > 0) {
      const properties = Object.keys(invoices[0]);

      properties.forEach(property => {
        initialColumns[property] = true;
      });
    }

    columns = initialColumns;
  }

  if (filtersFromLocalStorage) {
    filters = JSON.parse(filtersFromLocalStorage);
  } else {
    filters = { items: [] };
  }

  state.tableConfigurations[companyId] = {
    rowsPerPage: rowsPerPage,
    currentPage: 0,
    filters: filters,
    columns: columns,
    searchData: { hasVisibleSearchField: false, searchTerm: '' },
  };
};

export const resetTableConfiguration: CaseReducer<
  InvoicesState,
  PayloadAction<{ companyId: number; invoices: InvoicesData[] }>
> = (state, action) => {
  const companyId = action.payload.companyId;
  const invoices = action.payload.invoices;
  let columns: { [key: string]: boolean } = {};
  let rowsPerPage: number = 12;
  let filters: any = { items: [] };
  const searchData = { hasVisibleSearchField: false, searchTerm: '' };

  if (invoices.length > 0) {
    const properties = Object.keys(invoices[0]);

    properties.forEach(property => {
      if (property !== 'customFields' && property !== 'lastName') {
        columns[property] = true;
      }
    });
  }

  state.tableConfigurations[companyId] = {
    rowsPerPage: rowsPerPage,
    currentPage: 0,
    filters: filters,
    columns: columns,
    searchData: searchData,
  };

  sessionStorage.setItem(
    `ccp_invoice_table_configuration_columns_${companyId}`,
    JSON.stringify(state.tableConfigurations[companyId].columns),
  );

  sessionStorage.setItem(
    `ccp_invoice_table_configuration_rows_per_page_${companyId}`,
    JSON.stringify(rowsPerPage),
  );

  sessionStorage.setItem(
    `ccp_invoice_table_configuration_filters_${companyId}`,
    JSON.stringify(state.tableConfigurations[companyId].filters),
  );
};

export const toggleColumn: CaseReducer<
  InvoicesState,
  PayloadAction<{ companyId: number; property: string; visible: boolean }>
> = (state, action) => {
  const { companyId, property, visible } = action.payload;
  state.tableConfigurations[companyId].columns[property] = visible;
  sessionStorage.setItem(
    `ccp_invoice_table_configuration_columns_${companyId}`,
    JSON.stringify(state.tableConfigurations[companyId].columns),
  );
};

export const toggleAllColumns: CaseReducer<
  InvoicesState,
  PayloadAction<{ companyId: number; visible: boolean }>
> = (state, action) => {
  const { companyId, visible } = action.payload;
  const properties = Object.keys(state.tableConfigurations[companyId].columns);

  const toggledProperties: { [key: string]: boolean } = {};
  properties.forEach(property => {
    toggledProperties[property] = visible;
  });

  sessionStorage.setItem(
    `ccp_invoice_table_configuration_columns_${companyId}`,
    JSON.stringify(toggledProperties),
  );
  state.tableConfigurations[companyId].columns = toggledProperties;
};

export const updateRowsPerPage: CaseReducer<
  InvoicesState,
  PayloadAction<{ companyId: number; rowsPerPage: number }>
> = (state, action) => {
  const { companyId, rowsPerPage } = action.payload;
  state.tableConfigurations[companyId].rowsPerPage = rowsPerPage;
  sessionStorage.setItem(
    `ccp_invoice_table_configuration_rows_per_page_${companyId}`,
    JSON.stringify(rowsPerPage),
  );
};

export const updateFilters: CaseReducer<
  InvoicesState,
  PayloadAction<{
    companyId: number;
    model: GridFilterModel;
  }>
> = (state, action) => {
  const { companyId, model } = action.payload;

  state.tableConfigurations[companyId].filters = model;

  sessionStorage.setItem(
    `ccp_invoice_table_configuration_filters_${companyId}`,
    JSON.stringify(state.tableConfigurations[companyId].filters),
  );
};

export const resetFilters: CaseReducer<InvoicesState, PayloadAction<{ companyId: number }>> = (
  state,
  action,
) => {
  const { companyId } = action.payload;
  state.tableConfigurations[companyId].filters = { items: [] };
  state.tableConfigurations[companyId].searchData = {
    hasVisibleSearchField: false,
    searchTerm: '',
  };
  if (state.tableConfigurations[companyId].currentPage !== 0) {
    state.tableConfigurations[companyId].currentPage = 0;
  }

  sessionStorage.setItem(
    `ccp_invoice_table_configuration_filters_${companyId}`,
    JSON.stringify(state.tableConfigurations[companyId].filters),
  );
};

export const resetSearchData: CaseReducer<InvoicesState, PayloadAction<{ companyId: number }>> = (
  state,
  action,
) => {
  const { companyId } = action.payload;
  state.tableConfigurations[companyId].searchData = {
    hasVisibleSearchField: false,
    searchTerm: '',
  };
  if (state.tableConfigurations[companyId].currentPage !== 0) {
    state.tableConfigurations[companyId].currentPage = 0;
  }
};
