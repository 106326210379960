import { TRoles } from 'features/authentication/store/interfaces';

export enum ListType {
  CU_ADMIN = 'CU_ADMIN',
  INVOICES = 'INVOICES',
  NEW_EU_REQUEST = 'NEW_EU_REQUEST',
  MARKETING_MATERIAL = 'MARKETING_MATERIAL',
  ACTIVATION_LETTER = 'ACTIVATION_LETTER',
}

export type EmailDistributionModalValuesProps = {
  email: string;
  description: string;
};

export interface EmailDistributionModalProps {
  isOpen: boolean;
  onSubmit: (data: EmailDistributionModalValuesProps) => void;
  closeModal: () => void;
  isEditPopup?: boolean;
  email?: string;
  description?: string;
}

export interface EditPositionModalProps {
  position: string;
  isOpen: boolean;
  onSubmit: (data: { position: string }) => void;
  closeModal: () => void;
}
export interface EditNotificationsModalProps {
  roles: TRoles;
  notifications: Array<'invoices' | 'onboarding'>;
  isOpen: boolean;
  onSubmit: (data: { onboarding: boolean; invoices: boolean }) => void;
  closeModal: () => void;
}

export interface DeleteEmailModalProps {
  email: string;
  isOpen: boolean;
  onSubmit: () => void;
  closeModal: () => void;
}

export interface SettingsInvoicesTableProps {
  activeTab: TabsType;
}

export interface ITranslationProps {
  id: string;
  defaultMessage: string;
}

export enum TabsType {
  EXTERNAL = 'External users',
  INTERNAL = 'Internal users',
}
