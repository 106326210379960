import { FormatDate } from '@components/FormatDate';
import Spacer from '@components/Spacer';
import {
  ContractResponse,
  ContractStatus,
  ContractTypeNames,
  IContractProduct,
  hubSpotFeeType,
} from '@localTypes/index';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { default as theme } from '@theme/theme';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import ContractProduct from '../ContractTile/TableWrapper/ContractProduct';
import { formattedPrice } from '@utils/formattedPrice';

type ContractInfoProps = {
  data: ContractResponse;
};

const ContractTitle = ({ children }: { children?: string }) => (
  <Typography
    variant='h5'
    color={theme.palette.neutralDark.main}
    fontWeight='500'
    textTransform='uppercase'
    fontFamily='Roboto-Medium'>
    {children}
  </Typography>
);

const ContractValue = ({
  status,
  children,
  bold,
}: {
  status: ContractStatus;
  children?: ReactNode;
  bold?: boolean;
}) => (
  <Typography
    variant={`subtitle${status === ContractStatus.Active ? '1' : '2'}`}
    fontSize='16px'
    display='flex'
    gap='8px'
    fontWeight={bold ? '600' : '400'}
    alignItems='center'>
    {children}
  </Typography>
);

const DirectPayBadge = ({ isDirectPay }: { isDirectPay: boolean }) => {
  const { formatMessage } = useIntl();

  return isDirectPay ? (
    <Box
      borderRadius='12px'
      border={`1px solid ${theme.palette.neutralDark.light}`}
      display='inline-block'
      padding='0 10px'>
      {formatMessage({
        id: 'view.contract.direct_pay',
        defaultMessage: 'Direct pay',
      })}
    </Box>
  ) : null;
};

const ContractProductsWrapper = ({
  title,
  productsList,
  status,
}: {
  title: string;
  productsList: IContractProduct[];
  status: ContractStatus;
}) => (
  <>
    <Spacer size='xl' />
    <ContractTitle>{title}</ContractTitle>
    <Spacer size='md' />
    <Box display='flex' flexDirection='column' gap='24px'>
      <ContractProduct
        products={productsList}
        isDisabled={status === ContractStatus.Expired || status === ContractStatus.Paused}
      />
    </Box>
  </>
);

const ContractInfo = ({ data }: ContractInfoProps) => {
  const { formatMessage } = useIntl();
  const [mainProducts, setMainProducts] = useState<IContractProduct[]>([]);
  const [additionalProducts, setAdditionalProducts] = useState<IContractProduct[]>([]);

  useEffect(() => {
    const mProducts: IContractProduct[] = [],
      aProducts: IContractProduct[] = [];
    data.products.forEach(item => {
      if (item.isMainProduct) {
        mProducts.push(item);
      } else {
        aProducts.push(item);
      }
    });
    setMainProducts(mProducts);
    setAdditionalProducts(aProducts);
  }, [data.products, setMainProducts, setAdditionalProducts]);

  const getFees = useCallback(
    (payer: ('Company' | 'Member')[], possibleHubSpotFeesTypes: hubSpotFeeType[]) => {
      if (!data.hubSpotProductUnits || data.hubSpotProductUnits.length === 0) return null;
      const hubSpotProducts = data.hubSpotProductUnits.filter(
        item => payer.includes(item.payer) && possibleHubSpotFeesTypes.includes(item.type),
      );
      if (hubSpotProducts.length !== 0) {
        return hubSpotProducts[0];
      }
      return null;
    },
    [data.hubSpotProductUnits],
  );

  const monthlyFeeCompany = useMemo(() => {
    if (
      (data.monthlyFeeNetto && !data.hubSpotProductUnits) ||
      data.hubSpotProductUnits?.length === 0
    ) {
      return data.isDirectPay
        ? null
        : {
            amount: data.monthlyFeeNetto || 0,
            unitPrice: data.monthlyFeeNetto || 0,
            payer: 'Company',
          };
    }
    return getFees(
      ['Company'],
      [
        hubSpotFeeType.CorporateFeeContract,
        hubSpotFeeType.FlatLicenseContract,
        hubSpotFeeType.QuotaLicenseContract,
        hubSpotFeeType.SingleLicenseContract,
      ],
    );
  }, [getFees, data]);

  const monthlyFeeEmployee = useMemo(() => {
    if (
      (data.monthlyFeeNetto && !data.hubSpotProductUnits) ||
      data.hubSpotProductUnits?.length === 0
    ) {
      return data.isDirectPay
        ? {
            amount: data.monthlyFeeNetto || 0,
            unitPrice: data.monthlyFeeNetto || 0,
            payer: 'Member',
          }
        : null;
    }
    return getFees(
      ['Member'],
      [
        hubSpotFeeType.CorporateFeeContract,
        hubSpotFeeType.FlatLicenseContract,
        hubSpotFeeType.QuotaLicenseContract,
        hubSpotFeeType.SingleLicenseContract,
      ],
    );
  }, [getFees, data]);

  const serviceFee = useMemo(() => {
    if (
      (data.serviceFeeNetto && !data.hubSpotProductUnits) ||
      data.hubSpotProductUnits?.length === 0
    ) {
      return {
        unitPrice: data.serviceFeeNetto || 0,
        payer: data.isDirectPay ? 'Member' : 'Company',
      };
    }
    return getFees(['Member', 'Company'], [hubSpotFeeType.ServiceFee]);
  }, [getFees, data]);

  const trainingInstructionFee = useMemo(() => {
    if (
      (data.trainingInstructionFeeNetto && !data.hubSpotProductUnits) ||
      data.hubSpotProductUnits?.length === 0
    ) {
      return {
        amount: data.trainingInstructionFeeNetto || 0,
        payer: data.isDirectPay ? 'Member' : 'Company',
        billingStartDate: data.settleTrainingInstructionFeeFrom || data.startDate,
      };
    }
    return getFees(['Member', 'Company'], [hubSpotFeeType.TrainingInstructionFee]);
  }, [getFees, data]);

  const gridItemCount = useMemo(
    () => (data.numberOfEmployees && data.numberOfEmployees !== 0 ? 3 : 4),
    [data],
  );

  const translationMessages: Record<ContractTypeNames, { id: string; defaultMessage: string }> =
    useMemo(
      () =>
        defineMessages({
          FlatLicenseContract: {
            id: 'view.contract.table.type.FlatLicenseContract',
            defaultMessage: 'Flat license',
          },
          CorporateFeeContract: {
            id: 'view.contract.table.type.CorporateFeeContract',
            defaultMessage: 'Corporate fee',
          },
          QuotaLicenseContract: {
            id: 'view.contract.table.type.QuotaLicenseContract',
            defaultMessage: 'Quota license',
          },
          SingleLicenseContract: {
            id: 'view.contract.table.type.SingleLicenseContract',
            defaultMessage: 'Single license',
          },
          CasLicenseContract: {
            id: 'view.contract.table.type.CasLicenseContract',
            defaultMessage: 'Cas license',
          },
        }),
      [],
    );

  const translatedType = useMemo(() => {
    if (!data.type.class) {
      return '-';
    }
    if (translationMessages?.[data.type.class]) {
      return formatMessage(translationMessages[data.type.class]);
    }
    return data.type.class;
  }, [formatMessage, data.type.class, translationMessages]);

  return (
    <>
      <Grid container spacing='32px'>
        <Grid item xs={gridItemCount}>
          <ContractTitle>
            {formatMessage({
              id: 'view.contract.table.header.start_date',
              defaultMessage: 'Start date',
            })}
          </ContractTitle>
          <ContractValue status={data.status}>
            {data.startDate ? FormatDate(data.startDate) : '-'}
          </ContractValue>
        </Grid>
        <Grid item xs={gridItemCount}>
          <ContractTitle>
            {formatMessage({
              id: 'view.contract.table.header.end_date',
              defaultMessage: 'End date',
            })}
          </ContractTitle>
          <ContractValue status={data.status}>
            {data.endDate ? FormatDate(data.endDate) : '-'}
          </ContractValue>
        </Grid>
        {data.numberOfEmployees && data.numberOfEmployees !== 0 && (
          <Grid item xs={gridItemCount}>
            <ContractTitle>
              {formatMessage({
                id: 'view.contract.table.header.employees_number',
                defaultMessage: 'Number of employees',
              })}
            </ContractTitle>
            <ContractValue status={data.status}>{data.numberOfEmployees}</ContractValue>
          </Grid>
        )}
        <Grid item xs={gridItemCount}>
          <ContractTitle>
            {formatMessage({
              id: 'view.contract.table.header.type',
              defaultMessage: 'Type',
            })}
          </ContractTitle>
          <ContractValue status={data.status} bold>
            {translatedType}
          </ContractValue>
        </Grid>
        {monthlyFeeCompany && monthlyFeeCompany.unitPrice !== 0 && (
          <Grid item xs={gridItemCount}>
            <ContractTitle>
              {formatMessage({
                id: 'view.contract.table.header.monthly_fee_company',
                defaultMessage: 'Monthly fee (Company)',
              })}
            </ContractTitle>
            <ContractValue status={data.status}>
              {['QuotaLicenseContract', 'SingleLicenseContract'].includes(data.type.class)
                ? `${formattedPrice(monthlyFeeCompany.unitPrice)} ${formatMessage({
                    id: 'view.contract.table.header.monthly_fee_company.per_user',
                    defaultMessage: 'per user',
                  })}`
                : formattedPrice(monthlyFeeCompany.amount)}
            </ContractValue>
          </Grid>
        )}
        {monthlyFeeEmployee && monthlyFeeEmployee.unitPrice !== 0 && (
          <Grid item xs={gridItemCount}>
            <ContractTitle>
              {formatMessage({
                id: 'view.contract.table.header.monthly_fee_employee',
                defaultMessage: 'Monthly fee (Employee)',
              })}
            </ContractTitle>
            <ContractValue status={data.status}>
              {formattedPrice(monthlyFeeEmployee.unitPrice)}
              <DirectPayBadge isDirectPay={monthlyFeeEmployee.payer === 'Member'} />
            </ContractValue>
          </Grid>
        )}
        {serviceFee && serviceFee.unitPrice !== 0 && (
          <Grid item xs={gridItemCount}>
            <ContractTitle>
              {formatMessage({
                id: 'view.contract.table.header.service_fee',
                defaultMessage: 'Service fee',
              })}
            </ContractTitle>
            <ContractValue status={data.status}>
              {formattedPrice(serviceFee.unitPrice)}
              <DirectPayBadge isDirectPay={serviceFee.payer === 'Member'} />
            </ContractValue>
          </Grid>
        )}
        {trainingInstructionFee && trainingInstructionFee.amount !== 0 && (
          <Grid item xs={gridItemCount}>
            <ContractTitle>
              {formatMessage({
                id: 'view.contract.table.header.training_fee',
                defaultMessage: 'Training instruction fee',
              })}
            </ContractTitle>
            <ContractValue status={data.status}>
              <Stack>
                <Box display='flex' alignItems='center' gap='8px'>
                  {formattedPrice(trainingInstructionFee.amount)}
                  <DirectPayBadge isDirectPay={trainingInstructionFee.payer === 'Member'} />
                </Box>
                <Box>
                  {data.startDate !== trainingInstructionFee.billingStartDate && (
                    <Typography variant='caption'>
                      {formatMessage(
                        {
                          id: 'view.contract.label.fee_settle_date',
                          defaultMessage:
                            'Will be settled for new employees with start date {date}',
                        },
                        { date: FormatDate(trainingInstructionFee.billingStartDate) },
                      )}
                    </Typography>
                  )}
                </Box>
              </Stack>
            </ContractValue>
          </Grid>
        )}
      </Grid>
      {mainProducts.length > 0 && (
        <ContractProductsWrapper
          title={formatMessage({
            id: 'view.contract.table.header.products',
            defaultMessage: 'Products',
          })}
          status={data.status}
          productsList={mainProducts}
        />
      )}
      {additionalProducts.length > 0 && (
        <ContractProductsWrapper
          title={formatMessage({
            id: 'view.contract.table.header.additional_products',
            defaultMessage: 'Additional products',
          })}
          status={data.status}
          productsList={additionalProducts}
        />
      )}
    </>
  );
};

export default ContractInfo;
