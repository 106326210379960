import { useUserSettingsTabs } from '../utils';
import { TRoles } from '../features/authentication/store/interfaces';
import { useAppSelector } from '../store';
import { AuthenticationSelectors } from '../features/authentication/store/authentication.selectors';
import { CompanyRole } from '@localTypes/user';

export const useGetSettingTab = () => {
  const autoConfirmSignup = useAppSelector(
    state => state.settings.defaultSelfRegistration.autoConfirmSignup,
  );
  const userSettingsTabs = useUserSettingsTabs();
  const { userInfo } = useAppSelector(AuthenticationSelectors.getAuthentication);
  const isShowTab = (tab: { value: string; label: string; role: TRoles }) =>
    (tab.value !== 'onboarding_emails' &&
      tab.value !== 'Company custom field' &&
      tab.role.filter(tabRole => userInfo?.role.includes(tabRole)).length !== 0) ||
    (tab.value === 'onboarding_emails' &&
      !autoConfirmSignup &&
      tab.role.filter(tabRole => userInfo?.role.includes(tabRole)).length !== 0) ||
    (tab.value === 'Company custom field' &&
      !!userInfo?.role.includes(CompanyRole.Usermgmt) &&
      !!userInfo?.role.includes(CompanyRole.Admin));

  return userSettingsTabs.filter(tab => isShowTab(tab));
};
