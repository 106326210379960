import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import signIn from './signIn';
import { initialState } from 'features/authentication/store/initialState';
import { AuthenticationState, FetchingStatus } from '@localTypes/index';

export const signInExtraReducers = (builder: ActionReducerMapBuilder<AuthenticationState>) => {
  builder.addCase(signIn.pending, (state, action) => {
    state.fetchingStatus = FetchingStatus.PENDING;
    state.error = null;
    state.forgotPassword = initialState.forgotPassword;
  });
  builder.addCase(signIn.fulfilled, (state, action) => {
    if (action.payload && action.payload.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const notTypedPayload = action.payload as any;
      state.fetchingStatus = FetchingStatus.FULFILLED;
      state.authenticated = false;
      state.error = {
        name: notTypedPayload.challengeParam?.userAttributes?.email || '',
        message: 'NEW_PASSWORD_REQUIRED',
        stack: '',
        code: '',
      };
    } else {
      state.fetchingStatus = FetchingStatus.FULFILLED;
      state.authenticated = true;
      state.error = null;
    }
  });
  builder.addCase(signIn.rejected, (state, action) => {
    state.fetchingStatus = FetchingStatus.REJECTED;
    state.error = { message: action.payload?.message };
  });
};
