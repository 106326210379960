import moment from 'moment';
import { FetchingStatus } from 'types';

import { StatsState } from './interfaces';

const currentYear = moment().year();
const currentMonth = moment().month();
// const previousYear = currentYear - 3;

export const initialState: StatsState = {
  checkIns: {
    fetchingStatus: FetchingStatus.IDLE,
    data: null,
    error: null,
    isLoading: false,
    filters: {
      endYear: currentYear,
      startYear: currentYear,
      endMonth: currentMonth,
      startMonth: currentMonth,
    },
  },
  coupons: {
    fetchingStatus: FetchingStatus.IDLE,
    data: null,
    error: null,
    isLoading: false,
    filters: {
      endYear: currentYear,
      startYear: currentYear,
      endMonth: currentMonth,
      startMonth: currentMonth,
    },
  },
  activeMembers: {
    fetchingStatus: FetchingStatus.IDLE,
    data: null,
    error: null,
    isLoading: false,
    filters: {
      endYear: currentYear,
      startYear: currentYear,
      endMonth: currentMonth,
      startMonth: currentMonth,
    },
  },
  statistics: {
    fetchingStatus: FetchingStatus.IDLE,
    error: null,
    data: {
      activeMembers: 0,
      checkins: 0,
      coupons: 0,
    },
  },
};
