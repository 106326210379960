import { ContractResponse, ContractStatus } from '@localTypes/index';
import { Box, Typography, AccordionSummary, AccordionDetails } from '@mui/material';
import { TileAccordion, TileWrapper } from './ContractCard.styles';
import ContractStatusChip from '../Status';
import { FormatDate } from '@components/FormatDate';
import { useIntl } from 'react-intl';
import Spacer from '@components/Spacer';
import ContractInfo from '../ContractInfo';

type ContractCardProps = {
  contract: ContractResponse;
};

const ContractCard = ({ contract }: ContractCardProps) => {
  const { formatMessage } = useIntl();

  return contract.status === ContractStatus.Active ? (
    <TileWrapper>
      <Box className='tile-header'>
        <Box>
          <Typography variant='h1'>
            {formatMessage({
              id: 'contract_details.contract_title',
              defaultMessage: 'Current contract',
            })}
          </Typography>
          <Spacer size='xs' />
          <Typography variant='caption' fontSize='14px'>
            {formatMessage({
              id: 'contract_details.contract_subtitle',
              defaultMessage: 'All prices excl. VAT',
            })}
          </Typography>
        </Box>
        <ContractStatusChip startDate={FormatDate(contract.startDate)} status={contract.status} />
      </Box>
      <Spacer size='xl' />
      <ContractInfo data={contract} />
    </TileWrapper>
  ) : (
    <TileAccordion className='accordion' expanded={true}>
      <AccordionSummary className='accordion-header'>
        <ContractStatusChip startDate={FormatDate(contract.startDate)} status={contract.status} />
      </AccordionSummary>
      <AccordionDetails className='accordion-details'>
        <Spacer size='xl' />
        <ContractInfo data={contract} />
      </AccordionDetails>
    </TileAccordion>
  );
};

export default ContractCard;
