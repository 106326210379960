import {
  Button,
  IconButton,
  styled,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from '@mui/material';

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  background: theme.palette.primaryLight.light,
}));

export const StyledTableHeadText = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutralDark.main,
  fontFamily: 'Roboto-Medium',
  textTransform: 'uppercase',
  fontSize: '14px',
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  background: theme.palette.primaryLight.light,
}));

export const StyledTableCell = styled(TableCell)`
  border-bottom: 2px solid white;
  padding: 13px 0px 13px 17px;
`;

export const StyledTableBodyText = styled(Typography)`
  font-family: Roboto-Regular;
  font-size: 16px;
  color: black;
`;

export const StyledDeleteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutralDark.main,
  fontSize: '16px',
  minWidth: 'auto',
  padding: '7px 16px',
  marginRight: '10px',
}));

export const StyledWarningIcon = styled('img')`
  width: 36px;
  height: 36px;
  margin-left: 48px;
  margin-top: 48px;
`;

export const StyledCloseIconButton = styled(IconButton)`
  width: fit-content;
  position: absolute;
  right: 10px;
  top: 8px;
`;
