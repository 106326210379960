import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus } from 'types';

import { StatsState } from '../../../interfaces';
import { getActiveMembers } from './getActiveMembers';

export const getActiveMembersExtraReducer = (builder: ActionReducerMapBuilder<StatsState>) => {
  builder.addCase(getActiveMembers.pending, (state, action) => {
    state.activeMembers.error = null;
    state.activeMembers.isLoading = true;
    state.activeMembers.fetchingStatus = FetchingStatus.PENDING;
  });
  builder.addCase(getActiveMembers.fulfilled, (state, action) => {
    state.activeMembers.fetchingStatus = FetchingStatus.FULFILLED;
    state.activeMembers.error = null;
    state.activeMembers.isLoading = false;
    state.activeMembers.data = action.payload;
  });

  builder.addCase(getActiveMembers.rejected, (state, action) => {
    state.activeMembers.fetchingStatus = FetchingStatus.REJECTED;
    state.activeMembers.error = action.error;
    state.activeMembers.isLoading = false;
  });
};
