import { styled, Menu } from '@mui/material';

export const HeaderWrapper = styled('header')(({ theme }) => ({
  padding: '16px 24px',
  background: theme.palette.neutralLight.light,
  gap: 16,
  minHeight: 72,
  top: 0,
  zIndex: 2,
  position: 'sticky',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.title': {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 14,
    fontWeight: 900,
    lineHeight: 1.57,
    color: theme.palette.neutralDark.contrastText,
    textTransform: 'uppercase',
  },

  '.user': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&__name': {
      position: 'relative',
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
      '.version': {
        position: 'absolute',
        left: '-85px',
        top: '2px',
      },
    },

    '&__avatar': {
      width: 40,
      height: 40,
      marginLeft: 8,
      color: theme.palette.primary.main,
      background: theme.palette.primaryLight.main,
      fontFamily: 'Roboto-Medium',
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.22,
      cursor: 'pointer',

      ':hover': {
        border: `2px solid ${theme.palette.primary.main}`,
      },

      '&_active': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
  },
}));

export const MenuWrapper = styled(Menu)(({ theme }) => ({
  '.MuiPaper-root': {
    marginTop: 4,
    marginLeft: -9,
    minWidth: 151,
  },
}));
