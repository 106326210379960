import { TableCell } from '@mui/material';

import { CouponTableProps } from 'types';

import { StyledTableRow } from './CouponTable.styles';

export interface TableCellRowProps {
  data: CouponTableProps;
  borderEnd: boolean;
}

const TableCellRow: React.FC<TableCellRowProps> = ({ data, borderEnd }) => {
  const { coupons, partner_id, partner_name } = data;

  return (
    <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: borderEnd ? '' : 0 } }}>
      <TableCell className="title"></TableCell>
      <TableCell className="provider">{partner_name}</TableCell>
      <TableCell className="provider">{partner_id}</TableCell>
      <TableCell className="title">{coupons}</TableCell>
    </StyledTableRow>
  );
};

export default TableCellRow;
