import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { CCPDefaultButton, Spacer } from 'components';
import { EmployeeDetailsStyled } from './EmployeeDetails.styles';
import { EmployeeDetailsProps } from './interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { EmployeeManagementSelectors } from 'features/employeeManagement/store/employeeManagement.selectors';
import { Employee, EmployeeStatus } from 'features/employeeManagement/store/interfaces';
import { useIntl } from 'react-intl';
import { CustomFields } from 'features/employeeManagement/components/EmployeeGeneralInfo/CustomFields';
import { useGendersList } from 'utils/genders';
import { useEmployeeTitles } from 'utils/employeeTitle';
import { FormatDate } from 'components/FormatDate';
import { GlobalCustomFields } from 'features/employeeManagement/components/EmployeeGeneralInfo/GlobalCustomFileds';
import EmployeeStatusChip from 'features/employeeManagement/components/EmployeeOverview/EmployeeTable/Columns/Status';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import { GoToSettings } from 'components';
import { useLanguage } from 'hooks';
import { EmployeeManagementActions } from 'features/employeeManagement/store';
import ResendLetterModal from '../../EmployeeOverview/Modals/ResendLetterModal';
import EmployeeRequestModal, {
  initRequestModal,
} from '../../EmployeeOverview/Modals/EmployeeRequestModal';
import { useNavigate } from 'react-router-dom';
import { getCrutchStatusInfo } from '@utils/employeeOverview';

const EmployeeDetails: React.FC<EmployeeDetailsProps> = ({ handleEditProfile, allowEdit }) => {
  const currentLanguage = useLanguage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const employeeApi = useAppSelector(EmployeeManagementSelectors.getGetEmployeeApi);
  const employee = employeeApi.data as Employee;
  const contracts = useAppSelector(state => state.contracts.getContracts.data);
  const [requestModal, setRequestModal] = useState(initRequestModal);

  const allProducts = contracts.reduce((result: string[], currentContract) => {
    currentContract.products.forEach(product => {
      if (!result.includes(product.name)) {
        result.push(product.name);
      }
    });
    return result;
  }, []);

  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const { activationLetterRecipientMode } = useAppSelector(SettingsSelectors.settingsSettings);
  const intl = useIntl();
  const genders = useGendersList();
  const genderValue = genders.find(gender => gender.value === employee.gender)?.title || '';
  const employeeTitles = useEmployeeTitles();
  const titleValue = employeeTitles.find(title => title.value === employee.title)?.title || '';
  const disableResendCta =
    employee.status === 'locked' ||
    employee.status === 'blocked' ||
    employee.status === 'deleted' ||
    employee.status === 'denied' ||
    employee.status === 'applied' ||
    employee.status === 'unassigned'
      ? true
      : false;

  const [goToSettingsModal, setGoToSettingsModal] = useState<boolean>(false);
  const [goToSettingsEUModal, setGoToSettingsEUModal] = useState<boolean>(false);
  const [resendModal, setResendModal] = useState<boolean>(false);

  const isCuListEmpty =
    activationLetterRecipientMode.toLowerCase() === 'cu' &&
    activationLetterDistributionList.length === 0;
  const noEuEmail = activationLetterRecipientMode.toLowerCase() === 'eu' && !employee.businessEmail;

  const resendActivationCode = () => {
    if (isCuListEmpty) {
      setGoToSettingsEUModal(false);
      setGoToSettingsModal(true);
    } else if (noEuEmail) {
      setGoToSettingsEUModal(true);
      setGoToSettingsModal(false);
    } else {
      dispatch(
        EmployeeManagementActions.resendActivationLetter({
          endUserId: employee.id,
          language: currentLanguage,
        }),
      );
    }
  };

  const closeRequestModal = () => {
    setRequestModal(initRequestModal);
  };

  const submitRequestModal = async ({ transition = '', startDate = '', endDate = '' }) => {
    const updateEmployeeStatus = {
      employeeId: employee.id,
      transition,
      startDate,
      endDate,
    };

    await dispatch(EmployeeManagementActions.changeEmployeeStatus(updateEmployeeStatus));
    closeRequestModal();
    if (transition === EmployeeStatus.DENIED) {
      navigate(`/${currentLanguage}/employee-management`);
    } else {
      dispatch(EmployeeManagementActions.getEmployee({ employeeId: employee.id.toString() }));
    }
  };

  const employeeStatusInfo = getCrutchStatusInfo(employee);

  return (
    <EmployeeDetailsStyled>
      <Typography variant='body1' className='rightSideHeader'>
        {intl.formatMessage({
          id: 'employee.details.general_info',
          defaultMessage: 'General info',
        })}
      </Typography>
      <Spacer height={36} />

      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='body2' className='employeeName notranslate'>
          {titleValue ? titleValue + ' ' : ''}
          {employee.firstName} {employee.lastName}
        </Typography>
        <EmployeeStatusChip
          status={employeeStatusInfo.status}
          date={employeeStatusInfo.date}
          plannedStatus={employeeStatusInfo.plannedStatus}
        />
      </Stack>
      <Spacer height={8} />
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='body2' className='employeeUnderNameText'>
          {`${intl.formatMessage({ id: 'employee.details.user_id', defaultMessage: 'User ID' })} ${
            employee.id
          }`}
        </Typography>
        {employee.status === EmployeeStatus.ACTIVE && (
          <Typography variant='body2' className='employeeUnderNameText'>
            {intl.formatMessage({ id: 'employee.details.from', defaultMessage: 'From' })}{' '}
            {FormatDate(employee.entryDate)}
          </Typography>
        )}
        {employee.status === EmployeeStatus.INACTIVE && (
          <Typography variant='body2' className='employeeUnderNameText'>
            {intl.formatMessage({ id: 'employee.details.till_big', defaultMessage: 'Till' })}{' '}
            {FormatDate(employee.exitDate)}
          </Typography>
        )}
      </Stack>
      <Spacer height={24} />
      <Stack direction='row' justifyContent='space-between'>
        <Box>
          <Spacer height={12} />
          <Typography variant='body2' className='employeeInfoHeader'>
            {intl.formatMessage({
              id: 'employee.details.personal_info',
              defaultMessage: 'Personal info',
            })}
          </Typography>
          <Spacer height={16} />
          <Stack direction='row' spacing={6}>
            <Box className='infoColumn'>
              <Typography variant='body2'>
                {intl.formatMessage({
                  id: 'form.employee.field.gender.label',
                  defaultMessage: 'Gender',
                })}
                :
              </Typography>
              <Typography variant='body2' className='infoData gender notranslate'>
                {genderValue}
              </Typography>
            </Box>
            <Box className='infoColumn'>
              <Typography variant='body2'>
                {intl.formatMessage({
                  id: 'form.employee.field.birthday.label',
                  defaultMessage: 'Birthday date',
                })}
                :
              </Typography>
              <Typography variant='body2' className='infoData'>
                {FormatDate(employee.dateOfBirth)}
              </Typography>
            </Box>
            <Box className='infoColumn'>
              <Typography variant='body2'>
                {intl.formatMessage({
                  id: 'form.employee.field.phone.label',
                  defaultMessage: 'Phone number',
                })}
                :
              </Typography>
              <Typography variant='body2' className='infoData'>
                {employee.phoneNumber}
              </Typography>
            </Box>
            <Box className='infoColumn'>
              <Typography variant='body2'>
                {intl.formatMessage({
                  id: 'form.employee.field.corporate_email.label',
                  defaultMessage: 'Corporate email',
                })}
                :
              </Typography>
              <Typography variant='body2' className='infoData notranslate'>
                {employee.businessEmail}
              </Typography>
            </Box>
          </Stack>
          <Spacer height={16} />

          <GlobalCustomFields data={employee.globalCustomFields} isAddressFields />
        </Box>
      </Stack>
      <Spacer height={36} />
      <Typography variant='body2' className='employeeInfoHeader'>
        {intl.formatMessage({
          id: 'employee.details.membership_info',
          defaultMessage: 'Membership info',
        })}
      </Typography>
      <Spacer height={11} />
      <Stack direction='row' spacing={6}>
        <Box className='infoColumn'>
          <Typography variant='body2'>
            {intl.formatMessage({
              id: 'form.employee.field.start_date.label',
              defaultMessage: 'Start date',
            })}
            :
          </Typography>
          <Typography variant='body2' className='infoData'>
            {FormatDate(employee.entryDate)}
          </Typography>
        </Box>
        {employee.exitDate && (
          <Box className='infoColumn'>
            <Typography variant='body2'>
              {intl.formatMessage({
                id: 'form.employee.field.end_date.label',
                defaultMessage: 'End date',
              })}
              :
            </Typography>
            <Typography variant='body2' className='infoData'>
              {FormatDate(employee.exitDate)}
            </Typography>
          </Box>
        )}
      </Stack>
      <Spacer height={36} />
      {!(
        employee.status === EmployeeStatus.INACTIVE || employee.status === EmployeeStatus.PAUSED
      ) && (
        <Stack direction='row' spacing={2}>
          {allProducts &&
            allProducts.length > 0 &&
            allProducts.map(item => (
              <Box key={item} className='productChip'>
                {item}
              </Box>
            ))}
        </Stack>
      )}
      <Spacer height={48} />
      <GlobalCustomFields data={employee.globalCustomFields} />
      <CustomFields data={employee.customFields} />
      <Stack direction='row' justifyContent='flex-end' spacing={2}>
        {employee.status === EmployeeStatus.APPLIED ? (
          <>
            <CCPDefaultButton
              variant='outlined'
              color='error'
              onClick={() => setRequestModal({ denyModal: true, confirmModal: false })}>
              {intl.formatMessage({
                id: 'employee.details.action.deny',
                defaultMessage: 'Deny request',
              })}
            </CCPDefaultButton>
            <CCPDefaultButton
              variant='contained'
              onClick={() => setRequestModal({ denyModal: false, confirmModal: true })}>
              {intl.formatMessage({
                id: 'employee.details.action.confirm',
                defaultMessage: 'Confirm request',
              })}
            </CCPDefaultButton>
          </>
        ) : (
          <CCPDefaultButton
            variant='outlined'
            disabled={disableResendCta}
            onClick={() =>
              isCuListEmpty
                ? setGoToSettingsModal(true)
                : noEuEmail
                ? setGoToSettingsEUModal(true)
                : setResendModal(true)
            }>
            {intl.formatMessage({
              id: 'employee.details.action.re_send_activation_code',
              defaultMessage: 'Resend activation code',
            })}
          </CCPDefaultButton>
        )}
        {allowEdit && (
          <CCPDefaultButton
            disabled={employee.status === EmployeeStatus.DENIED}
            variant='contained'
            onClick={() => handleEditProfile(true)}>
            {intl.formatMessage({
              id: 'employee.details.action.edit_profile',
              defaultMessage: 'Edit profile',
            })}
          </CCPDefaultButton>
        )}
      </Stack>
      {(requestModal.confirmModal || requestModal.denyModal) && (
        <EmployeeRequestModal
          employeeTitle={titleValue}
          employeeName={`${employee.firstName} ${employee.lastName}`}
          employeeId={employee.id}
          transitionType={
            requestModal.confirmModal ? EmployeeStatus.PENDING : EmployeeStatus.DENIED
          }
          close={closeRequestModal}
          onSubmit={submitRequestModal}
          transitionDate={requestModal.confirmModal ? employee.entryDate : undefined}
        />
      )}
      {(goToSettingsModal || goToSettingsEUModal) && (
        <GoToSettings
          isOpen={goToSettingsModal || goToSettingsEUModal}
          isResendLetted={goToSettingsModal}
          isResendToEUWithEmptyEmail={goToSettingsEUModal}
          handleClose={() => {
            setGoToSettingsModal(false);
            setGoToSettingsEUModal(false);
          }}
        />
      )}
      {resendModal && (
        <ResendLetterModal close={() => setResendModal(false)} onSubmit={resendActivationCode} />
      )}
    </EmployeeDetailsStyled>
  );
};

export default EmployeeDetails;
