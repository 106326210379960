import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { default as theme } from '@theme/theme';

import { NoResultsWrapper } from './InvoicesTable.styles';
import { useIntl } from 'react-intl';

const CustomNoResultsOverlay = () => {
  const { formatMessage } = useIntl();
  return (
    <NoResultsWrapper>
      <Box className='container'>
        <ErrorOutlineIcon htmlColor={theme.palette.warning.main} />
        <Typography className='container__title' variant='body1'>
          {formatMessage({
            id: 'view.invoice.table.no_found.title',
            defaultMessage: 'No invoices found',
          })}
        </Typography>
        <Typography className='container__text' variant='body1'>
          {formatMessage({
            id: 'view.invoice.table.no_found.description',
            defaultMessage:
              'No invoices were found for your selection. Change your search criteria and try again.',
          })}
        </Typography>
      </Box>
    </NoResultsWrapper>
  );
};

export default CustomNoResultsOverlay;
