import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { addEmployeeExtraReducer } from './actions/async/addEmployee/addEmployee.reducer';
import { getEmployeesExtraReducer } from './actions/async/getEmployees/getEmployees.reducer';
import {
  resetGetEmployeesStatus,
  resetApi,
  resetAddEmployeeApi,
  resetChangeEmployeeApi,
  setInitialTableConfiguration,
  updateRowsPerPage,
  toggleAllColumns,
  toggleColumn,
  updateCurrentPage,
  updateFilters,
  updateSearchTerm,
  updateSearchVisibility,
  resetSearchData,
  resetTableConfiguration,
  setCurrentStep,
  resetFilters,
  setAddEmployeeModalOpen,
  resetBulkImportCsvFile,
} from './actions/sync/employeeManagement.actions';
import { changeEmployeeExtraReducer } from './actions/async/changeEmployee/changeEmployee.reducer';
import { getEmployeeExtraReducer } from './actions/async/getEmployee/getEmployee.reducer';
import { cancelImportProgressReducer } from './actions/async/cancelImportProgress/cancelImportProgress.reducer';
import { createEndUserExtraReducer } from './actions/async/createEndUser/createEndUser.reducer';
import getBulkUploadFileExtraReducer from './actions/async/getBulkUploadFile/getBulkUploadFile.reducer';
import { getEmployeeLicenseExtraBuilder } from './actions/async/getEmployeeLicense/getEmployeeLicense.reducer';
import { changeEmployeeStatusExtraReducer } from './actions/async/changeEmployeeStatus/changeEmployeeStatus.reducer';
import { getEmployeeStatusExtraReducer } from './actions/async/getEmployeeStatus/getEmployeeStatus.reducer';
import { bulkStatusChangeExtraReducer } from './actions/async/bulkStatusChange/bulkStatusChange.reducer';
import uploadCsvFileExtraReducer from './actions/async/uploadCsv/uploadCsvFile.reducer';
import { updateCsvFileExtraReducer } from './actions/async/updateCsv/updateCsvFile.reducer';

const employeeManagementSlice = createSlice({
  name: 'employeeManagement',
  initialState,
  reducers: {
    resetApi,
    resetGetEmployeesStatus,
    setCurrentStep,
    resetAddEmployeeApi,
    resetChangeEmployeeApi,
    setInitialTableConfiguration,
    resetTableConfiguration,
    toggleColumn,
    updateRowsPerPage,
    toggleAllColumns,
    updateFilters,
    updateCurrentPage,
    updateSearchTerm,
    updateSearchVisibility,
    resetSearchData,
    resetFilters,
    setAddEmployeeModalOpen,
    resetBulkImportCsvFile,
  },
  extraReducers: builder => {
    addEmployeeExtraReducer(builder);
    getEmployeesExtraReducer(builder);
    getEmployeeExtraReducer(builder);
    getEmployeeStatusExtraReducer(builder);
    changeEmployeeExtraReducer(builder);
    createEndUserExtraReducer(builder);
    uploadCsvFileExtraReducer(builder);
    updateCsvFileExtraReducer(builder);
    cancelImportProgressReducer(builder);
    getBulkUploadFileExtraReducer(builder);
    getEmployeeLicenseExtraBuilder(builder);
    changeEmployeeStatusExtraReducer(builder);
    bulkStatusChangeExtraReducer(builder);
  },
});

export const { name, actions } = employeeManagementSlice;
export const employeeManagementReducer = employeeManagementSlice.reducer;
