import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { DefaultNoResultsOverlayStyled } from './NoResultsOverlay.styles';
import { Spacer } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import SearchResultsIcon from 'assets/icons/illustrations-search-results.svg';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { TablesManagementActions } from '../store';
import { CompanyResponse, CompanyCustomFieldType } from 'types';
import { DataGridTableConfiguration } from '../store/interfaces';
import { useIntl } from 'react-intl';

const DefaultNoResultsOverlay: React.FC<{
  tableConfiguration: DataGridTableConfiguration;
  data: any[];
  tableName: string;
  rowsData: any[];
  noRecordsTranslation: string;
  addRecordsTranslation: string;
  addNewRecords?: () => void;
}> = ({
  tableConfiguration,
  data,
  tableName,
  rowsData,
  noRecordsTranslation,
  addRecordsTranslation,
  addNewRecords = undefined,
}) => {
  const { formatMessage } = useIntl();
  const resetFiltersTranslation = formatMessage({
    id: 'grid.reset_filters',
    defaultMessage: '<tag>Click here</tag> to reset them and get results.',
  });
  const dispatch = useAppDispatch();

  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);

  const handleResetAll = useCallback(() => {
    //do something
    if (activeCompany && data) {
      dispatch(
        TablesManagementActions.resetTableConfiguration({
          tableName,
          company: {
            id: activeCompany?.companyId,
            customFields: [] as CompanyCustomFieldType[],
          } as CompanyResponse,
          tableData: data,
        }),
      );
    }
  }, [dispatch, activeCompany, data, tableName]);

  return (
    <DefaultNoResultsOverlayStyled>
      <Box component='img' className='noResultsIcon' src={SearchResultsIcon} />
      <Spacer height={36} />

      <Typography variant='body2' component='h2' className='header'>
        {noRecordsTranslation
          ? noRecordsTranslation
          : formatMessage({
              id: 'grid.no_records',
              defaultMessage: 'Sorry, you have no any records.',
            })}
      </Typography>
      <Spacer height={16} />
      {addNewRecords !== undefined &&
        rowsData.length === 0 &&
        tableConfiguration?.filters &&
        tableConfiguration.filters.items.length === 0 && (
          <Typography variant='body2' className='additionalInfo'>
            <span className='resetAllBtn' onClick={addNewRecords}>
              {addRecordsTranslation.split('</tag>')[0].replace('<tag>', '')}
            </span>{' '}
            {addRecordsTranslation.split('</tag>')[1]}
          </Typography>
        )}
      {tableConfiguration?.filters && tableConfiguration.filters.items.length > 0 && (
        <Typography variant='body2' className='additionalInfo'>
          {formatMessage({
            id: 'grid.no_records',
            defaultMessage: `It looks like you recently applied some filters.`,
          })}{' '}
          <span className='resetAllBtn' onClick={handleResetAll}>
            {resetFiltersTranslation.split('</tag>')[0].replace('<tag>', '')}
          </span>{' '}
          {resetFiltersTranslation.split('</tag>')[1]}
        </Typography>
      )}
    </DefaultNoResultsOverlayStyled>
  );
};

export default DefaultNoResultsOverlay;
