import EditIcon from '@mui/icons-material/Edit';
import { Paper, Table, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useIntl } from 'react-intl';
import { CompanyCustomFieldType, TranslationsKeys } from 'types';
import { useInputType } from 'utils/customField';
import { StyledTableBody, StyledTableHead } from './CustomFieldTable.style';
import { useLanguage } from 'hooks';
import { default as theme } from '@theme/theme';

type CustomFieldTableProps = {
  data: CompanyCustomFieldType[];
  companyLanguage: TranslationsKeys;
  handleEdit: ({ data, type }: { data: CompanyCustomFieldType; type: 'add' | 'edit' }) => void;
};

const CustomFieldTable = ({ data, companyLanguage, handleEdit }: CustomFieldTableProps) => {
  const intl = useIntl();
  const inputTypes = useInputType();
  const portalLanguage = useLanguage();
  const fallbackLanguage: TranslationsKeys = 'en';

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell className='table_cell'>
                {intl.formatMessage({
                  id: 'setting.custom_field.table.header.name',
                  defaultMessage: 'Custom field name',
                })}
              </TableCell>
              <TableCell className='table_cell'>
                {intl.formatMessage({
                  id: 'setting.custom_field.table.header.type',
                  defaultMessage: 'Type',
                })}
              </TableCell>
              <TableCell className='table_cell'>
                {intl.formatMessage({
                  id: 'setting.custom_field.table.header.mandatory',
                  defaultMessage: 'Mandatory',
                })}
              </TableCell>
              <TableCell className='table_cell'>
                {intl.formatMessage({
                  id: 'setting.custom_field.table.header.language',
                  defaultMessage: 'Language',
                })}
              </TableCell>
              <TableCell className='table_cell' />
            </TableRow>
          </StyledTableHead>
          <StyledTableBody>
            {data.map((customField, index) => {
              const customFieldValue = customField.name
                ? Object.keys(customField.name).filter(
                    item => customField.name[item as TranslationsKeys],
                  )
                : [];
              return (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                  <TableCell>
                    {customField.name[portalLanguage] ||
                      customField.name[fallbackLanguage] ||
                      customField.name[companyLanguage]}
                  </TableCell>
                  <TableCell>
                    {inputTypes.find(type => type.value === customField.inputType)?.title}
                  </TableCell>
                  <TableCell>
                    {customField.mandatory === 'yes'
                      ? intl.formatMessage({
                          id: 'setting.custom_field.table.cell.mandatory_yes',
                          defaultMessage: 'Yes',
                        })
                      : intl.formatMessage({
                          id: 'setting.custom_field.table.cell.mandatory_no',
                          defaultMessage: 'No',
                        })}
                  </TableCell>
                  <TableCell>
                    {customFieldValue.map((language, index) => (
                      <span key={index} className='language'>
                        {language}
                        {index !== customFieldValue.length - 1 ? ', ' : ''}
                      </span>
                    ))}
                  </TableCell>
                  <TableCell>
                    <Box
                      className='edit'
                      onClick={() => handleEdit({ data: customField, type: 'edit' })}>
                      <EditIcon htmlColor={theme.palette.neutralDark.main} fontSize='small' />
                      <Typography>
                        {intl.formatMessage({
                          id: 'employee_management.bulk_upload.table.column.action.edit',
                          defaultMessage: 'Edit',
                        })}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </StyledTableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomFieldTable;
