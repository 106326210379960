import { styled, Alert, Button, Paper } from '@mui/material';

export const MainBlock = styled(Paper)(({ theme }) => ({
  height: '100%',
  padding: '50px 0',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  overflow: 'auto',

  p: {
    color: theme.palette.neutralDark.main,
    fontSize: 14,
    lineHeight: '22px',
    margin: 0,
  },

  h1: {
    fontSize: 20,
    lineHeight: '32px',
    marginBottom: 16,
    fontWeight: 'bold',
  },

  '.steps': {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '32px',
    position: 'relative',
    '&:before': {
      content: '""',
      height: 2,
      width: '80%',
      backgroundColor: theme.palette.primaryLight.main,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    button: {
      margin: '5px 8px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.primaryLight.main,
      color: theme.palette.primary.dark,
      fontSize: 13,
      lineHeight: '24px',
      letterSpacing: '0.12px',
      padding: '1px 13px',
      minWidth: 'unset',

      svg: {
        fontSize: 18,
        marginLeft: -8,
        marginRight: 8,
      },

      '&.active': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.neutralLight.light,
      },

      '.step-no': {
        paddingRight: 8,
        fontWeight: 'bold',
      },
    },
  },

  '.error-view': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 0',

    '.desc': {
      fontSize: 16,
      lineHeight: '22px',
      letterSpacing: '0.15px',
      color: theme.palette.neutralDark.contrastText,
      fontWeight: 'bold',
      margin: '16px 0',
    },

    button: {
      marginTop: 20,
    },
  },
  '.bulk-upload-wrapper': {
    width: '100%',
    padding: '24px 0px',
  },
}));

export const FileInput = styled('input')(() => ({
  height: '0',
  width: '0',
  overflow: 'hidden',
  visibility: 'hidden',
}));

export const IconContainer = styled('div')(({ theme }) => ({
  width: 'fit-content',
  marginBottom: 10,
  svg: {
    padding: 1,
    height: 40,
    width: 40,
    fill: theme.palette.error.main,
    position: 'relative',
    top: 3,
  },
}));

export const TableContainer = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: 40,

  '.employee-table-footer': {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '14px',
  },

  '.cancel-import-btn': {
    marginRight: 16,
  },

  '.edit-button': {
    textTransform: 'unset',
    color: theme.palette.neutralDark.main,
    margin: '0 4px',
    minWidth: 'auto',
  },
  '.duplicate-button': {
    display: 'inline-flex',
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.neutralDark.contrastText,
    fontWeight: 'bold',
    padding: '2px 8px',
    borderRadius: '4px',
    margin: '0 8px',
    height: 24,
  },

  '.bold': {
    color: theme.palette.neutralDark.contrastText,
    fontWeight: 'bold',
  },

  'span.duplicate-email': {
    color: theme.palette.warning.main,
  },

  'span.duplicate-email-in-template': {
    color: theme.palette.error.main,
  },
}));

export const RoundedCornerButton = styled(Button)(() => ({
  fontWeight: 'bold',
  lineHeight: '22px',
  borderRadius: '25px',
  marginLeft: '25px',
  marginRight: '15px',
  textTransform: 'none',
}));

export const FileUploadTemplateContainerRoot = styled('div')(() => ({
  marginTop: '50px',
}));

export const InfoAlert = styled(Alert)(({ theme }) => ({
  background: 'none',
  color: theme.palette.neutralDark.contrastText,
  textAlign: 'left',
  border: `1px solid  ${theme.palette.neutralLight.dark}`,
}));

export const CardTitle = styled('h1')(() => ({
  fontFamily: 'NutmegHeadline-UltraBlack',
  fontSize: '20px',
  textTransform: 'uppercase',
}));

export const CardSubtitle = styled('p')(() => ({
  fontSize: '16px!important',
}));

export const BulkImportFooter = styled('div')(({ theme }) => ({
  padding: '40px 16px',
  height: 64,
  background: theme.palette.neutralLight.light,
  gap: 16,
  bottom: 0,
  zIndex: 2,
  position: 'sticky',
  display: 'flex',
  alignItems: 'center',
  //justifyContent: 'flex-end',
  boxShadow: `0 10px 10px 0 ${theme.palette.transparent.boxShadowDark01}`,
  justifyContent: 'space-between',
  transform: 'translateX(-24px)',
  width: 'calc(100% + 48px)',
  '.cancel-import-link': {
    flex: 1,
    textDecoration: 'none',
  },
}));
