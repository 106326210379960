import { Auth } from 'aws-amplify';
import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { AxiosConfig } from '../interfaces';

export const patchHeaders = {
  headers: {
    'content-type': 'application/merge-patch+json',
  },
};

export const axiosWithConfig = async ({
  withAuthentication = true,
  headers = {},
}: AxiosConfig = {}): Promise<AxiosInstance> => {
  const customHeaders: AxiosRequestHeaders = { ...headers };

  try {
    if (withAuthentication) {
      const currentSession = await Auth.currentSession();
      customHeaders['Authorization'] = 'Bearer ' + currentSession.getIdToken().getJwtToken();
    }
  } catch (err) {
    if (err instanceof Error && err.name === 'NotAuthorizedException') {
      const activeCompanyId = window.localStorage.getItem('activeCompanyId') || '';
      window.localStorage.clear();
      window.localStorage.setItem('lastOpenedPathname', window.location.pathname);
      window.localStorage.setItem('activeCompanyId', activeCompanyId);
      window.location.href = `${window.location.origin}/${
        (
          document.cookie.split('; ').reduce((result, item) => {
            let cookie = item.split('=');
            return { ...result, ...JSON.parse(`{"${cookie[0]}": "${cookie[1]}"}`) };
          }, {}) as any
        ).language_app || 'de'
      }/login?session=expired`;
    }
  }
  return axios.create({
    timeout: 30000,
    headers: customHeaders,
  });
};
