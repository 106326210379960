import { Box, Accordion, styled } from '@mui/material';

export const TileAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: '4px',
  border: `solid 1px ${theme.palette.neutralLight.main}`,
  boxShadow: 'none',
  '&.Mui-expanded .expand-icon': {
    transform: 'rotate(180deg)',
  },
  '.accordion-header': { float: 'right', padding: '0 24px', cursor: 'default' },
  '.accordion-details': {
    marginTop: '-98px',
    padding: '24px',
  },
  '& .productChip': {
    padding: '1px 11px 1px 12px',
    borderRadius: 11,
    border: `1px solid  ${theme.palette.neutralLight.dark}`,
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
    textTransform: 'capitalize',
    '&.expired': {
      color: theme.palette.neutralDark.main,
    },
  },
  '& .productChipWithIcon': {
    padding: '1px 4px 1px 3px!important',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: 4,
      width: 18,
      height: 18,
    },
  },
}));

export const TileWrapper = styled(Box)(({ theme }) => ({
  padding: '24px',
  borderRadius: 4,
  border: `solid 1px ${theme.palette.neutralLight.main}`,
  '.tile-header': {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  // '.statusWrapper': {
  //   height: 22,
  //   fontFamily: 'NutmegHeadline-UltraBlack',
  //   fontSize: 14,
  //   lineHeight: 1.57,
  //   color: theme.palette.neutralDark.contrastText,
  //   '&.expired': {
  //     color: theme.palette.neutralDark.main,
  //   },
  // },
  '& .productChip': {
    padding: '1px 11px 1px 12px',
    borderRadius: 11,
    border: `1px solid  ${theme.palette.neutralLight.dark}`,
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
    textTransform: 'capitalize',
    '&.expired': {
      color: theme.palette.neutralDark.main,
    },
  },
  '& .productChipWithIcon': {
    padding: '1px 4px 1px 3px!important',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: 4,
      width: 18,
      height: 18,
    },
  },
  // '& tr': {
  //   verticalAlign: 'top',
  // },
}));
