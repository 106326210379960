import { styled, Box } from '@mui/material';

export const StatsWrapper = styled(Box)(({ theme }) => ({
  '.cards': {
    gap: 24,
    display: 'flex',

    '&__item': {
      flex: 1,
      borderRadius: 4,
      background: theme.palette.neutralLight.light,
      padding: '36px 36px 38px',

      '&:first-child .cards__item_info_title': {
        color: theme.palette.primary.main,
      },

      '&:last-child .cards__item_info_title': {
        // color: {theme.palette.warning.main},
      },

      '&_info': {
        gap: 4,
        minHeight: 182,
        border: `2px solid ${theme.palette.primaryLight.main}`,
        padding: 16,
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',

        '&_title': {
          fontSize: 36,
          fontFamily: 'NutmegHeadline-UltraBlack',
          fontWeight: 900,
          color: theme.palette.success.main,
        },

        '&_text': {
          fontSize: 16,
          lineHeight: 1.38,
          color: theme.palette.neutralDark.main,
        },
        '&_error': {
          fontSize: 16,
          lineHeight: 1.38,
          color: theme.palette.neutralDark.main,
        },
        '&_iconError': {
          width: 36,
          height: 36,
          marginBottom: 12,
        },
      },

      '&_title': {
        fontFamily: 'NutmegHeadline-UltraBlack',
        fontSize: 20,
        fontWeight: 900,
        lineHeight: 1.6,
        color: theme.palette.neutralDark.contrastText,
        textTransform: 'uppercase',
        margin: '48px 0 16px',
        textAlign: 'center',
      },

      '&_text': {
        fontSize: 16,
        lineHeight: 1.38,
        color: theme.palette.neutralDark.contrastText,
        marginBottom: 36,
        textAlign: 'center',
      },

      button: {
        width: '100%',
      },
    },
  },
  '.loadingWrapper': {
    height: 490,
    borderRadius: 4,
    margin: '0px',
    backgroundColor: theme.palette.neutralLight.light,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& .loadingTitle': {
      fontSize: 16,
      fontFamily: 'Roboto-Medium',
      margin: '36px 33px 16px 32px;',
      color: theme.palette.neutralDark.contrastText,
      lineHeight: 1.38,
      letterSpacing: 0.15,
    },
    '& .loadingContent': {
      fontSize: 16,
      color: theme.palette.neutralDark.main,

      lineHeight: 1.38,
    },
  },
}));
