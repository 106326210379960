import { styled, Box } from '@mui/material';

export const CardWrapper = styled(Box)(({ theme }) => ({
  gap: 2,
  display: 'flex',
  marginBottom: 24,

  '&:last-child': {
    marginBottom: 0,
  },

  '.dashboard-chip': {
    display: 'inline-flex',
    padding: '2px 4px 2px 8px',
    alignItems: 'center',
    gap: '2px',
    borderRadius: '9999px',
    background: '#F1F5F9',
    fontFamily: "'Roboto-Regular'",
    textTransform: 'initial',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#0F172A',
    '&:hover': {
      background: '#94A3B8',
      borderColor: '#94A3B8',
    },
  },

  '.state': {
    flex: 1,
    padding: 24,
    background: theme.palette.neutralLight.light,
    borderRadius: '4px 0 0 4px',

    '&_title': {
      fontFamily: 'NutmegHeadline-UltraBlack',
      fontSize: 14,
      fontWeight: 900,
      lineHeight: 1.57,
      color: theme.palette.neutralDark.contrastText,
      textTransform: 'uppercase',
      paddingBottom: 12,
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
    },

    '&_info': {
      gap: 16,
      margin: '12px 0 36px',
      display: 'flex',

      '&_item': {
        gap: 4,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 24,
        borderRadius: 8,
        border: `1px solid  ${theme.palette.neutralLight.dark}`,

        // '&:last-child .state_info_item_title': {
        //   color: {theme.palette.warning.main},
        // },

        '&_title': {
          fontFamily: 'NutmegHeadline-UltraBlack',
          fontSize: 36,
          lineHeight: '44px',
          fontWeight: 900,
          color: theme.palette.success.main,
        },

        '&_text': {
          fontSize: 16,
          lineHeight: 1.38,
          color: theme.palette.neutralDark.main,
        },
      },
    },

    '&_footer': {
      gap: 16,
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },

  '.info': {
    width: '100%',
    maxWidth: 448,
    padding: 24,
    background: theme.palette.neutralLight.light,
    borderRadius: '0 4px 4px 0',

    '&_status': {
      fontFamily: 'Roboto-Medium',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.29,
      color: theme.palette.neutralLight.light,
      textTransform: 'uppercase',
      borderRadius: 4,
      width: 'fit-content',
      padding: '4px 16px 2px',
      margin: '16px 0 36px',
    },

    '&_title': {
      fontFamily: 'Roboto-Medium',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.57,
      color: theme.palette.neutralDark.main,
      marginBottom: 16,
      textTransform: 'uppercase',
    },

    '&_text': {
      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
    },
  },
}));
