import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, ClickAwayListener } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InsertInvitationRoundedIcon from '@mui/icons-material/InsertInvitationRounded';

import { CompanySelectors } from 'features/company/store/company.selectors';
import { MonthPickerProps, RootState, FiltersProps } from 'types';

import MonthRange from './MonthRange';
import { MonthPickerWrapper } from './MonthPicker.styles';
import { useMonthList } from 'hooks';
import { useIntl } from 'react-intl';
import { getStatsMonth } from '../../../../utils';
import { CCPRectButton } from '@components/index';
import { default as theme } from '@theme/theme';

const MonthPicker: React.FC<MonthPickerProps> = ({ filters, updateFilters, updateValues }) => {
  const intl = useIntl();
  const monthList = useMonthList();
  const { coupons } = useSelector((state: RootState) => state.stats);
  const { checkIns } = useSelector((state: RootState) => state.stats);
  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);

  const [isOpenRange, setIsOpenRange] = useState<boolean>(false);
  const [filtersList, setFiltersList] = useState<FiltersProps>({
    endYear: filters.endYear,
    endMonth: filters.endMonth,
    startYear: filters.startYear,
    startMonth: filters.startMonth,
  });
  const { endYear, endMonth, startYear, startMonth } = filtersList;
  const fullDate = `${monthList[startMonth] || ''} ${startYear} - ${
    monthList[endMonth] || ''
  } ${endYear}`;
  const disabled = checkIns.isLoading || coupons.isLoading;
  const isOpen = !disabled && isOpenRange;

  const openDropDownToggler = (): void => {
    setIsOpenRange(!isOpenRange);

    if (isOpenRange) {
      resetFilters();
    }
  };

  const outsideClickHandler = (): void => {
    setIsOpenRange(false);
    resetFilters();
  };

  const changeFiltersHandler = (key: string, value: number | string): void => {
    setFiltersList({ ...filtersList, [key]: value });
  };

  const submitValuesHandler = (): void => {
    if (updateFilters) {
      updateFilters(filtersList);
    }

    if (updateValues) {
      const { startMonthString, endMonthString } = getStatsMonth({ startMonth, endMonth });
      updateValues({
        companyId: activeCompany?.companyId,
        startMonth: startMonthString + `.${startYear}`,
        endMonth: endMonthString + `.${endYear}`,
      });
    }

    setIsOpenRange(false);
  };

  const resetFilters = (): void => {
    setFiltersList(filters);
  };

  return (
    <MonthPickerWrapper>
      <Box className='header' onClick={openDropDownToggler}>
        <Box className='header__title'>
          <Typography variant='body1'>{fullDate}</Typography>
          <InsertInvitationRoundedIcon htmlColor={theme.palette.primary.main} />
        </Box>
        <ArrowDropDownIcon
          className='header__arrow'
          htmlColor={theme.palette.neutralDark.main}
          sx={{
            transform: `rotate(${isOpen ? 180 : 0}deg)`,
          }}
        />
      </Box>
      {isOpen && (
        <ClickAwayListener onClickAway={outsideClickHandler}>
          <Box className='content'>
            <Box className='content__header'>
              <Box className='content__header_item'>
                {monthList[startMonth] || ''}, {startYear}
              </Box>
              <EastIcon />
              <Box className='content__header_item'>
                {monthList[endMonth] || ''}, {endYear}
              </Box>
            </Box>
            <Box className='content__wrapper'>
              <MonthRange filters={filtersList} changeFilters={changeFiltersHandler} />
              <Box className='content__wrapper_divider' />
              <MonthRange
                filters={filtersList}
                changeFilters={changeFiltersHandler}
                isEndMonthRange
              />
            </Box>
            <Box className='content__bottom'>
              <CCPRectButton variant='contained' color='secondary' onClick={openDropDownToggler}>
                {intl.formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
              </CCPRectButton>
              <CCPRectButton variant='contained' onClick={submitValuesHandler}>
                {intl.formatMessage({ id: 'table.toolbar.button.apply', defaultMessage: 'Apply' })}
              </CCPRectButton>
            </Box>
          </Box>
        </ClickAwayListener>
      )}
    </MonthPickerWrapper>
  );
};

export default MonthPicker;
