import { styled } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';

export const CustomToolbarStyled = styled(GridToolbarContainer)(({ theme }) => ({
  '.toolbar-row': {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    borderBottom: `2px solid ${theme.palette.primaryLight.main}`,
    padding: '12px 14px',
  },
  '.grid-button': {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    textTransform: 'none',
    color: theme.palette.neutralDark.main,
    minWidth: 'auto',
    '& .MuiButton-startIcon': {
      marginRight: 4,
      marginLeft: 0,
    },
    svg: {
      width: 24,
      height: 24,
    },
    '.column-badge': {
      position: 'relative',
      display: 'flex',
      '&:after': {
        content: `''`,
        width: '10px',
        height: '10px',
        display: 'block',
        position: 'absolute',
        background: 'blue',
        borderRadius: '50%',
        right: 0,
      },
    },
  },

  '.gridToolbarItems': {
    display: 'flex',
    alignItems: 'center',
    '.gridToolbarBtn': {
      fontSize: 16,
      fontFamily: 'Roboto-Medium',
      textTransform: 'none',
      color: theme.palette.neutralDark.main,
      minWidth: 'auto',
      padding: '7px 17px',
    },
    hr: {
      height: 24,
      width: 2,
      backgroundColor: theme.palette.neutralLight.dark,
    },
  },
}));
