import clsx from 'clsx';
import OtpInput from 'react18-input-otp';
import { useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useAppDispatch } from '@store/index';
import { ForgotPasswordSchema } from '@shared/schemas';
import { AuthenticationActions } from '@features/authentication/store';
import { CCPDefaultButton, CCPPasswordTextField, Spacer } from '@components/index';
import { useSelector } from 'react-redux';
import { AuthenticationSelectors } from '@features/authentication/store/authentication.selectors';

const ResetPasswordCode = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {
    forgotPassword: { email },
  } = useSelector(AuthenticationSelectors.getAuthentication);

  const [code, setCode] = useState('');

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const onSubmit = useCallback(
    (data: any) => {
      dispatch(
        AuthenticationActions.forgotPasswordSubmit({
          email,
          code,
          new_password: data.newPassword,
        }),
      );
    },
    [dispatch, email, code],
  );

  const changeValueHandler = (code: string): void => {
    setCode(code);
  };

  const resendLinkHandler = () => {
    dispatch(
      AuthenticationActions.forgotPassword({
        email,
        isShowToast: true,
      }),
    );
  };

  const changeStepHandler = () => {
    dispatch(AuthenticationActions.setActiveStep(1));
  };

  return (
    <Box className='wrapper'>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <Typography variant='h2'>
          {intl.formatMessage({
            id: 'reset_password_code.title.veryfy_email',
            defaultMessage: 'Verify email',
          })}
        </Typography>
        <Spacer size='lg' />
        <Typography variant='subtitle1'>
          {intl.formatMessage(
            {
              id: 'reset_password_code.subtitle.verification_code',
              defaultMessage: `We have sent you an email to {mail}. Please enter the verification code here to confirm your email address.`,
            },
            { mail: email },
          )}
        </Typography>
        <Spacer size='2xl' />
        <OtpInput
          isInputNum
          inputStyle={clsx('verify__code_item', {
            verify__code_item_active: code.length === 6,
          })}
          containerStyle='verify__code'
          value={code}
          onChange={changeValueHandler}
          numInputs={6}
        />
        <Typography className='verify__subtitle' variant='subtitle1'>
          {intl.formatMessage({
            id: 'reset_password_code.body.text',
            defaultMessage: 'Did not receive a verification link?',
          })}
        </Typography>
        <Box className='verify__link' onClick={resendLinkHandler}>
          <Typography variant='body1'>
            {intl.formatMessage({
              id: 'reset_password_code.body.action.resend',
              defaultMessage: 'Resend confirmation link',
            })}
          </Typography>
        </Box>
        <Divider className='verify__divider' />
        <Typography className='wrapper__title' variant='h2'>
          {intl.formatMessage({
            id: 'reset_password_code.body.action.set_new_password',
            defaultMessage: 'Set new password',
          })}
        </Typography>
        <Typography className='verify__subtitle' variant='subtitle1'>
          {intl.formatMessage({
            id: 'reset_password_code.body.hint_text',
            defaultMessage: `Passwords must contain: a minimum of 1 lower case letter, a minimum of 1 upper case letter
          [A-Z] and a minimum of 1 numeric character. Passwords must be at least 10 characters in
          length.`,
          })}
        </Typography>
        <Box className='verify__field'>
          <CCPPasswordTextField
            label={intl.formatMessage({ id: 'new_password', defaultMessage: 'New password' })}
            name='newPassword'
            control={control}
          />
          <CCPPasswordTextField
            label={intl.formatMessage({
              id: 'confirm_password',
              defaultMessage: 'Confirm password',
            })}
            name='confirmPassword'
            control={control}
          />
        </Box>
        <Box className='verify__footer'>
          <CCPDefaultButton onClick={changeStepHandler} variant='outlined'>
            {intl.formatMessage({
              id: 'reset_password_code.body.cta.cancel',
              defaultMessage: 'Cancel',
            })}
          </CCPDefaultButton>
          <CCPDefaultButton
            disabled={!isValid || code.length !== 6}
            color='primary'
            type='submit'
            variant='contained'>
            {intl.formatMessage({
              id: 'reset_password_code.body.cta.confirm',
              defaultMessage: 'Confirm new password',
            })}
          </CCPDefaultButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPasswordCode;
