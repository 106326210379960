import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { AddEmailSchema } from 'shared/schemas';
import { EmailDistributionModalProps, EmailDistributionModalValuesProps } from 'types';

import { useIntl } from 'react-intl';
import CCPDefaultButton from '@components/Button/Default';
import Spacer from '@components/Spacer';
import CCPTextField from '@components/Form/TextField/TextField';

const EmailDistributionModal: React.FC<EmailDistributionModalProps> = ({
  isOpen,
  closeModal,
  onSubmit,
  isEditPopup = false,
  email = '',
  description = '',
}) => {
  const { formatMessage } = useIntl();
  const methods = useForm<EmailDistributionModalValuesProps>({
    mode: 'all',
    resolver: yupResolver(AddEmailSchema),
    defaultValues: {
      email,
      description,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = methods;

  return (
    <FormProvider {...methods}>
      <Dialog open={isOpen} onClose={closeModal} component='form' onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {isEditPopup
            ? formatMessage({
                id: 'modal.edit_email.title',
                defaultMessage: 'Edit e-mail-address',
              })
            : formatMessage({
                id: 'modal.add_new_email.title',
                defaultMessage: 'Add new e-mail address',
              })}
        </DialogTitle>
        <DialogContent>
          <Typography variant='subtitle1'>
            {isEditPopup
              ? formatMessage({
                  id: 'modal.edit_email.subtitle',
                  defaultMessage:
                    'Fill in the following fields to create a new recipient for upcoming invoices.',
                })
              : formatMessage({
                  id: 'modal.add_new_email.subtitle',
                  defaultMessage:
                    'Fill in the following fields to create a new recipient for upcoming invoices.',
                })}
          </Typography>
          <Spacer size='xl' />
          <CCPTextField
            fullWidth
            required
            control={control}
            label={formatMessage({
              id: 'form.new_email.email.label',
              defaultMessage: 'Email',
            })}
            name='email'
            variant='outlined'
          />
          <Spacer size='xl' />
          <CCPTextField
            fullWidth
            rows={3}
            multiline
            control={control}
            name='description'
            label={formatMessage({
              id: 'form.new_email.description.label',
              defaultMessage: 'Description',
            })}
            variant='outlined'
          />
        </DialogContent>
        <DialogActions>
          <CCPDefaultButton onClick={closeModal} variant='outlined'>
            {isEditPopup
              ? formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })
              : formatMessage({
                  id: 'form.new_email.cta.back',
                  defaultMessage: 'Back to list',
                })}
          </CCPDefaultButton>
          <CCPDefaultButton type='submit' disabled={!isValid} variant='contained'>
            {isEditPopup
              ? formatMessage({
                  id: 'form.employee.cta.save_changes',
                  defaultMessage: 'Save changes',
                })
              : formatMessage({
                  id: 'form.new_email.cta.add_email',
                  defaultMessage: 'Add e-mail',
                })}
          </CCPDefaultButton>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default EmailDistributionModal;
