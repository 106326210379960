import { useCallback, useMemo, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import {
  TableInfo,
  TotalError,
  TotalEmployee,
  TotalErrorWrapper,
  ShowIssues,
  NoData,
  ErrorIconStyle,
} from './EmployeeTable.styles';
import moment from 'moment';
import { TableContainer } from './EmployeeBulkUpload.styles';
import EditEmployeeModal from 'features/employeeManagement/components/EmployeeBulkUpload/EditEmployeeModal';
import _ from 'lodash';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { useGendersList } from 'utils/genders';
import { FormatDate } from 'components/FormatDate';
import { EmployeeManagementActions } from 'features/employeeManagement/store';
import { manualFormatDateToString } from 'components/FormatDate';
import { EmployeeManagementSelectors } from 'features/employeeManagement/store/employeeManagement.selectors';
import { useLanguage } from 'hooks';
import { GridColDef } from '@mui/x-data-grid-pro';
import { CCPDefaultButton } from 'components';
import { isFuture } from 'date-fns';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import { isValidBirthday } from '@utils/getUpdatedDate';
import { useEmployeeTitles } from '@utils/employeeTitle';
import theme from '@theme/theme';
import { ImportDataValidation, ImportedEmployee } from '@localTypes/index';
import getCustomFieldEnumLabel from '@utils/getCustomFieldEnumLabel';

interface IEmployeeTableProps {
  data: ImportedEmployee[];
  dataValidation: ImportDataValidation;
}

const ERROR_HIGHLIGHT = theme.palette.error.main;
const WARNING_HIGHLIGHT = theme.palette.warning.main;

const EmployeeTable = ({ data, dataValidation }: IEmployeeTableProps) => {
  const { formatMessage } = useIntl();
  const genders = useGendersList();
  const employeeTitles = useEmployeeTitles();
  const dispatch = useAppDispatch();
  const currentLanguage = useLanguage();
  const [open, setOpen] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [employee, setEmployee] = useState<ImportedEmployee>({} as ImportedEmployee);
  const [showIssueOnly, setShowIssueOnly] = useState<boolean>();

  const company = useAppSelector(CompanySelectors.getApi).getCompany.data;
  const bulkUploadFiles = useAppSelector(EmployeeManagementSelectors.getBulkUploadFiles);
  const { activationLetterRecipientMode } = useAppSelector(SettingsSelectors.settingsSettings);

  const isEmailMandatory = activationLetterRecipientMode.toLowerCase() === 'eu';

  const validRows = useMemo(
    () => data.filter(row => row.invalid || row.isDatabaseDuplicate || row.isTemplateDuplicate),
    [data],
  );
  const tableRows = useMemo(
    () => (showIssueOnly ? validRows : data),
    [data, validRows, showIssueOnly],
  );

  const customerFieldColumn = useMemo(() => {
    if (!company?.customFields) {
      return [];
    }

    return (
      company.customFields.map(field => ({
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        headerName: (field.name?.[currentLanguage] || field.name?.de)?.toUpperCase(),
        field: field.key || '',
        renderCell: (params: GridRenderCellParams) =>
          params.row.customFields[field.key] ? (
            getCustomFieldEnumLabel(params.row.customFields[field.key], field, currentLanguage)
          ) : field.mandatory === 'yes' ? (
            <NoData color={ERROR_HIGHLIGHT}>
              {formatMessage({
                id: 'employee_management.bulk_upload.table.column.error.no_data',
                defaultMessage: 'No data',
              })}
            </NoData>
          ) : (
            ''
          ),
        isCustomFields: true,
      })) || []
    );
  }, [company, formatMessage, currentLanguage]);

  const globalCustomFieldColumns = useMemo(() => {
    if (!company?.globalCustomFields) {
      return [];
    }

    return (
      company.globalCustomFields.map(field => ({
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        headerName: (field.name?.[currentLanguage] || field.name?.de)?.toUpperCase(),
        field: field.key || '',
        renderCell: (params: GridRenderCellParams) =>
          params.row.globalCustomFields[field.key] ? (
            params.row.globalCustomFields[field.key]
          ) : field.mandatory === 'yes' ? (
            <NoData color={ERROR_HIGHLIGHT}>
              {formatMessage({
                id: 'employee_management.bulk_upload.table.column.error.no_data',
                defaultMessage: 'No data',
              })}
            </NoData>
          ) : (
            ''
          ),
        isGlobalCustomField: true,
      })) || []
    );
  }, [company, formatMessage, currentLanguage]);

  const getFormattedDate = useCallback(
    (
      val: string,
      dateType: string,
      isMultipleFieldErrors: boolean = false,
      isNotUniqueValue: boolean = false,
    ) => {
      if (!val || !moment(val).isValid()) {
        return (
          <NoData color={ERROR_HIGHLIGHT}>
            {formatMessage({
              id: 'employee_management.bulk_upload.table.column.error.no_data',
              defaultMessage: 'No data',
            })}
          </NoData>
        );
      }
      if (val && dateType === 'startDate' && !isFuture(new Date(val)))
        return <span style={{ color: ERROR_HIGHLIGHT }}>{FormatDate(val)}</span>;
      if (val && isNotUniqueValue && dateType === 'birthDate')
        return <span style={{ color: WARNING_HIGHLIGHT }}>{FormatDate(val)}</span>;
      if (val && dateType === 'birthDate' && !isValidBirthday(new Date(val)))
        return <span style={{ color: ERROR_HIGHLIGHT }}>{FormatDate(val)}</span>;
      else
        return (
          <span style={{ color: clsx(isMultipleFieldErrors && ERROR_HIGHLIGHT) }}>
            {FormatDate(val)}
          </span>
        );
    },
    [formatMessage],
  );

  const getFormattedRequiredString = useCallback(
    (val: String, isMandatory: boolean) =>
      val ? (
        val
      ) : (
        <NoData color={isMandatory ? ERROR_HIGHLIGHT : theme.palette.neutralDark.contrastText}>
          {formatMessage({
            id: 'employee_management.bulk_upload.table.column.error.no_data',
            defaultMessage: 'No data',
          })}
        </NoData>
      ),
    [formatMessage],
  );

  const notUniqueErrorTitle =
    'User with combination of firstname, lastname and birthday already exists in the company.';
  const isDuplicateErrorTitle =
    'is_not_unique_email, User with combination of firstname, lastname and birthday already exists in the company.';

  const isUniqueValue = (data: string) =>
    data === notUniqueErrorTitle || data === isDuplicateErrorTitle;

  const columns: GridColDef[] = useMemo(
    () => [
      {
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        headerName: '',
        field: 'invalid',
        renderCell: (params: GridRenderCellParams) => {
          if (params.row.isTemplateDuplicate) {
            return (
              <Tooltip
                title={formatMessage({
                  id: 'employee.bulk_import.duplicate_imports_email',
                  defaultMessage: 'This e-mail is not unique in the file',
                })}>
                <ErrorIcon className='data-error' htmlColor={theme.palette.error.main} />
              </Tooltip>
            );
          } else if (params.row.isDatabaseDuplicate) {
            return (
              <Tooltip
                title={formatMessage({
                  id: 'employee.bulk_import.duplicate_email',
                  defaultMessage: 'This e-mail is already known',
                })}>
                <ErrorIcon className='data-error' htmlColor={theme.palette.warning.main} />
              </Tooltip>
            );
          } else if (Object.keys(params.row?.multipleFieldErrors || {}).length > 0) {
            return (
              <Tooltip
                title={formatMessage({
                  id: 'employee.bulk_import.multi_error',
                  defaultMessage: 'Multiple fields are invalid',
                })}>
                <ErrorIcon className='data-error' htmlColor={theme.palette.error.main} />
              </Tooltip>
            );
          } else if (params.row.invalid) {
            return (
              <Tooltip
                title={formatMessage({
                  id: 'employee.bulk_import.unspecific_error',
                  defaultMessage: 'Some fields are invalid',
                })}>
                <ErrorIcon className='data-error' htmlColor={theme.palette.error.main} />
              </Tooltip>
            );
          }
          return <></>;
        },
      },
      {
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        headerName: formatMessage({
          id: 'form.employee.field.title.label',
          defaultMessage: 'Title',
        }),
        field: 'title',
        renderCell: (params: GridRenderCellParams) => {
          const titleValue = employeeTitles.find(
            title => title.value.toLowerCase() === params.row.title?.toLowerCase(),
          );
          return titleValue === undefined ? (
            <span css={{ color: ERROR_HIGHLIGHT }}>{params.row.title}</span>
          ) : (
            titleValue.title
          );
        },
      },
      {
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        headerName: formatMessage({
          id: 'employee_management.bulk_upload.table.header.first_name',
          defaultMessage: 'First name',
        }),
        field: 'firstName',
        renderCell: (params: GridRenderCellParams) => (
          <span
            className='notranslate'
            css={{
              color: clsx(
                isUniqueValue(params.row?.multipleFieldErrors?.firstName)
                  ? WARNING_HIGHLIGHT
                  : params.row?.multipleFieldErrors?.firstName && ERROR_HIGHLIGHT,
              ),
            }}>
            {getFormattedRequiredString(params.row.firstName, true)}
          </span>
        ),
        cellClassName: 'bold',
      },
      {
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        headerName: formatMessage({
          id: 'employee_management.bulk_upload.table.header.last_name',
          defaultMessage: 'Last name',
        }),
        field: 'lastName',
        renderCell: (params: GridRenderCellParams) => (
          <span
            className='notranslate'
            css={{
              color: clsx(
                isUniqueValue(params.row?.multipleFieldErrors?.lastName)
                  ? WARNING_HIGHLIGHT
                  : params.row?.multipleFieldErrors?.lastName && ERROR_HIGHLIGHT,
              ),
            }}>
            {getFormattedRequiredString(params.row.lastName, true)}
          </span>
        ),
        cellClassName: 'bold',
      },
      {
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        headerName: formatMessage({
          id: 'employee_management.bulk_upload.table.header.gender',
          defaultMessage: 'Gender',
        }),
        field: 'gender',
        renderCell: (params: GridRenderCellParams) => {
          const genderValue = genders.find(gender => gender.value === params.row.gender);
          return genderValue ? (
            <span
              className='notranslate'
              css={{
                color: clsx(params.row?.multipleFieldErrors?.gender && ERROR_HIGHLIGHT),
              }}>
              {genderValue.title}
            </span>
          ) : (
            <NoData color={ERROR_HIGHLIGHT}>
              {formatMessage({
                id: 'employee_management.bulk_upload.table.column.error.no_data',
                defaultMessage: 'No data',
              })}
            </NoData>
          );
        },
      },
      {
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        width: 120,
        headerName: formatMessage({
          id: 'employee_management.bulk_upload.table.header.birthday',
          defaultMessage: 'Birthday date',
        }),
        field: 'dateOfBirth',
        renderCell: (params: GridRenderCellParams) =>
          getFormattedDate(
            params.row.dateOfBirth,
            'birthDate',
            params.row?.multipleFieldErrors?.dateOfBirth,
            isUniqueValue(params.row?.multipleFieldErrors?.dateOfBirth),
          ),
      },
      {
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        width: 200,
        headerName: formatMessage({
          id: 'employee_management.bulk_upload.table.header.corporate_email',
          defaultMessage: 'Corporate email',
        }),
        field: 'businessEmail',
        renderCell: (params: GridRenderCellParams) => (
          <span
            className={`notranslate ${
              params.row.isDatabaseDuplicate
                ? 'duplicate-email'
                : params.row.isTemplateDuplicate
                ? 'duplicate-email-in-template'
                : ''
            }`}
            style={{
              whiteSpace: 'break-spaces',
              textAlign: 'left',
              color: params.row?.multipleFieldErrors?.businessEmail ? ERROR_HIGHLIGHT : '',
            }}>
            {getFormattedRequiredString(params.row.businessEmail, isEmailMandatory)}
          </span>
        ),
      },
      {
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        width: 120,
        headerName: formatMessage({
          id: 'employee_management.bulk_upload.table.header.phone',
          defaultMessage: 'Phone number',
        }),
        field: 'phoneNumber',
        renderCell: (params: GridRenderCellParams) =>
          params.row.phoneNumber ? (
            <span
              style={
                !params.row.phoneNumber.includes('+') ||
                params.row?.multipleFieldErrors?.phoneNumber
                  ? { color: ERROR_HIGHLIGHT }
                  : { color: theme.palette.neutralDark.contrastText }
              }>
              {params.row.phoneNumber}
            </span>
          ) : (
            ''
          ),
      },
      {
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        headerName: formatMessage({
          id: 'employee_management.bulk_upload.table.header.start',
          defaultMessage: 'Start date',
        }),
        field: 'startDate',
        renderCell: (params: GridRenderCellParams) =>
          getFormattedDate(
            params.row.startDate,
            'startDate',
            params.row?.multipleFieldErrors?.startDate,
          ),
      },
      ...customerFieldColumn,
      ...globalCustomFieldColumns,
      {
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        headerName: '',
        field: 'custom',
        width: currentLanguage === 'en' ? 300 : 350,
        renderCell: (params: GridRenderCellParams) => (
          <Box display='flex' alignItems='center'>
            <Box
              className='duplicate-button'
              style={params.row.isDatabaseDuplicate ? {} : { visibility: 'hidden' }}>
              {formatMessage({
                id: 'employee_management.bulk_upload.table.column.error.duplicate',
                defaultMessage: 'Duplicate',
              })}
            </Box>
            <CCPDefaultButton
              variant='text'
              className='edit-button'
              onClick={() => handleOpenModal(params.row, true)}
              startIcon={<BorderColorOutlinedIcon />}>
              {formatMessage({
                id: 'employee_management.bulk_upload.table.column.action.edit',
                defaultMessage: 'Edit',
              })}
            </CCPDefaultButton>
            <CCPDefaultButton
              variant='text'
              className='edit-button'
              color='inherit'
              onClick={() => handleOpenModal(params.row, true, true)}
              startIcon={<DeleteOutlinedIcon />}>
              {formatMessage({
                id: 'employee_management.bulk_upload.table.column.action.delete',
                defaultMessage: 'Delete',
              })}
            </CCPDefaultButton>
          </Box>
        ),
      },
    ],
    [
      currentLanguage,
      customerFieldColumn,
      employeeTitles,
      formatMessage,
      genders,
      getFormattedDate,
      getFormattedRequiredString,
      globalCustomFieldColumns,
      isEmailMandatory,
    ],
  );

  const handleClose = (open: boolean) => {
    setIsDelete(false);
    setOpen(open);
  };

  const handleOpenModal = (employee: any, open: boolean, isDeleteValue = false) => {
    if (isDeleteValue) {
      setIsDelete(true);
    }
    setEmployee(employee);

    setOpen(open);
  };

  const handleUpdateRows = (
    importedLineId: string,
    employee: ImportedEmployee,
    deleteRow = false,
  ) => {
    updateValidEmployees(importedLineId, employee, deleteRow);
  };

  const updateValidEmployees = async (
    lineId: string,
    employee: ImportedEmployee,
    isDelete = false,
  ) => {
    const newValidation = _.cloneDeep(dataValidation);
    if (isDelete) {
      delete newValidation.allData[lineId];
    } else {
      const {
        invalid,
        isDatabaseDuplicate,
        isTemplateDuplicate,
        multipleFieldErrors,
        ...newEmployee
      } = employee;
      newValidation.allData[lineId] = newEmployee;
    }
    try {
      var data = newValidation.allData;

      var csvFile = '';
      Object.values(data).forEach((row, i) => {
        let titlesRow = '';

        Object.entries(row).forEach(([field, value]) => {
          if ((field === 'customFields' || field === 'globalCustomFields') && company) {
            company[field].forEach(item => {
              titlesRow += item.key + ',';
              if (!row[field][item.key]) {
                csvFile += ',';
              } else {
                csvFile += row[field][item.key] + ',';
              }
            });
          } else if (field !== 'dialCode') {
            titlesRow += field + ',';
            if (field === 'dateOfBirth' || field === 'startDate') {
              const date =
                value && typeof value === 'string' ? new Date(value.replaceAll(' ', '')) : '';
              if (date instanceof Date && !isNaN(date.valueOf())) {
                csvFile += manualFormatDateToString(value as string, 'de') + ',';
              } else {
                csvFile += (!value ? '' : value) + ',';
              }
            } else {
              csvFile += (!value ? '' : value) + ',';
            }
          }
        });
        if (i === 0) {
          csvFile = `${titlesRow.substr(0, titlesRow.length - 1)}\r\n${csvFile.substr(
            0,
            csvFile.length - 1,
          )}\r\n`;
        } else {
          csvFile = csvFile.substr(0, csvFile.length - 1) + '\r\n';
        }
      });
      var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
      const formData = new FormData();
      formData.append('file', blob || '');

      await dispatch(
        EmployeeManagementActions.updateCsvFile({
          payload: formData,
          confirmationId: bulkUploadFiles.data?.companyBulkUpload?.confirmationId!,
        }),
      );
    } catch (ex) {}
  };

  const showIssues = () => {
    setShowIssueOnly(true);
  };

  const showAll = () => {
    setShowIssueOnly(false);
  };

  return (
    <>
      <TableContainer>
        <TableInfo>
          <TotalEmployee>
            {data.length}{' '}
            {formatMessage({
              id: 'employee_management.bulk_upload.review.profile_review.label',
              defaultMessage: 'employee profiles to review',
            })}
          </TotalEmployee>
          {dataValidation?.errorsArray &&
          Object.keys(dataValidation?.errorsArray).length > 0 &&
          !_.isEmpty(dataValidation?.errorsArray) ? (
            <TotalErrorWrapper>
              <TotalError>
                <ErrorIconStyle />
                {formatMessage(
                  {
                    id: 'employee_management.bulk_upload.review.mismatch.label',
                    defaultMessage: 'We found {amount} rows with mismatch data',
                  },
                  {
                    amount: data.filter(
                      employee => employee.invalid || employee.isDatabaseDuplicate,
                    ).length,
                  },
                )}
              </TotalError>
              {showIssueOnly ? (
                <ShowIssues onClick={showAll}>
                  {formatMessage({
                    id: 'employee_management.bulk_upload.review.show_all.label',
                    defaultMessage: 'Show all',
                  })}
                </ShowIssues>
              ) : (
                <ShowIssues onClick={showIssues}>
                  {formatMessage({
                    id: 'employee_management.bulk_upload.review.show.label',
                    defaultMessage: 'Show issues only',
                  })}
                </ShowIssues>
              )}
            </TotalErrorWrapper>
          ) : (
            ''
          )}
        </TableInfo>
        <Box>
          <DataGridPro
            initialState={{
              pinnedColumns: { right: ['custom'] },
            }}
            autoHeight
            getRowId={row => row.importedLineId}
            rowHeight={64}
            rows={tableRows}
            columns={columns}
            sx={{
              '.MuiDataGrid-columnHeaderTitle': { color: theme.palette.tableTemplate.fontColor },
              '.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus':
                {
                  outline: 'none',
                },
              '.MuiDataGrid-columnSeparator': {
                display: 'none',
              },

              '& .MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          />
        </Box>
      </TableContainer>
      {open && (
        <EditEmployeeModal
          open={open}
          setOpen={handleClose}
          employee={employee}
          handleEdit={handleUpdateRows}
          isDelete={isDelete}
        />
      )}
    </>
  );
};

export default EmployeeTable;
