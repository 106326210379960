import { styled, Box } from '@mui/material';

export const SettingsWrapper = styled(Box)(({ theme }) => ({
  '.title': {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 20,
    fontWeight: 900,
    lineHeight: 1.6,
    color: 'red',
    textTransform: 'uppercase',
    marginBottom: 24,
  },

  '.wrapper': {
    gap: 2,
    display: 'flex',

    '&__sidebar': {
      minWidth: 235,
      background: theme.palette.neutralLight.light,
      paddingTop: 24,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      '&_item': {
        position: 'relative',

        fontSize: 16,
        lineHeight: 1.38,
        color: theme.palette.neutralDark.contrastText,
        cursor: 'pointer',
        padding: '13px 24px',

        '&_active': {
          color: theme.palette.primary.main,
          cursor: 'default',
          fontWeight: '600',

          ':after': {
            content: "''",
            position: 'absolute',
            top: 0,
            right: 0,
            background: theme.palette.primary.main,
            width: 4,
            height: '100%',
          },
        },
      },
    },
  },

  '.content': {
    width: 'calc(100% - 235px)',
    minHeight: 766,
    background: theme.palette.neutralLight.light,
    padding: '48px 48px 36px',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',

    '&__title': {
      fontFamily: 'NutmegHeadline-UltraBlack',
      fontSize: 14,
      fontWeight: 900,
      lineHeight: 1.57,
      color: theme.palette.neutralDark.contrastText,
      textTransform: 'uppercase',
      marginBottom: 8,
    },

    '&__new': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 30,

      '&_text': {
        fontSize: 16,
        lineHeight: 1.38,
        color: theme.palette.neutralDark.contrastText,
      },
    },

    '&__fields_wrapper': {
      display: 'flex',
      gap: 24,
      '& .MuiInputBase-root.MuiInputBase-colorPrimary': {
        padding: 0,
        margin: 0,
        justifySelf: 'self-start',

        fontSize: 16,
      },
    },
  },
}));
