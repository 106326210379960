import { Typography, Box } from '@mui/material';
import { sanitize } from 'dompurify';

export interface CleanArticleProps {
  answer: string;
}

const CleanArticle: React.FC<CleanArticleProps> = ({ answer }) => {
  const santinizedText = sanitize(answer, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  });
  return (
    <Box>
      <Typography variant='subtitle1' dangerouslySetInnerHTML={{ __html: santinizedText }} />
      <br />
    </Box>
  );
};

export default CleanArticle;
