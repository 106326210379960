import moment from 'moment';

const formatMonth = (month: string) => {
  const numberMonth = Number(moment().month(month).format('M'));
  const numberFormatMonth = numberMonth > 9 ? String(numberMonth) : `0${numberMonth}`;

  return numberFormatMonth;
};

export default formatMonth;
