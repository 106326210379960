import { styled, Typography } from '@mui/material';

export const Header = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontFamily: 'NutmegHeadline-UltraBlack',
  fontSize: 25,
  fontWeight: 900,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.4,
  letterSpacing: 0.5,
  color: theme.palette.neutralLight.light,
  textTransform: 'uppercase',
}));
