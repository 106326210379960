import { styled, MenuItem, Accordion, Select, Theme, CSSObject } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

import { MenuDrawerProps, SelectWrapperProps } from 'types/index';

const openedMixin = (theme: Theme): CSSObject => ({
  width: 300,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  border: 'none',
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: 72,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  border: 'none',
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open' && prop !== 'isAuthorized',
})<MenuDrawerProps>(({ theme, open, isAuthorized }) => ({
  width: 300,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),

  '.MuiPaper-elevation': {
    background: isAuthorized ? theme.palette.primary.dark : theme.palette.primary.main,
    zIndex: 2,
  },

  '.header': {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.main,
    padding: '18px 18px 14px',
    minHeight: 72,
    justifyContent: 'flex-start',

    '&__logo': {
      width: open ? 'auto' : 0,
      opacity: open ? 1 : 0,
      cursor: 'pointer',

      '&_small': {
        width: open ? 0 : 'auto',
        cursor: 'pointer',
      },
      '&_text': {
        paddingLeft: '24px',
        color: theme.palette.neutralLight.main,
        display: open ? 'inherit' : 'none',
      },
    },
  },

  '.company__select': {
    marginTop: 24,
    padding: '0 16px',
  },

  '.wrapper': {
    height: 'auto',
    overflowX: 'hidden',
    overflowY: 'scroll',
    margin: '24px 0 46px',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },

    '&__item': {
      gap: 12,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: '12px 24px',
      opacity: 0.5,
      cursor: 'pointer',
      color: 'transparent',

      ' &_active': {
        opacity: 1,
        cursor: 'default',

        '&:after': {
          content: '""',
          width: 4,
          height: '-webkit-fill-available',
          background: theme.palette.neutralLight.light,
          position: 'absolute',
          top: 0,
          left: 0,
        },
      },

      '&_text': {
        fontFamily: 'Roboto-Medium',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.38,
        color: theme.palette.primaryLight.main,
        opacity: open ? 1 : 0,
      },
    },
  },

  '.back_wrapper': {
    gap: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: 0,
    left: 0,
    color: theme.palette.neutralLight.light,
    width: open ? 300 : 72,
    padding: open ? '0 24px 16px 21px' : '0 21px 16px',

    '.back_text': {
      fontFamily: 'Roboto-Medium',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.43,
      letterSpacing: 0.18,
      color: theme.palette.neutralLight.main,
      display: open ? 'block' : 'none',
    },

    '.back__icon': {
      width: 30,
      height: 30,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.transparent.light,
      borderRadius: 50,

      svg: {
        transition: 'transform 0.3s ease-out',
        transform: `rotate(${open ? 0 : 180}deg)`,
        fill: theme.palette.neutralLight.light,
      },
    },
  },

  '.unauthorized': {
    height: '100%',
    padding: '48px 0 46px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '&__header': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',

      ' &_text': {
        fontFamily: 'NutmegHeadline-UltraBlack',
        fontSize: 12,
        fontWeight: 900,
        lineHeight: 1.5,
        letterSpacing: 0.4,
        color: theme.palette.neutralLight.main,
        marginTop: 13,
        textTransform: 'uppercase',
      },
    },

    '&__content': {
      display: 'flex',
      flexDirection: 'column',

      '&_contact': {
        borderRadius: '0 9px 9px 0',
        background: theme.palette.primary.dark,
        width: 'calc(100% - 20px)',
        marginBottom: 68,
        padding: '24px 17px 24px 23px',
        display: 'flex',
        gap: 12,

        '&_info': {
          width: 'inherit',
        },

        '&_title': {
          fontFamily: 'NutmegHeadline-UltraBlack',
          fontSize: 12,
          fontWeight: 900,
          lineHeight: 1.5,
          letterSpacing: 0.4,
          color: theme.palette.neutralLight.main,
          marginBottom: 12,
          textTransform: 'uppercase',
        },

        '&_link': {
          fontSize: 13,
          fontWeight: 500,
          lineHeight: 1.38,
          letterSpacing: 0.12,
          color: theme.palette.neutralLight.main,

          '&:not(:last-child)': {
            marginBottom: 2,

            a: {
              textDecoration: 'none',
            },
          },

          a: {
            color: theme.palette.neutralLight.main,
            marginLeft: 3,
          },
        },

        svg: {
          minWidth: 13,
          marginTop: 2,
        },
      },

      '&_link': {
        marginBottom: 10,
        paddingLeft: 48,
        fontFamily: 'Roboto-Medium',
        fontSize: 14,
        lineHeight: 1,
        letterSpacing: 0.13,
        color: theme.palette.neutralLight.light,
        textDecoration: 'none',

        '&:last-child': {
          marginTop: 20,
          marginBottom: 0,
        },
      },
    },
  },
}));

export const MenuItemWrapper = styled(MenuItem)(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.38,
  color: theme.palette.neutralDark.contrastText,
  padding: '8px 16px',
}));

export const SelectWrapper = styled(Select, {
  shouldForwardProp: prop => prop !== 'isOpen',
})<SelectWrapperProps>(({ isOpen, theme }) => ({
  '&:hover': {
    outline: `${isOpen ? 2 : 1}px solid ${theme.palette.primaryLight.main}`,
  },

  '.MuiSelect-outlined': {
    outline: `${isOpen ? 2 : 1}px solid ${theme.palette.primaryLight.main}  !important`,
    padding: '8px 17px 8px 14px',
    background: isOpen ? 'transparent' : theme.palette.transparent.light,

    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.15,
    color: theme.palette.neutralLight.light,
  },

  fieldset: {
    display: 'none',
  },

  svg: {
    path: {
      fill: theme.palette.neutralLight.light,
    },
  },
}));

export const AccordionWrapper = styled(Accordion)(({ theme }) => ({
  '&:not(:last-child)': {
    margin: 0,
    boxShadow: 'none',
  },
  '&:before': {
    display: 'none',
  },

  '.summary': {
    maxHeight: 48,
    minHeight: '48px !important',
    padding: '0 24px',
    opacity: 0.5,

    '&_active': {
      opacity: 1,

      '&:after': {
        content: '""',
        width: 4,
        height: '-webkit-fill-available',
        background: theme.palette.neutralLight.light,
        position: 'absolute',
        top: 0,
        left: 0,
      },
    },

    '&_hide': {
      '.MuiAccordionSummary-expandIconWrapper': {
        display: 'none',
      },

      '.wrapper__item_text': {
        display: 'none',
      },
    },
  },

  '.MuiAccordionSummary-content': {
    gap: 12,
    margin: '12px 0 !important',
  },

  '.details': {
    padding: 0,

    '&__text': {
      padding: '13px 46px 13px 60px',
      fontFamily: 'Roboto-Medium',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.38,
      color: theme.palette.neutralLight.light,
      cursor: 'pointer',

      '&_active': {
        background: theme.palette.transparent.light,
        color: theme.palette.transparent.main,
      },
    },
  },
}));
