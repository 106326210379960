import clsx from 'clsx';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { Box, TableCell, TableRow, Typography } from '@mui/material';

import {
  EmailDistributionModalValuesProps,
  FetchingStatus,
  ListEntries,
  ListType,
  TabsType,
} from 'types';

import { InvoicesWrapper } from './Invoices.styles';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store';
import { SettingsActions } from 'features/settings/store';
import CustomTable from 'components/Table';
import ExternalUserRow from 'features/settings/components/InvoicesTable/ExternalUser/ExternalUserRow';
import { EmailDistributionModal, CCPDefaultButton, Spacer } from 'components';
import Loader from 'components/Loader';
import { FormatDate } from 'components/FormatDate';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { distributionTableColumns } from '..';

const Invoice: React.FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<TabsType>(TabsType.EXTERNAL);
  const [tableData, setTableData] = useState<ListEntries[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    data: listEntries,
    externalData: externalListEntries,
    fetchingStatus,
  } = useAppSelector(state => state.settings.distributionLists.INVOICES);
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);

  const updateActiveTab = (tab: TabsType): void => {
    setActiveTab(tab);
    setIsOpen(false);
  };

  const addRecipientModalToggler = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const addNewEmailHandler = async (data: EmailDistributionModalValuesProps) => {
    setIsLoading(true);
    await dispatch(
      SettingsActions.addRecordToDistributionList({
        companyId: activeCompany?.companyId,
        email: data.email,
        listType: ListType.INVOICES,
        description: data.description,
      }),
    );
    addRecipientModalToggler();
    setIsLoading(false);
  };

  const isExternalTab = useMemo(() => activeTab === TabsType.EXTERNAL, [activeTab]);

  useEffect(() => {
    setTableData(isExternalTab ? externalListEntries : listEntries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listEntries, externalListEntries, isExternalTab]);

  useEffect(() => {
    dispatch(SettingsActions.getDistributionList({ listType: ListType.INVOICES }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableColumns = useMemo(
    () => [
      ...distributionTableColumns,
      ...(isExternalTab
        ? [
            {
              columnName: 'description',
              columnTitle: 'Note',
              intlId: 'user_setting.self_registration.table.note',
            },
          ]
        : []),
    ],
    [isExternalTab],
  );

  const renderRow = (item: ListEntries) =>
    isExternalTab ? (
      <ExternalUserRow key={item.unsubscribeKey} item={item} />
    ) : (
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        key={item.unsubscribeKey}>
        <TableCell>{item.email}</TableCell>
        <TableCell className='right-align'>{FormatDate(item.creationDate)}</TableCell>
      </TableRow>
    );
  const addNewButton = useMemo(
    () =>
      isExternalTab ? (
        <CCPDefaultButton onClick={addRecipientModalToggler} variant='contained'>
          {intl.formatMessage({
            id: 'cta.add_new_recepient',
            defaultMessage: 'Add new recepient',
          })}
        </CCPDefaultButton>
      ) : null,
    [isExternalTab, addRecipientModalToggler, intl],
  );

  return (
    <InvoicesWrapper id='invoicesSettingsWrapper' position='relative'>
      <Loader
        show={fetchingStatus === FetchingStatus.PENDING || isLoading}
        inCard={isLoading ? false : true}
        portalId='invoicesSettingsWrapper'
      />
      <Typography variant='h6'>
        {intl.formatMessage({
          id: 'setting.invoice.title',
          defaultMessage: 'Invoices e-mail distribution',
        })}
      </Typography>
      <Spacer size='md' />
      <Box className='new'>
        <Typography variant='subtitle1'>
          {intl.formatMessage({
            id: 'setting.invoice.sub_title',
            defaultMessage: 'Maintain the list of e-mail recipients for monthly invoices.',
          })}
        </Typography>
      </Box>
      <Box className='tabs'>
        <Box
          onClick={() => updateActiveTab(TabsType.EXTERNAL)}
          className={clsx('tabs__item', {
            tabs__item_active: isExternalTab,
          })}>
          {intl.formatMessage({
            id: 'setting.invoice.tab.external',
            defaultMessage: 'External users',
          })}
        </Box>
        <Box
          onClick={() => updateActiveTab(TabsType.INTERNAL)}
          className={clsx('tabs__item', {
            tabs__item_active: activeTab === TabsType.INTERNAL,
          })}>
          {intl.formatMessage({
            id: 'setting.invoice.tab.system',
            defaultMessage: 'System users',
          })}
        </Box>
      </Box>
      <CustomTable
        columnsList={tableColumns}
        listData={tableData}
        renderRow={renderRow}
        additionalButton={addNewButton}
        noDataText={intl.formatMessage({
          id: 'setting.invoice.table.no_data',
          defaultMessage: 'No recepient has been added',
        })}
        noDataHelperText={intl.formatMessage({
          id: 'setting.invoice.table.no_data_helper',
          defaultMessage: 'Added users get informed about invoices via email.',
        })}
      />
      {isOpen && (
        <EmailDistributionModal
          isOpen={isOpen}
          onSubmit={addNewEmailHandler}
          closeModal={addRecipientModalToggler}
        />
      )}
    </InvoicesWrapper>
  );
};

export default Invoice;
