import { actions as SettingsSyncActions } from './settings.slice';
import { addRecordToDistributionList } from './actions/async/addRecordToDistributionList/addRecordToDistributionList';
import { deleteRecordFromDistributionList } from './actions/async/deleteRecordFromDistributionList/deleteRecordFromDistributionList';
import { editRecordInDistributionList } from './actions/async/editRecordInDistributionList/editRecordInDistributionList';
import { updateLanguage } from './actions/async/updateLanguage/updateLanguage';
import { getSettings } from './actions/async/getSettings/getSettings';
import { updateSettings } from './actions/async/updateSettings/updateSettings';
import { addNewEmployeeInvitation } from './actions/async/addNewEmployeeInvitation/addNewEmployeeInvitation';
import { removeEmployeeInvitation } from './actions/async/removeEmployeeInvitation/removeEmployeeInvitation';
import { getRegistrationUsers } from './actions/async/getRegistrationUsers/getRegistrationUsers';
import { getRegistrationSettings } from './actions/async/getRegistrationSettings/getRegistrationSettings';
import { setRegistrationSettings } from './actions/async/setRegistrationSettings/setRegistrationSettings';
import { getDistributionList } from './actions/async/getDistributionList/getDistributionList';
import { getOwnUserData } from './actions/async/getOwnUserData/getOwnUserData';
import { changeOwnUserData } from './actions/async/changeOwnUserData/changeOwnUserData';
import { getCompanyCustomField } from './actions/async/getCompanyCustomFIeld/getCompanyCustomField';
import { addCompanyCustomField } from './actions/async/addCompanyCustomField/addCompanyCustomField';
import { deleteCompanyCustomField } from './actions/async/deleteCompanyCustomField/deleteCompanyCustomField';
import { updateCompanyCustomField } from './actions/async/updateCompanyCustomField/updateCompanyCustomField';
import updateRegistrationForm from './actions/async/spUpdateRegistrationForm/updateRegistrationForm';
import updateLandingSettings from './actions/async/spUpdateLandingSettings/updateLandingSettings';
import updateGeneralSettings from './actions/async/spUpdateGeneralSettings/updateGeneralSettings';
import { setRegistrationIdentifier } from './actions/async/setRegistrationIdentifier/setRegistrationIdentifier';

export const SettingsActions = {
  ...SettingsSyncActions,
  addRecordToDistributionList,
  deleteRecordFromDistributionList,
  editRecordInDistributionList,
  updateLanguage,
  getSettings,
  updateSettings,
  getDistributionList,
  addNewEmployeeInvitation,
  removeEmployeeInvitation,
  getRegistrationSettings,
  setRegistrationSettings,
  setRegistrationIdentifier,
  getRegistrationUsers,
  getOwnUserData,
  changeOwnUserData,
  getCompanyCustomField,
  addCompanyCustomField,
  deleteCompanyCustomField,
  updateCompanyCustomField,
  updateRegistrationForm,
  updateLandingSettings,
  updateGeneralSettings,
};
