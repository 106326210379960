import { styled, Box } from '@mui/material';

export const StatsInfoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: 16,
  background: theme.palette.neutralLight.light,
  padding: '36px 36px 39px',
  width: 328,
  borderRadius: '4px 0 0 4px',

  '.top': {
    '&__title': {
      fontFamily: 'NutmegHeadline-UltraBlack',
      fontSize: 20,
      fontWeight: 900,
      lineHeight: 1.6,
      color: theme.palette.neutralDark.contrastText,
      textTransform: 'uppercase',
    },

    '&__text': {
      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
      margin: '16px 0 24px',
    },
  },

  '.bottom': {
    outline: `2px solid ${theme.palette.primaryLight.main}`,
    borderRadius: 8,
    padding: '48px 16px 21px',
    minHeight: 326,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '&__count': {
      textAlign: 'center',
      fontSize: 36,
      fontFamily: 'NutmegHeadline-UltraBlack',
      fontWeight: 900,
    },

    '&__title': {
      textAlign: 'center',

      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.main,
      margin: '11px 0 29px',
    },

    '&__subtitle': {
      textAlign: 'center',

      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
      marginBottom: 27,
    },
  },

  '.yellow': {
    color: theme.palette.warning.main,
  },

  '.green': {
    color: theme.palette.success.main,
  },

  '.blue': {
    color: theme.palette.primary.main,
  },
}));
