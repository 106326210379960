import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import CCPSelect from '@components/Form/Select';
import { useForm } from 'react-hook-form';
import { DefaultTextField } from '@components/Form/TextField/DefaultTextField.styles';
import { useIntl } from 'react-intl';
import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import postHubspotSupportForm from 'api/postHubspotSupportForm';
import {
  Field,
  HubspotSupportForm as HubspotSupportFormType,
  ObjectTypeId,
  FieldFormType,
  PropertyObjectType,
  FormFieldGroup,
} from '@localTypes/hubspotSupportForm';
import CondensedAccountInfo from '@components/CondensedAccountInfo';
import { useLanguage } from '@hooks/index';
import { default as theme } from '@theme/theme';
import HookFormSingleUpload from '@components/HookFormSingleUpload';
import UploadIcon from '@mui/icons-material/Upload';
import UploadedFileList from '@components/UploadedFileList';
import { validateFileExtension, validateFileSize } from '@utils/validationHelpers';
import { formatBytes } from '@utils/numberHelpers';
import { InfoRounded } from '@mui/icons-material';
import { CompanySelectors } from '@features/company/store/company.selectors';
import { useDialCodes } from '@utils/dialCodes';
import { AlertModal, CCPDefaultButton } from '..';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AuthenticationActions } from '@features/authentication/store';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useNavigate } from 'react-router';
import { defaultTextfieldError } from '@utils/defaultErrorMessage';
import { S3CredentialsActions } from 'features/shared/s3Credentials/store';
import { S3CredentialsSelectors } from '@features/shared/s3Credentials/store/s3Credentials.selector';
import { getS3Credential } from '@utils/getS3Credential';
import { S3 } from 'aws-sdk';
import binArrayToJson from '@utils/binArrayToJson';

// Proper type it with all possible fields instead of just string
type ExtractedFieldName = Field['name'];
type HubspotForm = Record<ExtractedFieldName, string | string[] | FileList>;
//types for dynamical depending fields
type CustomDependingField = {
  field: string;
  dependsOn: {
    parentField: string;
    value: boolean | number | number[] | string | string[];
  };
};
type ExtractedDependingValue = CustomDependingField['dependsOn']['value'];

//special fields that we don't need show, but need to send
const CUSTOM_READONLY_FIELDS = ['email', 'lastname', 'firstname', 'company', 'cid', 'phone'];

const MAX_FILE_SIZE = 4200000; // 4MB

const VALID_FILE_EXTENSIONS = [
  'doc',
  'docx',
  'xml',
  'png',
  'jpg',
  'jpeg',
  'pdf',
  'xls',
  'xlsx',
  'txt',
  'csv',
];

type HubspotSupportFormProps = { anchorId: string };

const HubspotSupportForm: React.FC<HubspotSupportFormProps> = ({ anchorId }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const language = useLanguage();
  const { userInfo } = useAppSelector(AuthenticationSelectors.getAuthentication);
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const { data: s3Data } = useAppSelector(S3CredentialsSelectors.getS3Credentials);
  const dialCodes = useDialCodes();

  const [submissionSuccessfull, setSubmissionSuccessfull] = useState<boolean | null>(null);
  const [supportFormStructure, setSupportFormStructure] = useState<HubspotSupportFormType>();
  const [hiddenValues, setHiddenValues] = useState<Record<string, string>>({});
  const [isShowLogoutAlert, setIsShowLogoutAlert] = useState<boolean>(false);

  const [customFieldDependsOn, setCustomFieldDependsOn] = useState<CustomDependingField[]>([]);

  const importFormStructure = useCallback((json: HubspotSupportFormType | undefined) => {
    if (!json) {
      return;
    }
    const hiddenFieldValues: Record<string, string> = {};
    const dependingFields: CustomDependingField[] = [];
    //remove hidden elements from response
    const formFieldGroups: FormFieldGroup[] = json.formFieldGroups.reduce(
      (acc: FormFieldGroup[], fieldGroup: FormFieldGroup) => {
        const mainFields: Field[] = [];
        const dependentFields: Field[] = [];
        fieldGroup.fields.forEach(item => {
          if (!item.hidden) {
            // Hidden fields are not displayed in the form and added later from the state to the form
            mainFields.push(item);
          }
          if (
            item.hidden &&
            (item.defaultValue || (item.type === 'enumeration' && item.selectedOptions.length > 0))
          ) {
            const { propertyObjectType, name } = item || {};
            const fieldName =
              propertyObjectType !== 'CONTACT' ? `${propertyObjectType}.${name}` : name;
            hiddenFieldValues[fieldName] =
              item.type === 'enumeration'
                ? item.selectedOptions.join(';')
                : item.defaultValue.toString();
          }

          if (item.dependentFieldFilters.length > 0) {
            item.dependentFieldFilters.forEach(dependentField => {
              dependentFields.push(dependentField.dependentFormField);
              let parentVal: ExtractedDependingValue = '';
              if (dependentField.filters[0].boolValue) {
                parentVal = dependentField.filters[0].boolValue;
              } else if (dependentField.filters[0].numberValue) {
                parentVal = dependentField.filters[0].numberValue;
              } else if (dependentField.filters[0].numberValues.length > 0) {
                parentVal = dependentField.filters[0].numberValues;
              } else if (dependentField.filters[0].strValue) {
                parentVal = dependentField.filters[0].strValue;
              } else if (dependentField.filters[0].strValues.length > 0) {
                parentVal = dependentField.filters[0].strValues;
              }
              dependingFields.push({
                field: dependentField.dependentFormField.name,
                dependsOn: { parentField: item.name, value: parentVal },
              });
            });
          }
        });
        if (mainFields.length > 0) acc.push({ ...fieldGroup, fields: mainFields });
        if (dependentFields.length > 0) acc.push({ ...fieldGroup, fields: dependentFields });
        return acc;
      },
      [] as FormFieldGroup[],
    );

    setCustomFieldDependsOn(dependingFields);
    setHiddenValues(hiddenFieldValues);
    setSupportFormStructure({ ...json, formFieldGroups });
  }, []);

  useEffect(() => {
    if (activeCompany) {
      dispatch(
        S3CredentialsActions.getS3CredentialsCompany({
          companyId: activeCompany.companyId,
        }),
      );
    }
  }, [activeCompany, dispatch]);

  useEffect(() => {
    if (s3Data) {
      const { AccessKeyId, SecretAccessKey, SessionToken, s3Url } =
        getS3Credential({
          data: s3Data,
          type: 'hubspot-forms',
        }) || {};

      if (!s3Url) {
        return;
      }

      const s3 = new S3({
        region: 'eu-central-1',
        accessKeyId: AccessKeyId,
        secretAccessKey: SecretAccessKey,
        sessionToken: SessionToken,
      });

      let formType = '';
      if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
        formType = `-${process.env.REACT_APP_ENVIRONMENT}`;
      }

      s3.getObject({
        Bucket: s3Url?.split('/')[2],
        Key: `hubspot-forms/hubspot-support-form${formType}.json`,
        ResponseContentType: 'application/json',
      })
        .promise()
        .then(file => {
          if (!file.Body) return;

          const fileInformation = binArrayToJson(file.Body as Uint8Array);
          importFormStructure(fileInformation);
          return fileInformation;
        });
    }
  }, [s3Data, importFormStructure]);

  // dynamically generate yup schema from hubspot form structure
  const generatedHubspotSchema = useMemo(() => {
    const requiredMessage = intl.formatMessage({
      id: 'form.generic.field_required',
      defaultMessage: 'Field is required',
    });
    const invalidFileSizeMessage = intl.formatMessage(
      {
        id: 'form.generic.invalid_file_size',
        defaultMessage: 'Max allowed size is {maxFileSize}',
      },
      { maxFileSize: formatBytes(MAX_FILE_SIZE) },
    );
    const invalidFileExtensionMessage = intl.formatMessage(
      {
        id: 'form.generic.invalid_file_extension',
        defaultMessage: 'Not a valid file extension (allowed: {validFileExtensions})',
      },
      { validFileExtensions: VALID_FILE_EXTENSIONS.join(', ') },
    );

    if (!supportFormStructure) {
      return yup.object().shape({});
    }

    const schema = supportFormStructure.formFieldGroups.reduce((acc, fieldGroup) => {
      fieldGroup.fields.forEach(field => {
        const { name, required, fieldType } = field;

        if (fieldType === 'checkbox') {
          acc[name] = required
            ? yup.array().of(yup.string()).required(requiredMessage)
            : yup.array().of(yup.string());
        } else if (fieldType === 'file') {
          acc[name] = required
            ? yup
                .mixed()
                .required(requiredMessage)
                .test('is-valid-type', invalidFileExtensionMessage, async files =>
                  validateFileExtension(files, VALID_FILE_EXTENSIONS),
                )
                .test('is-valid-size', invalidFileSizeMessage, async files =>
                  validateFileSize(files, MAX_FILE_SIZE),
                )
            : yup
                .mixed()
                .test('is-valid-type', invalidFileExtensionMessage, async files =>
                  validateFileExtension(files, VALID_FILE_EXTENSIONS),
                )
                .test('is-valid-size', invalidFileSizeMessage, async files =>
                  validateFileSize(files, MAX_FILE_SIZE),
                );
        } else if (fieldType === 'phonenumber') {
          acc[name] = required ? yup.mixed().required(requiredMessage) : yup.mixed().nullable();
        } else if (fieldType === 'booleancheckbox') {
          acc[name] = required ? yup.boolean().required(requiredMessage) : yup.boolean();
        } else {
          if (required) {
            const dependentField = customFieldDependsOn.find(item => item.field === name);
            if (dependentField) {
              acc[name] = yup
                .string()
                .when(dependentField.dependsOn.parentField, (parentVal, schema) => {
                  if (
                    (Array.isArray(dependentField.dependsOn.value) &&
                      dependentField.dependsOn.value.findIndex(val => val === parentVal) !== -1) ||
                    dependentField.dependsOn.value === parentVal
                  ) {
                    return yup.string().required(requiredMessage);
                  }
                  return schema;
                });
            } else {
              acc[name] = yup.string().required(requiredMessage);
            }
          } else {
            acc[name] = yup.string();
          }
        }
      });

      // custom fields
      acc['dialCode'] = yup.string().max(4);
      acc['phoneNumber'] = yup.mixed().test('is-valid-number', 'Must be a number', value => {
        if (!value) {
          return true;
        }

        return !isNaN(value);
      });

      return acc;
    }, {} as Record<ExtractedFieldName, yup.BaseSchema>);

    return yup.object().shape(schema);
  }, [intl, supportFormStructure, customFieldDependsOn]);

  // React object type ids from hubspot form structure
  const objectTypes = useMemo(() => {
    if (!supportFormStructure) {
      return {};
    }

    return supportFormStructure.formFieldGroups.reduce(
      (acc, fieldGroup) =>
        fieldGroup.fields.reduce((accField, field) => {
          accField[field.name] = {
            objectTypeId: field.objectTypeId,
            fieldType: field.fieldType,
            propertyObjectType: field.propertyObjectType,
          };

          return accField;
        }, acc),
      {} as Record<
        ExtractedFieldName,
        {
          objectTypeId: ObjectTypeId;
          fieldType: FieldFormType;
          propertyObjectType: PropertyObjectType;
        }
      >,
    );
  }, [supportFormStructure]);

  const toggleLogoutModal = useCallback(() => {
    setIsShowLogoutAlert(!isShowLogoutAlert);
  }, [isShowLogoutAlert, setIsShowLogoutAlert]);
  const handleLogout = useCallback(async () => {
    await dispatch(AuthenticationActions.signOut());
    navigate(`/${language}/login`);
  }, [dispatch, language, navigate]);

  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors, isSubmitSuccessful, isSubmitting, isValid },
    getValues,
    watch,
    setValue,
    trigger,
  } = useForm<HubspotForm>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      firstname: '',
      lastname: '',
      company: '',
      art_des_anliegens___2024_: '',
      cid: '',
      hf_communication_language: '',
      hf_type_of_request: '',
      hf_bereich_der_anfrage_trainierenden_firmen_verbundpartner: '',
      hf_quelle_der_anfrage: '',
      art_des_anliegens_category__2024_: '',
      hs_file_upload: [],
      phoneNumber: '',
      dialCode: '',
    },
    resolver: yupResolver(generatedHubspotSchema),
    // uncomment to debug validation schema
    // resolver: async (data, context, options) => {
    //   // you can debug your validation schema here
    //   console.log('formData', data);
    //   console.log(
    //     'validation result',
    //     await yupResolver(generatedHubspotSchema)(data, context, options),
    //   );
    //   return yupResolver(generatedHubspotSchema)(data, context, options);
    // },
  });

  useEffect(() => {
    if (userInfo?.email) {
      reset({
        ...getValues(),
        ...{
          email: userInfo.email,
          firstname: userInfo.name,
          lastname: userInfo.surname,
          company: userInfo.companyName,
          cid: activeCompany?.casCompanyId?.toString() || '',
          hf_quelle_der_anfrage: '',
          hf_communication_language: '',
          art_des_anliegens_category__2024_: '',
          hs_file_upload: [],
          phoneNumber: '',
          dialCode: '',
        },
      });
    }
  }, [userInfo, reset, activeCompany, getValues]);

  useEffect(() => {
    if (supportFormStructure) {
      supportFormStructure.formFieldGroups.forEach(formField => {
        formField.fields.forEach(field => {
          if (field.fieldType === 'select' && field.selectedOptions.length !== 0) {
            setValue(field.name, field.selectedOptions[0]);
          } else if (field.fieldType !== 'select' && field.defaultValue) {
            setValue(field.name, field.defaultValue);
          }
        });
      });
    }
  }, [supportFormStructure, setValue]);

  // watch to re-render list of form fields depending on value of art_des_anliegens
  const watchFieldsWithDependents = watch(
    customFieldDependsOn.map(field => field.dependsOn.parentField),
  );
  const watchFileUploads = watch('hs_file_upload') as FileList;

  const checkCorrectValueForDependenField = useCallback(
    (dependsOnIndex: number, dependsOnValue: ExtractedDependingValue) => {
      if (watchFieldsWithDependents[dependsOnIndex].toString().length === 0) {
        return false;
      }
      let correctValue = false;
      if (Array.isArray(dependsOnValue)) {
        for (let i = 0; i < dependsOnValue.length; i++) {
          if (dependsOnValue[i] === watchFieldsWithDependents[dependsOnIndex]) {
            correctValue = true;
            break;
          }
        }
      } else {
        correctValue = watchFieldsWithDependents[dependsOnIndex] === dependsOnValue.toString();
      }
      return correctValue;
    },
    [watchFieldsWithDependents],
  );

  useEffect(() => {
    // reset fields that depends on another field
    customFieldDependsOn.forEach(({ field, dependsOn }, index) => {
      if (watchFieldsWithDependents[index] !== undefined) {
        if (!checkCorrectValueForDependenField(index, dependsOn.value)) setValue(field, '');
      }
    });
  }, [
    customFieldDependsOn,
    setValue,
    watchFieldsWithDependents,
    checkCorrectValueForDependenField,
  ]);

  const onSubmit = async (formData: HubspotForm) => {
    if (!supportFormStructure) {
      return null;
    }

    const formsApiPayload = new FormData();

    formsApiPayload.append(
      'hs_context',
      JSON.stringify({
        pageUri: window.location.href,
        pageName: document.title,
      }),
    );

    Object.entries(formData).forEach(([name, value]) => {
      if (value) {
        const { propertyObjectType, fieldType } = objectTypes?.[name] || {};
        const fieldName =
          propertyObjectType !== 'CONTACT' && propertyObjectType
            ? `${propertyObjectType}.${name}`
            : name;

        if (name === 'phoneNumber' || name === 'dialCode') {
          return;
        }

        if (fieldType === 'file' && value.length > 0) {
          // Handle file values
          formsApiPayload.append(fieldName, value[0]);
        } else if (Array.isArray(value)) {
          // Handle array values
          formsApiPayload.append(fieldName, value.join(';'));
        } else {
          // Handle other values
          formsApiPayload.append(fieldName, value.toString());
        }
      }
    });

    if (formData.dialCode && formData.phoneNumber) {
      // Handle phone number field
      formsApiPayload.append('phone', `${formData.dialCode} ${formData.phoneNumber}`);
    }

    Object.keys(hiddenValues).forEach(key => formsApiPayload.append(key, hiddenValues[key]));

    try {
      const response = await postHubspotSupportForm(
        formsApiPayload,
        supportFormStructure.guid,
        supportFormStructure.portalId,
      );
      if (response.status === 200 || response.status === 204) {
        setSubmissionSuccessfull(true);
      } else {
        setSubmissionSuccessfull(false);
      }
    } catch (error) {
      setSubmissionSuccessfull(false);
    }
  };

  const renderFieldGroups = useCallback(() => {
    if (!supportFormStructure) {
      return null;
    }

    return supportFormStructure.formFieldGroups.map((fieldGroup, index) =>
      fieldGroup.fields.filter(item => !item.hidden).length > 0 ? (
        <FormGroup
          className='form__row'
          key={index}
          css={{
            alignItems: 'flex-start',
          }}>
          {fieldGroup.fields.map(field => {
            const { label, name, fieldType, enabled } = field;

            const customDependingFieldIndex = customFieldDependsOn.findIndex(
              ({ field: dependingFieldName }) => dependingFieldName === name,
            );

            //if field depends on another field, check if that field has correct value
            if (customDependingFieldIndex !== -1) {
              const customDependingField = customFieldDependsOn[customDependingFieldIndex];

              if (
                watchFieldsWithDependents[customDependingFieldIndex] === undefined ||
                !checkCorrectValueForDependenField(
                  customDependingFieldIndex,
                  customDependingField.dependsOn.value,
                )
              ) {
                return null;
              }
            }

            if (!enabled) {
              return null;
            }

            if (CUSTOM_READONLY_FIELDS.includes(name)) {
              return <input type='hidden' key={name} {...register(name)} />;
            }

            if (fieldType === 'text' || fieldType === 'phonenumber' || fieldType === 'textarea') {
              const errorMessage = errors[name]?.message;
              return (
                <DefaultTextField
                  label={label.trim()}
                  key={name}
                  error={!!errors[name]}
                  required={field.required}
                  {...register(name)}
                  type='text'
                  helperText={
                    errorMessage
                      ? intl.formatMessage({
                          id: errors[name]?.message,
                          defaultMessage: defaultTextfieldError[errorMessage] || errorMessage,
                        })
                      : undefined
                  }
                  className='form__wrapper_field'
                  disabled={CUSTOM_READONLY_FIELDS.includes(name)}
                  multiline={fieldType === 'textarea'}
                  height={fieldType === 'textarea' ? 'auto' : undefined}
                  rows={fieldType === 'textarea' ? 5 : undefined}
                />
              );
            }

            if (fieldType === 'select') {
              return (
                <CCPSelect
                  label={label.trim()}
                  key={name}
                  name={name}
                  control={control}
                  required={field.required}
                  className='form__wrapper_field'>
                  {field.options.map(option => (
                    <MenuItem
                      value={option.value}
                      key={option.label.trim()}
                      selected={option.value === getValues(name)}>
                      {option.label.trim()}
                    </MenuItem>
                  ))}
                </CCPSelect>
              );
            }

            if (fieldType === 'checkbox') {
              return (
                <FormControl
                  className='form__wrapper_field'
                  error={!!errors[name]}
                  required={field.required}
                  key={label.trim()}>
                  <FormLabel id={name}>{label.trim()}</FormLabel>

                  <FormGroup>
                    {field.options.map(item => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={(getValues(name) as string[]).includes(item.value)}
                          />
                        }
                        key={item.label.trim()}
                        value={item.value}
                        label={item.label.trim()}
                        {...register(name, { required: field.required })}
                      />
                    ))}
                  </FormGroup>

                  <FormHelperText>{errors[name]?.message}</FormHelperText>
                </FormControl>
              );
            }
            if (fieldType === 'booleancheckbox') {
              const checked = getValues(name) === 'true';

              return (
                <FormControl
                  className='form__wrapper_field'
                  error={!!errors[name]}
                  key={label.trim()}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked={checked} />}
                    key={label.trim()}
                    required={field.required}
                    label={label.trim()}
                    {...register(name)}
                  />

                  <FormHelperText>{errors[name]?.message}</FormHelperText>
                  {field.unselectedLabel && (
                    <Typography className='form__wrapper_subtitle' variant='body1' marginTop='32px'>
                      {field.unselectedLabel}
                    </Typography>
                  )}
                </FormControl>
              );
            }

            if (fieldType === 'file') {
              return (
                <Box
                  key={label.trim()}
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}>
                  {watchFileUploads.length > 0 && (
                    <UploadedFileList
                      data={watchFileUploads}
                      onRemove={() => {
                        setValue(name, []);
                        trigger(name);
                      }}
                    />
                  )}
                  <HookFormSingleUpload
                    register={register}
                    control={control}
                    name={name}
                    error={errors[name]}
                    required={field.required}
                    css={{
                      borderColor: theme.palette.primary.main,
                      color: theme.palette.primary.main,
                      gap: 4,
                    }}
                    variant='outlined'
                    validFileExtensions={VALID_FILE_EXTENSIONS}
                    disabled={watchFileUploads.length > 0}
                    formHelperText={
                      <>
                        <div
                          css={{
                            marginTop: 10,
                            marginBottom: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}>
                          <div
                            css={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 4,
                            }}>
                            <InfoRounded
                              fontSize='inherit'
                              css={{
                                color: theme.palette.neutralDark.main,
                              }}
                            />

                            <span css={{ fontWeight: 600 }}>
                              {intl.formatMessage({
                                id: 'support.contact.form.allowed_formats_label',
                                defaultMessage: 'Allowed formats and sizes:',
                              })}
                            </span>
                          </div>

                          <div>
                            {intl.formatMessage(
                              {
                                id: 'support.contact.form.allowed_formats_description',
                                defaultMessage:
                                  '[Example: {validFileExtensions} up to {maxFileSize}]',
                              },
                              {
                                validFileExtensions: VALID_FILE_EXTENSIONS.join(', '),
                                maxFileSize: formatBytes(MAX_FILE_SIZE),
                              },
                            )}
                          </div>
                        </div>
                      </>
                    }>
                    <UploadIcon />
                    {`${intl.formatMessage({
                      id: 'form.generic.add_attachment',
                      defaultMessage: 'Add Attachment',
                    })} ${field.required ? '*' : ''}`}
                  </HookFormSingleUpload>
                </Box>
              );
            }

            return null;
          })}
        </FormGroup>
      ) : null,
    );
  }, [
    control,
    errors,
    getValues,
    intl,
    register,
    setValue,
    supportFormStructure,
    trigger,
    watchFieldsWithDependents,
    watchFileUploads,
    checkCorrectValueForDependenField,
    customFieldDependsOn,
  ]);

  if (isSubmitSuccessful && submissionSuccessfull) {
    return (
      <Box className='questions__card' style={{ alignItems: 'center' }}>
        <Typography variant='h6'>
          {intl.formatMessage({ id: 'support.contact.form.title', defaultMessage: 'Contact us' })}
        </Typography>
        <Typography className='new__list_text form__wrapper_text' variant='body1'>
          {intl.formatMessage({
            id: 'support.contact.form.subtitle',
            defaultMessage: 'We are here to help you',
          })}
        </Typography>
        <Typography className='new__list_text form__wrapper_text' variant='body1'>
          {intl.formatMessage({
            id: 'support.contact.form.submit_success',
            defaultMessage: 'Your request has been submitted successfully',
          })}
        </Typography>
      </Box>
    );
  }

  if (!supportFormStructure) {
    return null;
  }

  return (
    <Box
      css={{
        justifyContent: 'start',
        display: 'flex',
        gap: 16,
        maxWidth: 466,
      }}>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        {isSubmitting && (
          <Backdrop open>
            <CircularProgress color='inherit' />
          </Backdrop>
        )}
        <Typography variant='h6' id={anchorId} marginBottom='16px'>
          {intl.formatMessage({
            id: 'support.contact.form.title',
            defaultMessage: 'Question Form',
          })}
        </Typography>
        <Typography variant='subtitle1' color={theme.palette.neutralDark.main}>
          {intl.formatMessage({
            id: 'support.contact.form.subtitle',
            defaultMessage: 'Ask you question.',
          })}
        </Typography>

        {userInfo && activeCompany?.casCompanyId && (
          <CondensedAccountInfo
            firstname={userInfo?.name}
            lastname={userInfo?.surname}
            companyname={userInfo?.companyName}
            email={userInfo.email}
            companyId={activeCompany?.casCompanyId}
            actionButton={
              <CCPDefaultButton
                variant='text'
                onClick={toggleLogoutModal}
                sx={{ marginLeft: '-18px' }}>
                {intl.formatMessage({
                  id: 'support.contact.form.logout',
                  defaultMessage: 'Log into another account',
                })}
              </CCPDefaultButton>
            }
            containerCss={{ marginTop: 30 }}
          />
        )}
        <Stack
          direction='row'
          spacing={2}
          style={{
            marginBottom: 60,
            marginTop: 30,
          }}>
          <CCPSelect
            control={control}
            name='dialCode'
            label={`${intl.formatMessage({
              id: 'form.employee.field.dialCode.label',
              defaultMessage: 'Prefix',
            })}`}
            width={104}>
            {dialCodes.map(({ dialCode }) => (
              <MenuItem
                key={dialCode}
                value={dialCode}
                selected={dialCode === getValues('dialCode')}>
                {dialCode}
              </MenuItem>
            ))}
          </CCPSelect>

          <DefaultTextField
            {...register('phoneNumber')}
            label={intl.formatMessage({
              id: 'form.employee.field.phone.label',
              defaultMessage: 'Phone number (optional)',
            })}
            width={404}
            type='integer'
          />
        </Stack>

        <Typography
          variant='h3'
          css={{
            marginBottom: 30,
          }}>
          {intl.formatMessage({
            id: 'support.contact.form.form_title',
            defaultMessage: 'Your request',
          })}
        </Typography>

        {renderFieldGroups()}

        <CCPDefaultButton type='submit' disabled={!isValid} variant='contained'>
          {intl.formatMessage({
            id: 'form.generic.submit',
            defaultMessage: 'Submit',
          })}
        </CCPDefaultButton>

        {isSubmitSuccessful && !submissionSuccessfull && (
          <Typography className='new__list_text form__wrapper_text' variant='body1'>
            {intl.formatMessage({
              id: 'support.contact.form.submit_error',
              defaultMessage: 'Error submitting form, please try again.',
            })}
          </Typography>
        )}
      </Box>
      {isShowLogoutAlert && (
        <AlertModal
          iconComponent={<ErrorOutlineIcon htmlColor={theme.palette.warning.main} />}
          isOpen={isShowLogoutAlert}
          handleClose={toggleLogoutModal}
          handleConfirm={handleLogout}
          confirmButtonText={intl.formatMessage({
            id: 'alert.modal.button.confirm',
            defaultMessage: 'Confirm',
          })}
          title={intl.formatMessage({
            id: 'alert.modal.hubspot_logout.title',
            defaultMessage: 'Your current session will be ended',
          })}
          showConfirmButton
          description={intl.formatMessage({
            id: 'alert.modal.hubspot_logout.description',
            defaultMessage: 'Are you sure you want to log out?',
          })}
        />
      )}
    </Box>
  );
};

export default HubspotSupportForm;
