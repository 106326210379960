import {
  CompanyCustomFieldType,
  Employee,
  EmployeeStatus,
  EmployeeTableOverwiewData,
} from '@localTypes/index';
import { ChangeEvent } from 'react';

export const getCustomItems = (
  e: ChangeEvent<HTMLInputElement>,
  customFields: CompanyCustomFieldType[],
  defaultLength: number,
) =>
  customFields.map((customField, idx) => {
    const id = idx + defaultLength;

    return {
      id,
      field: customField.key,
      operator: 'contains',
      value: e.target.value,
    };
  });

export const getStatusInfo = (employeeData: EmployeeTableOverwiewData | Employee) => {
  if (employeeData.status === EmployeeStatus.LOCKED) {
    return { status: EmployeeStatus.BLOCKED, date: undefined };
  }
  if (employeeData.plannedTransition) {
    return {
      status: employeeData.plannedTransition.status,
      date: employeeData.plannedTransition.startDate,
      plannedStatus: true,
    };
  }
  return { status: employeeData.status, date: employeeData.entryDate };
};

//this function need to the one sprint while backed team will do renaming from plaNedTransition to plaNNedTransition in the API response
export const getCrutchStatusInfo = (employeeData: Employee) => {
  if (employeeData.status === EmployeeStatus.LOCKED) {
    return { status: EmployeeStatus.BLOCKED, date: undefined };
  }
  if (employeeData.planedTransition) {
    return {
      status: employeeData.planedTransition.status,
      date: employeeData.planedTransition.startDate,
      plannedStatus: true,
    };
  }
  return { status: employeeData.status, date: employeeData.entryDate };
};
