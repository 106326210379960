import { styled, TableHead, TableBody } from '@mui/material';

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  borderRadius: 4,
  background: theme.palette.transparent.blue05,

  '.table_cell': {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    color: theme.palette.neutralDark.main,
    padding: '14px 16px 12px',
    borderBottom: `2px solid ${theme.palette.neutralLight.light}`,
    textTransform: 'uppercase',
  },
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  td: {
    background: theme.palette.transparent.blue05,
    padding: '21px 31px 21px 16px',
    borderBottom: `2px solid ${theme.palette.neutralLight.light}`,

    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
  },

  '.language': {
    textTransform: 'uppercase',
  },
  '.edit': {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      color: theme.palette.neutralDark.main,
      fontFamily: 'Roboto-Medium',
      fontSize: 16,
      marginLeft: 8.5,
    },
  },
}));
