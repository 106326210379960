// A custom theme for this app
import { createTheme, Theme } from '@mui/material';
import { deDE } from '@mui/material/locale';

import type {} from '@mui/x-date-pickers/themeAugmentation';
declare module '@mui/material/styles' {
  interface PaletteColor {
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }
  interface Palette {
    neutralLight: SimplePaletteColorOptions;
    neutralDark: SimplePaletteColorOptions;
    primaryLight: SimplePaletteColorOptions;
    transparent: SimplePaletteColorOptions & {
      dropdownSelected: string;
      boxShadowDark01: string;
      boxShadowDark02: string;
      dark05: string;
      blue05: string;
      blue07: string;
      disabledInput: string;
      darkYellow: string;
    };
    gradient: SimplePaletteColorOptions & { pinnedColumnsRight: string };
    tableTemplate: { fontColor: string; backgroundColor: string };
  }
  interface PaletteOptions {
    neutralLight: SimplePaletteColorOptions;
    neutralDark: SimplePaletteColorOptions;
    primaryLight: SimplePaletteColorOptions;
    transparent: SimplePaletteColorOptions & {
      dropdownSelected: string;
      boxShadowDark01: string;
      boxShadowDark02: string;
      dark05: string;
      blue05: string;
      blue07: string;
      disabledInput: string;
      darkYellow: string;
    };
    gradient: SimplePaletteColorOptions;
    tableTemplate: { fontColor: string; backgroundColor: string };
  }
}

const CCPTheme: Theme = createTheme(
  {
    typography: {
      fontFamily: [
        'Roboto-Regular',
        'Helvetica',
        'Arial',
        'sans-serif',
        'NutmegHeadline-UltraBlack',
      ].join(','),
      allVariants: {
        fontFamily: "'Roboto-Regular', 'Helvetica', 'Arial', sans-serif",
        textTransform: 'none',
      },
      h1: {
        fontSize: '18px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#1F2126',
        lineHeight: '28px',
      },
      h2: {
        fontFamily: 'NutmegHeadline-UltraBlack',
        fontSize: 20,
        fontWeight: 900,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.6',
        letterSpacing: 'normal',
        color: '#1F2126',
        textTransform: 'uppercase',
      },
      h3: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#1F2126',
        lineHeight: '24px',
      },
      h4: {
        fontFamily: 'NutmegHeadline-UltraBlack',
        fontSize: '12px',
        fontWeight: 900,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.57',
        letterSpacing: 'normal',
        color: '#1F2126',
        textTransform: 'uppercase',
        marginBottom: 16,
      },
      h5: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#1F2126',
        lineHeight: '24px',
      },
      h6: {
        fontFamily: 'NutmegHeadline-UltraBlack',
        fontSize: '14px',
        fontWeight: 900,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '22px',
        letterSpacing: 'normal',
        color: '#1F2126',
        textTransform: 'uppercase',
      },
      subtitle1: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#1F2126',
        lineHeight: '22px',
      },
      subtitle2: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: '0.13px',
        color: '#64748B',
      },
      caption: {
        color: '#6B7986',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px' /* 150% */,
      },
    },
    palette: {
      background: {
        default: '#eff2f6',
      },
      primary: {
        main: '#003bb3', //blue-main-000
        dark: '#002a80', //blue-main-001
        darker: '#001f5c', //blue-main-002
        contrastText: '#fff',
      },
      primaryLight: {
        light: '#f8f9fb', //blue-light-000
        main: '#eff2f6', //blue-light-001
        dark: '#e5f1ff', //blue-light-002
        darker: '#97b7e1', //blue-light-003
      },
      neutralLight: {
        light: '#fff', //white-000
        main: '#ebebeb', //light-grey-000
        dark: '#d8d8d8', //light-grey-002
      },
      neutralDark: {
        light: '#a3acb5', //light-grey-003
        main: '#6b7986', //light-grey-004
        dark: '#1f2126', // black-000
        contrastText: '#000', // black for a lot of titles(I don't know why we have no this color in the designs)
      },
      error: {
        main: '#e50051', //red-accent
      },
      warning: {
        main: '#f9a500', //orange-accent
      },
      success: {
        main: '#2bbc9b', //green-accent
      },
      transparent: {
        light: '#ffffff33', // white 20%
        main: '#ffffff80', // white 50%
        dark: '#ffffffcc', // white 80%
        dropdownSelected: '#003bb34d', //rgba(0, 59, 179, 0.3)
        boxShadowDark01: '#0000001a', //rgba(0, 0, 0, 0.1)
        boxShadowDark02: '#00000033', //rgba(0, 0, 0, 0.2)
        dark05: '#00000080', //rgba(0, 0, 0, 0.5)
        blue05: '#eff2f680', //rgba(239, 242, 246, 0.5)
        blue07: '#eff2f6b3', //rgba(239, 242, 246, 0.7)
        disabledInput: '#d8d8d880', //rgba(216, 216, 216, 0.5)
        darkYellow: '#f9a50033', //rgba(249, 165, 0, 0.2)
      },

      gradient: {
        main: '', //MuiColorInput picks this color by default, results in crushing page
        light: 'linear-gradient(124deg, #002A80 14.57%, #D3E6F5 87.33%)', //gradient blue-01
      },

      tableTemplate: {
        fontColor: '#a9bbcd',
        backgroundColor: '#a9bbcd33',
      },
    },

    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            '.MuiBackdrop-root': {
              backgroundColor: '#eff2f6b3',
            },
          },
          paper: {
            maxWidth: 640,
            minWidth: 620,
            boxShadow: `0 10px 10px 0 #0000001a`,
            position: 'relative',
            '& .dialog-close': {
              position: 'absolute',
              top: '24px',
              right: '24px',
              cursor: 'pointer',
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: '20px',
            padding: '32px 48px 24px',
            '& .dialog-icon': {
              paddingBottom: '24px',
              svg: {
                width: 36,
                height: 36,
              },
            },
          },
        },
      },
      MuiDialogContent: { styleOverrides: { root: { padding: '24px 48px' } } },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '24px 48px 32px',
            '&>:not(:first-of-type)': {
              marginLeft: '16px',
            },
          },
        },
      },
      MuiMenuList: {
        styleOverrides: {
          root: {
            padding: 0,
            boxShadow: '0 5px 6px -3px #0000001a, 0 3px 8px 2px #00000033',
            background: '#fff',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            minHeight: '36px !important',
            fontSize: 16,
            lineHeight: 1.38,
            color: '#1f2126',
            padding: '8px 16px',
            '&:hover': { background: '#eff2f6' },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 18,
            width: 'fit-content',
            height: 'fit-content',
            minWidth: 142,
            padding: '8px 18px',
            border: 'none',
            fontFamily: 'Roboto-Medium',
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '20px',
            textTransform: 'unset',
            '.MuiTouchRipple-root': {
              display: 'none',
            },
            '&.MuiButton-outlined': {
              padding: '6px 16px',
              border: '2px solid currentColor',
            },
            '&.MuiButton-outlinedPrimary:hover': {
              color: '#97B7E1',
              borderColor: '#97B7E1',
              backgroundColor: '#EFF2F6',
            },
            '&.MuiButton-outlinedPrimary:active': {
              backgroundColor: '#E5F1FF',
            },
            '&.MuiButton-outlinedError:hover': {
              color: '#EC7FA6',
            },
            '&.MuiButton-outlined:disabled': {
              color: '#6B7986',
              borderColor: '#6B7986',
              backgroundColor: 'transparent',
            },

            '&.MuiButton-containedPrimary:hover': {
              backgroundColor: '#002A80',
              color: '#97B7E1',
            },
            '&.MuiButton-containedPrimary:active': {
              backgroundColor: '#001F5C',
            },
            '&.MuiButton-containedError:hover': {
              backgroundColor: '#B20F49',
              color: '#EC7FA6',
            },
            '&.MuiButton-containedError:active': {
              backgroundColor: '#800E37',
            },
            '&.MuiButton-contained:disabled': {
              backgroundColor: '#A3ACB5',
              color: '#EBEBEB',
            },

            '&.MuiButton-textPrimary': {
              color: '#6B7986',
              backgroundColor: 'transparent',
            },
            '&.MuiButton-textSecondary': {
              color: '#003BB3',
              backgroundColor: 'transparent',
            },
            '&.MuiButton-textPrimary:hover, &.MuiButton-textSecondary:hover': {
              color: '#003BB3',
            },
            '&.MuiButton-textPrimary:active': {
              color: '#97B7E1',
            },
            '&.MuiButton-textPrimary:disabled': {
              color: '#A3ACB5',
            },
          },
        },
      },
      MuiStack: {
        defaultProps: {
          position: 'relative',
        },
      },
    },
  },
  deDE,
);

export default CCPTheme;
