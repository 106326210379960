import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { SettingsActions } from 'features/settings/store';
import { UserManagementBaseResponse, DeleteDistributionListRequestParams, RootState } from 'types';
import { AuthenticationActions } from 'features/authentication/store';

export const deleteRecordFromDistributionList = createAsyncThunk<
  {},
  DeleteDistributionListRequestParams
>(
  'settings/deleteRecordFromDistributionList',
  async (
    { companyId, email, listType, hideSuccessToast = false },
    { rejectWithValue, dispatch, getState },
  ) => {
    const endpoint =
      process.env.REACT_APP_SETTINGS_ENDPOINT + `company/${companyId}/list/${listType}`;
    const axios = await axiosWithConfig();

    try {
      const config: any = {
        data: {
          email_list: [email],
        },
      };

      await axios.delete(endpoint, config);
      if (!hideSuccessToast)
        showToast('success', {
          id: 'modal.success.delete_user_from_distribution_list',
          defaultMessage: 'Success',
        });

      await dispatch(
        SettingsActions.getDistributionList({
          companyId,
          listType,
        }),
      );
      const currentState = getState() as RootState;
      if (companyId && currentState.authentication.userInfo) {
        dispatch(
          AuthenticationActions.changeDistributionList({
            companyId: companyId.toString(),
            distributionList: currentState.authentication.userInfo.companydetails
              .filter(item => item.companyId === companyId.toString())[0]
              .distributionListSubscribed.filter(item => item !== listType),
          }),
        );
      }
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;

      showToast('error', {
        id: 'toast.distribution_list.delete_record.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(error.response?.data);
    }
  },
);
