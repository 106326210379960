import {
  CCPDefaultButton,
  CCPPasswordTextField,
  HorizontalSpacer,
  Spacer,
  StepContainer,
} from 'components';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DefaultPasswordForm } from './interfaces';
import { DefaultPasswordSchema } from 'shared/schemas';
import { useAppDispatch, useAppSelector } from 'store';
import { InitialPasswordActions } from '../../../store';
import { INITIAL_PASSWORD_STEPS } from '../../../store/interfaces';
import { InitialPasswordSelectors } from '../../../store/initialPassword.selectors';
import { AsyncError } from './DefaultPassword.styles';

const DefaultPassword = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const defaultPasswordStepData = useAppSelector(InitialPasswordSelectors.getDefaultPasswordStep);
  const api = useAppSelector(InitialPasswordSelectors.api);

  const methods = useForm<DefaultPasswordForm>({
    defaultValues: {
      defaultPassword: defaultPasswordStepData.defaultPassword
        ? defaultPasswordStepData.defaultPassword
        : '',
    },
    resolver: yupResolver(DefaultPasswordSchema),
  });

  const { control, handleSubmit } = methods;

  const onSubmit = (data: DefaultPasswordForm) => {
    dispatch(InitialPasswordActions.updateDefaultPasswordStepData(data));
    dispatch(InitialPasswordActions.setStep(INITIAL_PASSWORD_STEPS.SET_NEW_PASSWORD));
  };

  return (
    <StepContainer>
      <Typography variant='body1' className={clsx('hint')}>
        {intl.formatMessage({
          id: 'default_password.enter',
          defaultMessage: 'Enter the default password, which was given to you by our team.',
        })}
      </Typography>
      <Spacer height={32} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CCPPasswordTextField
            label={intl.formatMessage({
              id: 'default_password',
              defaultMessage: 'Default password',
            })}
            name='defaultPassword'
            control={control}
            isRepeat={false}
          />
          {api.changeTemporaryPassword.error && (
            <>
              <Spacer height={32} />
              <AsyncError variant='body1'>
                {api.changeTemporaryPassword.error === 'Incorrect username or password.'
                  ? intl.formatMessage({
                      id: 'default_password.error',
                      defaultMessage: 'Incorrect default password',
                    })
                  : intl.formatMessage({
                      id: api.changeTemporaryPassword.error,
                    })}
              </AsyncError>
            </>
          )}
          <Spacer height={186} />
          <Box className='ctaContainer'>
            <HorizontalSpacer />
            <CCPDefaultButton type='submit' variant='contained' color='primary'>
              {intl.formatMessage({
                id: 'self_registration.next',
                defaultMessage: 'Next: Set password',
              })}
            </CCPDefaultButton>
          </Box>
        </form>
      </FormProvider>
    </StepContainer>
  );
};

export default DefaultPassword;
