import { useState } from 'react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'store';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Table,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import ClearIcon from 'assets/icons/atoms-icon-remove.svg';
import CloseIcon from 'assets/icons/close-icon.svg';
import WarningIcon from 'assets/icons/atoms-icon-36-x-36-warning.svg';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import { SettingsActions } from 'features/settings/store';
import { ListType } from 'types';
import { CCPDefaultButton } from 'components';
import {
  StyledCloseIconButton,
  StyledDeleteButton,
  StyledTableBody,
  StyledTableBodyText,
  StyledTableCell,
  StyledTableHead,
  StyledTableHeadText,
  StyledWarningIcon,
} from './DistributionList.styles';
import Loader from '@components/Loader';

const DistributionList = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.activationLetterDistributionList,
  );

  const handleDelete = async () => {
    if (activeCompany) {
      setIsLoading(true);
      const { companyId } = activeCompany;
      await dispatch(
        SettingsActions.deleteRecordFromDistributionList({
          companyId,
          email,
          listType: ListType.ACTIVATION_LETTER,
        }),
      );
      setEmail('');
      setIsModalOpen(false);
      setIsLoading(false);
    }
  };

  const handleOpenModal = (email: string) => {
    setEmail(email);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setEmail('');
    setIsModalOpen(false);
  };

  return (
    <>
      <Loader show={isLoading} />
      <TableContainer>
        <Table>
          <StyledTableHead>
            <TableRow>
              <StyledTableCell>
                <StyledTableHeadText variant='body1'>
                  {intl.formatMessage({
                    id: 'setting.activation_code.table.header.email',
                    defaultMessage: 'E-mail',
                  })}
                </StyledTableHeadText>
              </StyledTableCell>
              <StyledTableCell width='50%'>
                <StyledTableHeadText variant='body1'>
                  {intl.formatMessage({
                    id: 'setting.activation_code.table.header.date_added',
                    defaultMessage: 'Date added',
                  })}
                </StyledTableHeadText>
              </StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </StyledTableHead>
          <StyledTableBody>
            {activationLetterDistributionList.map(listItem => {
              const { cognitoId, email, creationDate } = listItem;
              return (
                <TableRow key={cognitoId}>
                  <StyledTableCell>
                    <StyledTableBodyText>{email}</StyledTableBodyText>
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledTableBodyText>
                      {moment(creationDate).format('DD.MM.YYYY')}
                    </StyledTableBodyText>
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    <StyledDeleteButton
                      onClick={() => handleOpenModal(email)}
                      startIcon={<img alt='' src={ClearIcon} />}>
                      {intl.formatMessage({
                        id: 'setting.activation_code.table.cta.delete',
                        defaultMessage: 'Delete',
                      })}
                    </StyledDeleteButton>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </StyledTableBody>
        </Table>
      </TableContainer>
      <Dialog open={isModalOpen}>
        <StyledCloseIconButton aria-label='delete' size='large' onClick={handleCloseModal}>
          <img alt='' src={CloseIcon} />
        </StyledCloseIconButton>
        <StyledWarningIcon alt='' src={WarningIcon} />
        <DialogTitle>
          {intl.formatMessage({
            id: 'user_setting.activation_code.delete_company_user.header',
            defaultMessage: 'Delete Company user from distribution list',
          })}
        </DialogTitle>
        <DialogContent>
          <Typography variant='subtitle1'>
            {intl.formatMessage(
              {
                id: 'user_setting.activation_code.delete_company_user.description',
                defaultMessage: 'You’re going to delete {email}. Continue?',
              },
              { email: <b>{email}</b> },
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <CCPDefaultButton variant='outlined' onClick={handleCloseModal}>
            {intl.formatMessage({
              id: 'user_setting.activation_code.delete_company_user.cancel',
              defaultMessage: 'Cancel',
            })}
          </CCPDefaultButton>
          <CCPDefaultButton variant='contained' onClick={handleDelete} color='error'>
            {intl.formatMessage({
              id: 'user_setting.activation_code.delete_company_user.confirm',
              defaultMessage: 'Yes, delete',
            })}
          </CCPDefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DistributionList;
