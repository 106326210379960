import { Box, Typography } from '@mui/material';
import { CCPDefaultButton, Spacer } from 'components';
import { CompanySelectors } from 'features/company/store/company.selectors';
import CustomFieldModal from 'features/settings/components/CompanyCustomField/CustomFieldModal';
import CustomFieldTable from 'features/settings/components/CompanyCustomField/CustomFieldTable';
import { EmptyCompanyCustomFieldTable } from 'features/settings/components/CompanyCustomField/EmptyTable';
import { SettingsActions } from 'features/settings/store';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  CompanyCustomFieldForm,
  CompanyCustomFieldType,
  CustomFieldTypes,
  FetchingStatus,
  TranslationsKeys,
  TranslationsRecord,
} from 'types';
import { CompanyCustomFieldWrapper } from './CompanyCustomField.styles';
import { CompanyActions } from '../../../../../company/store';
import { useLanguage } from '@hooks/useLanguage';
import Loader from '@components/Loader';

type OpenModalType = {
  open: boolean;
  type: 'add' | 'edit';
  data: CompanyCustomFieldForm;
};

const CompanyCustomField = () => {
  const intl = useIntl();
  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);
  const companyApi = useAppSelector(CompanySelectors.getApi);
  const company = companyApi.getCompany;
  const companyCustomFieldList = useSelector(SettingsSelectors.companyCustomFieldList);
  const dispatch = useAppDispatch();
  const companyFieldData = companyCustomFieldList?.[activeCompany?.companyId as number] || null;
  const isEmpty =
    !companyFieldData || (companyFieldData.data && companyFieldData.data.length === 0);
  const data = companyFieldData?.data;
  const language = useLanguage();
  const communicationLanguage =
    (company?.data?.communicationLanguage.toLowerCase() as TranslationsKeys) || language;

  const defaultData: CompanyCustomFieldForm = {
    key: '',
    inputType: '' as CustomFieldTypes,
    name: {} as TranslationsRecord,
    translationsFields: [],
    localizedValueFields: [],
    mandatory: 'no',
  };

  const [openModal, setOpenModal] = useState<OpenModalType>({
    open: false,
    data: defaultData,
    type: 'add',
  });

  const anotherTranslation: TranslationsKeys = useMemo(
    () => (communicationLanguage === 'de' ? 'en' : 'de'),
    [communicationLanguage],
  );

  const handleOpen = ({ data, type }: { data: CompanyCustomFieldType; type: 'add' | 'edit' }) => {
    const namesArray = [];
    const enumArray = [];

    let key: keyof typeof data.name;
    for (key in data.name) {
      namesArray.push({ languageName: key, value: data.name[key] || '' });
    }

    if (type === 'add') {
      enumArray.push({ value: '', keyName: '' });
      if (communicationLanguage)
        namesArray.push({ languageName: communicationLanguage, value: '' });
    }

    if (type === 'edit' && data.localizedValuesMap) {
      Object.keys(data.localizedValuesMap).forEach(item => {
        if (
          data.localizedValuesMap &&
          data.localizedValuesMap[item] &&
          (data.localizedValuesMap[item][communicationLanguage] ||
            data.localizedValuesMap[item][anotherTranslation])
        ) {
          enumArray.push({
            value:
              data.localizedValuesMap[item][communicationLanguage] ||
              data.localizedValuesMap[item][anotherTranslation] ||
              '',
            keyName: item,
          });
        }
      });
    }

    const mandatory = data.mandatory ? data.mandatory : 'no';

    setOpenModal({
      open: true,
      data: {
        ...data,
        mandatory,
        translationsFields: namesArray.filter(
          item => item.value || item.languageName === communicationLanguage,
        ),
        localizedValueFields: enumArray,
        isFirstValueDefault: data.isFirstValueDefault === 'yes',
      },
      type,
    });
  };
  const handleClose = () => {
    setOpenModal({
      open: false,
      data: defaultData,
      type: 'add',
    });
  };
  useEffect(() => {
    if (
      activeCompany?.companyId &&
      ((company.fetchingStatus === FetchingStatus.FULFILLED &&
        company?.data?.id !== activeCompany.companyId) ||
        company.fetchingStatus === FetchingStatus.IDLE)
    ) {
      dispatch(CompanyActions.getCompany({ companyId: activeCompany.companyId }));
    }
    if (activeCompany?.companyId) {
      dispatch(
        SettingsActions.getCompanyCustomField({
          companyId: activeCompany?.companyId,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  return (
    <CompanyCustomFieldWrapper id='customFieldSettingsWrapper'>
      <Loader
        show={companyFieldData?.fetchingStatus === FetchingStatus.PENDING}
        inCard={true}
        portalId='customFieldSettingsWrapper'
      />
      <Typography variant='h6'>
        {intl.formatMessage({
          id: 'setting.custom_field.title',
          defaultMessage: 'Company custom field',
        })}
      </Typography>
      <Spacer size='md' />
      <Box className='new'>
        <Typography variant='subtitle1'>
          {intl.formatMessage({
            id: 'setting.custom_field.sub_title',
            defaultMessage: 'Create up to three custom fields for your company.',
          })}
        </Typography>
        <CCPDefaultButton
          variant='contained'
          disabled={
            (data && data?.length >= 3) ||
            !(
              companyFieldData?.fetchingStatus &&
              companyFieldData.fetchingStatus === FetchingStatus.FULFILLED
            )
          }
          onClick={() =>
            handleOpen({
              data: { ...defaultData, name: {} as TranslationsRecord, isFirstValueDefault: 'no' },
              type: 'add',
            })
          }>
          {intl.formatMessage({
            id: 'btn.action.add_new_custom_field',
            defaultMessage: 'Add new custom field',
          })}
        </CCPDefaultButton>
      </Box>

      {!isEmpty && data ? (
        <CustomFieldTable
          data={data}
          handleEdit={handleOpen}
          companyLanguage={communicationLanguage}
        />
      ) : (
        <EmptyCompanyCustomFieldTable />
      )}
      {openModal.open && (
        <CustomFieldModal
          {...openModal}
          handleClose={handleClose}
          companyLanguage={communicationLanguage}
          companyId={activeCompany?.companyId}
        />
      )}
    </CompanyCustomFieldWrapper>
  );
};

export default CompanyCustomField;
