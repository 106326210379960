import { useEffect, useState } from 'react';
import { useAppSelector } from 'store';
import { Box, Typography } from '@mui/material';

import { CompanySelectors } from 'features/company/store/company.selectors';
import { FetchingStatus, IUserInfo, ListType } from 'types';
import { useIntl } from 'react-intl';
import { Spacer } from 'components';
import CustomTable from 'components/Table';
import Loader from 'components/Loader';
import NotificationTableRow, { tableColumns } from '../Notifications/NotificationTableRow';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';

const NotificationSettings: React.FC = () => {
  const intl = useIntl();

  const ownUserData = useAppSelector(SettingsSelectors.getOwnUserData);
  const { companiesNames } = useAppSelector(CompanySelectors.getCompanyState);
  const fetchingStatus = useAppSelector(store => store.settings.ownUserDataFetchingStatus);
  const [tableData, setTableData] = useState<IUserInfo[]>([]);

  useEffect(() => {
    if (companiesNames.data.length && ownUserData) {
      const resultResp: any = (ownUserData.companydetails || []).map(company => {
        const companyName =
          companiesNames?.data?.find(item => item.companyId?.toString() === company.companyId)
            ?.companyName || company.companyName;
        const notifications = (company.distributionListSubscribed || [])
          .filter(item => item === ListType.INVOICES || item === ListType.NEW_EU_REQUEST)
          .map(item => (item === ListType.INVOICES ? 'invoices' : 'onboarding'));
        return { ...company, companyName, notifications };
      });
      setTableData(resultResp);
    }
  }, [companiesNames, ownUserData]);

  const updateTableData = (companyId: string, notifications: ('invoices' | 'onboarding')[]) => {
    setTableData(
      tableData.map(item => {
        if (item.companyId === Number(companyId)) {
          return { ...item, notifications };
        }
        return item;
      }),
    );
  };
  const renderRow = (item: IUserInfo & { notifications: ('invoices' | 'onboarding')[] }) => (
    <NotificationTableRow key={item.companyId} item={item} updateLocalData={updateTableData} />
  );

  const isLoading = fetchingStatus === FetchingStatus.PENDING || tableData.length === 0;

  return (
    <Box position='relative' id='notificationsWrapper'>
      <Loader show={isLoading} inCard={true} portalId='notificationsWrapper' />
      <Typography variant='h6'>
        {intl.formatMessage({
          id: 'account.tab.notifications',
          defaultMessage: 'Notifications',
        })}
      </Typography>
      <Spacer size='md' />
      <Typography variant='subtitle1'>
        {intl.formatMessage({
          id: 'account.tab.notifications.subtitle',
          defaultMessage: 'Choose notification preferences and how you want to be contacted.',
        })}
      </Typography>
      <Spacer size='md' />
      {tableData.length > 0 && (
        <CustomTable columnsList={tableColumns} listData={tableData} renderRow={renderRow} />
      )}
      <Spacer size='md' />
    </Box>
  );
};

export default NotificationSettings;
