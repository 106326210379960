import { Box } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CouponUsageWrapper } from './CouponUsage.styles';
import { StatsInfo, CouponTable } from '../../components';

import { FetchingStatus, RootState } from 'types';
import { StatsActions } from 'features/stats/store';
import { useAppDispatch } from 'store';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { getStatisticCount, getStatsMonth } from '../../../../utils';

const CouponUsagePage = () => {
  const dispatch = useAppDispatch();
  const { coupons } = useSelector((state: RootState) => state.stats);
  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);

  const { startMonth, endMonth, startYear, endYear } = coupons?.filters;
  const couponsCount = useMemo(
    () => getStatisticCount({ data: coupons?.data, type: 'coupons' }),
    [coupons?.data],
  );

  const getCouponsList = async () => {
    const { startMonthString, endMonthString } = getStatsMonth({ startMonth, endMonth });
    dispatch(
      StatsActions.getCoupons({
        companyId: activeCompany?.companyId,
        startMonth: startMonthString + `.${startYear}`,
        endMonth: endMonthString + `.${endYear}`,
      }),
    );
  };

  const updateFiltersHandler = (filters: any): void => {
    dispatch(StatsActions.updateCouponFilters(filters));
  };

  const updateValuesHandler = (values: any): void => {
    dispatch(StatsActions.getCoupons(values));
  };

  useEffect(() => {
    if (activeCompany) {
      getCouponsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  return (
    <CouponUsageWrapper>
      <Box className="container">
        <StatsInfo
          data={coupons.data?.couponsList}
          count={couponsCount}
          filters={coupons.filters}
          fileName="coupon-usage"
          className="yellow"
          isLoading={coupons.isLoading}
          updateValues={updateValuesHandler}
          updateFilters={updateFiltersHandler}
          hasError={coupons.fetchingStatus === FetchingStatus.REJECTED}
        />
        <Box className="content">
          <CouponTable />
        </Box>
      </Box>
    </CouponUsageWrapper>
  );
};

export default CouponUsagePage;
