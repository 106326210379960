import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper, Table, TableRow, TableCell, TableContainer, Pagination } from '@mui/material';

import { FetchingStatus, RootState } from 'types';

import TableLoader from '../TableLoader';
import TableCellRow from './TableCellRow';
import TableEmptyData from '../TableEmptyData';
import { StyledTableHead, StyledTableBody, PaginationWrapper } from './CheckInsTable.styles';
import { useIntl } from 'react-intl';
import { TableErrorData } from '../TableErrorData.ts';
import { useLanguage } from 'hooks';

const CheckInsTable = () => {
  const intl = useIntl();
  const activeLanguage = useLanguage();
  const { checkIns } = useSelector((state: RootState) => state.stats);
  const [page, setPage] = useState<number>(0);
  const checkInsList = checkIns.data!?.checkInsList;
  const hasError = checkIns.fetchingStatus === FetchingStatus.REJECTED;

  const rowsPerPage = 10;
  const offset = page * rowsPerPage;
  const limit = offset + rowsPerPage;
  const paginationListCount = Math.ceil(checkInsList?.length / rowsPerPage);
  const calculatePatinationResults =
    limit + 1 >= checkInsList?.length ? checkInsList?.length : limit;

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  if (checkIns.isLoading) {
    return <TableLoader />;
  }

  if (hasError) {
    return <TableErrorData />;
  }

  return checkInsList?.length ? (
    <>
      <TableContainer className="coupon_table" component={Paper} elevation={0}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell className="table_cell">
                {intl
                  .formatMessage({
                    id: 'view.stats.table.service',
                    defaultMessage: 'Service provider',
                  })
                  .toUpperCase()}
              </TableCell>
              <TableCell className="table_cell">
                {intl
                  .formatMessage({
                    id: 'dashboard__active_users',
                    defaultMessage: 'active users',
                  })
                  .toUpperCase()}
              </TableCell>
              <TableCell className="table_cell">
                {intl
                  .formatMessage({ id: 'view.stats.checkins', defaultMessage: 'Check-Ins' })
                  .toUpperCase()}
              </TableCell>
            </TableRow>
          </StyledTableHead>
          <StyledTableBody>
            {checkInsList?.slice(offset, limit)?.map(item => (
              <TableCellRow
                key={item.partner_id}
                data={item}
                borderEnd={page + 1 === paginationListCount}
                activeLanguage={activeLanguage}
              />
            ))}
          </StyledTableBody>
        </Table>
      </TableContainer>
      {checkInsList.length > 11 && (
        <PaginationWrapper>
          <Box className="results">
            {intl.formatMessage(
              {
                id: 'table.pagination.result',
                defaultMessage: '{first}-{last} from Results {result}',
              },
              {
                first: offset + 1,
                last: calculatePatinationResults,
                result: checkInsList?.length,
              },
            )}
          </Box>
          <Pagination
            showFirstButton
            showLastButton
            page={page + 1}
            count={paginationListCount}
            onChange={handleChange}
          />
        </PaginationWrapper>
      )}
    </>
  ) : (
    <>
      <TableContainer className="coupon_table" component={Paper} elevation={0}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell className="table_cell">
                {intl
                  .formatMessage({
                    id: 'view.stats.table.service',
                    defaultMessage: 'Service provider',
                  })
                  .toUpperCase()}
              </TableCell>
              <TableCell className="table_cell">
                {intl
                  .formatMessage({
                    id: 'dashboard__active_users',
                    defaultMessage: 'active users',
                  })
                  .toUpperCase()}
              </TableCell>
              <TableCell className="table_cell">
                {intl
                  .formatMessage({ id: 'view.stats.checkins', defaultMessage: 'Check-Ins' })
                  .toUpperCase()}
              </TableCell>
            </TableRow>
          </StyledTableHead>
        </Table>
      </TableContainer>
      <TableEmptyData />
    </>
  );
};

export default CheckInsTable;
