import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { DeleteEmailModalProps } from 'types/index';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';

import CCPDefaultButton from '@components/Button/Default';
import { default as theme } from '@theme/theme';
import { useIntl } from 'react-intl';

const DeleteEmailModal: React.FC<DeleteEmailModalProps> = ({
  email,
  isOpen,
  onSubmit,
  closeModal,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <CloseIcon onClick={closeModal} className='dialog-close' />
      <DialogTitle>
        <Box className='dialog-icon'>
          <ErrorOutlineIcon htmlColor={theme.palette.error.main} />
        </Box>
        <Typography variant='h2'>
          {formatMessage({
            id: 'account.alert.delete_email.title',
            defaultMessage: 'Delete E-Mail adress',
          })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>
          {formatMessage(
            {
              id: 'account.alert.delete_email.subtitle',
              defaultMessage:
                'You’re going to delete {emailAddress}. All data associated with this member, will be lost. Continue?',
            },
            { emailAddress: <b>({email})</b> },
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton onClick={closeModal} variant='outlined'>
          {formatMessage({
            id: 'account.alert.delete_email.cta.cancel',
            defaultMessage: 'Cancel',
          })}
        </CCPDefaultButton>
        <CCPDefaultButton onClick={onSubmit} variant='contained'>
          {formatMessage({
            id: 'account.alert.delete_email.cta.delete',
            defaultMessage: 'Yes, delete',
          })}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteEmailModal;
