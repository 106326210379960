const downloadFile = (file: any) => {
  const fileBlob = new Blob([file?.Body], {
    type: file.ContentType,
  });
  const url = window.URL.createObjectURL(fileBlob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'invoice');
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default downloadFile;


export const downloadFileFromUrl = (url: any) => {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: url,
  }).click();
};