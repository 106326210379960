import { Button, styled } from '@mui/material';

const CCPRectButton = styled(Button)(({ theme }) => ({
  maxHeight: 40,
  lineHeight: 1.38,
  color: theme.palette.primary.main,
  background: theme.palette.transparent.blue07,
  fontSize: 16,
  borderRadius: 4,
  minWidth: 80,
  padding: '8px 18px',
  '&.MuiButton-textPrimary:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primaryLight.dark,
  },
  '&.MuiButton-textPrimary:active': {
    color: theme.palette.primaryLight.darker,
    backgroundColor: theme.palette.primaryLight.main,
  },
  '&.MuiButton-textPrimary:disabled': {
    color: theme.palette.neutralDark.light,
    backgroundColor: theme.palette.primaryLight.main,
  },

  '&.MuiButton-contained': {
    boxShadow: 'none',
  },
  '&.MuiButton-containedPrimary': {
    color: theme.palette.neutralLight.light,
    backgroundColor: theme.palette.primary.main,
  },
  '&.MuiButton-containedPrimary:hover': {
    color: theme.palette.primaryLight.darker,
    backgroundColor: theme.palette.primary.dark,
  },
  '&.MuiButton-containedPrimary:active': {
    color: theme.palette.primaryLight.darker,
    backgroundColor: theme.palette.primary.darker,
  },
  '&.MuiButton-contained:disabled': {
    color: theme.palette.neutralLight.main,
    backgroundColor: theme.palette.neutralDark.light,
  },

  '&.MuiButton-containedSecondary': {
    boxShadow: 'none',
    color: theme.palette.neutralDark.main,
    backgroundColor: theme.palette.primaryLight.main,
  },
  '&.MuiButton-containedSecondary:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primaryLight.dark,
  },
  '&.MuiButton-containedSecondary:active': {
    color: theme.palette.primaryLight.darker,
    backgroundColor: theme.palette.primaryLight.dark,
  },
  '&.MuiButton-containedSecondary:disabled': {
    color: theme.palette.neutralDark.light,
    backgroundColor: theme.palette.primaryLight.main,
  },
}));

export default CCPRectButton;
