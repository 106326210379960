import { Button, styled, Chip, Box, Typography } from '@mui/material';

export const MarketingMaterialTypeBadge = styled(Box)(({ theme }) => ({
  padding: '0px 10px',
  color: theme.palette.neutralDark.dark,
  fontSize: 16,
  lineHeight: '24px',
  border: `1px solid  ${theme.palette.neutralDark.light}`,
  borderRadius: 12,
  textTransform: 'unset',
  width: 'fit-content',
}));

export const MarketingMaterialButton = styled(Button)(({ theme }) => ({
  padding: '5px 0 5px 20px',
  minWidth: 'fit-content',
  color: theme.palette.neutralDark.main,
  fontFamily: 'Roboto-Medium',
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1.38,
  textTransform: 'unset',
  '&:visited': { background: theme.palette.neutralLight.light },
  '&:hover': { background: theme.palette.neutralLight.light, opacity: 0.8 },
  '&:active': { background: theme.palette.neutralLight.light },
}));

export const PermissionsChip = styled(Chip)(({ theme }) => ({
  gap: 6,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 11,
  padding: '1px 12px',
  fontFamily: 'Roboto-Medium',
  fontSize: 16,
  lineHeight: 1.38,
  color: theme.palette.neutralDark.contrastText,
  border: `1px solid  ${theme.palette.neutralLight.dark}`,
  height: 'auto',

  span: {
    padding: 0,
  },

  svg: {
    marginLeft: '-6px !important',
  },
}));

export const StatusChip = styled(Chip)<any>(({ background, theme }) => ({
  background,
  borderRadius: 4,
  padding: '2px 8px',
  fontFamily: 'Roboto-Medium',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 1.29,
  color: theme.palette.neutralLight.light,
  textTransform: 'uppercase',
  height: 'auto',

  span: {
    padding: 0,
  },
}));

export const StyleName = styled(Typography)`
  font-size: 16px;
  font-family: Roboto-Bold;
`;

export const StyleDescription = styled(Typography)`
  font-size: 16px;
  color: #6b7986;
  font-family: Roboto-Regular;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
`;

export const StyleFileType = styled(Typography)`
  font-size: 16px;
  color: #000000;
  font-family: Roboto-Regular;
`;

export const StyleCategory = styled(Box)`
  padding: 1px 8px;
  color: #000000;
  font-family: Roboto-Regular;
  font-size: 16px;
  line-height: 1.38;
  border: 1px solid #d8d8d8;
  border-radius: 11px;
  text-transform: unset;
`;

export const CellContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2px;
`;
