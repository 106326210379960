import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { getInvoicesExtraReducer } from './actions/async/getInvoices/getInvoices.reducer';
import {
  resetFilters,
  resetSearchData,
  resetTableConfiguration,
  setInitialTableConfiguration,
  toggleAllColumns,
  toggleColumn,
  updateFilters,
  updateRowsPerPage,
} from './actions/sync/invoices.actions';

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInitialTableConfiguration,
    resetTableConfiguration,
    toggleColumn,
    toggleAllColumns,
    updateRowsPerPage,
    updateFilters,
    resetFilters,
    resetSearchData,
  },
  extraReducers: builder => {
    getInvoicesExtraReducer(builder);
  },
});

export const { name, actions } = invoicesSlice;
export const invoicesReducer = invoicesSlice.reducer;
