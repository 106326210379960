import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Checkbox,
  Typography,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, FormProvider } from 'react-hook-form';

import { AddUserSchema } from 'shared/schemas';
import { CompanyRole, FetchingStatus, TRoles } from 'types';
import { useAppDispatch, useAppSelector } from 'store/store';
import { UserManagementActions } from 'features/userManagement/store';
import { useAddNewUserFieldList, usePermissionsSettingsList } from 'utils';
import { NewUserModalProps, PermissionsSettingsState, RootState } from 'types';

import { WrapperCheckboxesForm, WrapperInputsForm } from './NewUser.styles';
import { useIntl } from 'react-intl';
import CCPDefaultButton from '@components/Button/Default';
import { default as theme } from '@theme/theme';
import Loader from '@components/Loader';
import Spacer from '@components/Spacer';
import CCPTextField from '@components/Form/TextField/TextField';

const NewUserModal: React.FC<NewUserModalProps> = ({
  isOpen,
  handleClose,
  activeCompanyId,
  activeCompanyName,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { fetchingStatus } = useAppSelector((state: RootState) => state.userManagement.addUser);
  const [isSuccess, setIsSuccess] = useState(false);

  const methods = useForm({
    defaultValues: {
      email: '',
      name: '',
      surname: '',
      position: '',
      Admin: false,
      Invoice: false,
      Usermgmt: false,
    },
    mode: 'onChange',
    resolver: yupResolver(AddUserSchema),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = methods;

  const { name, email, surname, position, Admin, Invoice, Usermgmt } = watch();
  const permissionsState: PermissionsSettingsState = useMemo(
    () => ({
      Admin,
      Invoice,
      Usermgmt,
    }),
    [Admin, Invoice, Usermgmt],
  );
  const activeRole = Object.keys(permissionsState).filter(
    (key: string) => permissionsState[key],
  ) as TRoles;
  const permissionsSettingsList = usePermissionsSettingsList();
  const addNewUserFieldList = useAddNewUserFieldList();

  const onSubmit = (): void => {
    dispatch(
      UserManagementActions.addUser({
        companyId: String(activeCompanyId),
        companyName: activeCompanyName,
        email,
        name,
        surname,
        position,
        role: activeRole,
      }),
    );
  };

  const closeModalHandler = () => {
    setIsSuccess(false);
    handleClose();
  };

  useEffect(() => {
    if (fetchingStatus === FetchingStatus.FULFILLED) {
      setIsSuccess(true);
    }
  }, [fetchingStatus]);

  useEffect(
    () => () => {
      dispatch(UserManagementActions.updateFetchingStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const renderNewUserFieldList = addNewUserFieldList.map(({ label, name, placeholder }) => (
    <CCPTextField key={name} fullWidth required label={label} control={control} name={name} />
  ));

  const renderPermissionsSettingsList = permissionsSettingsList.map(
    ({ name, labelText, labelTitle }) => (
      <Controller
        key={labelTitle}
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            className='item'
            control={
              <Checkbox
                className='checkbox'
                onChange={e => field.onChange(e.target.checked)}
                checked={field.value ?? false}
              />
            }
            label={
              <Box>
                <Typography variant='h3'>
                  {labelTitle}
                  {name === CompanyRole.Admin && (
                    <StarsIcon fontSize='small' htmlColor={theme.palette.neutralDark.main} />
                  )}
                </Typography>
                <Typography color={theme.palette.neutralDark.main} variant='h5'>
                  {labelText}
                </Typography>
              </Box>
            }
          />
        )}
      />
    ),
  );

  return (
    <>
      <Loader show={fetchingStatus === FetchingStatus.PENDING} />
      <FormProvider {...methods}>
        <Dialog
          open={isOpen}
          onClose={closeModalHandler}
          scroll='paper'
          component='form'
          onSubmit={handleSubmit(onSubmit)}>
          {!isSuccess ? (
            <>
              <DialogTitle>
                <Typography variant='h2'>
                  {formatMessage({
                    id: 'view.user_management.cta.add_new_user',
                    defaultMessage: 'Add new user',
                  })}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Typography variant='subtitle1'>
                  {formatMessage({
                    id: 'view.user_management.add_new.title',
                    defaultMessage:
                      'Fill out the form below to add new member. Than select a role for this member to grant them access to your resources.',
                  })}
                </Typography>
                <Spacer size='lg' />
                <WrapperInputsForm>{renderNewUserFieldList}</WrapperInputsForm>
                <Spacer size='lg' />
                <Typography variant='h6'>
                  {formatMessage({
                    id: 'view.user_management.permission_setting',
                    defaultMessage: 'Permissions settings',
                  })}
                </Typography>
                <Spacer size='md' />
                <WrapperCheckboxesForm>{renderPermissionsSettingsList}</WrapperCheckboxesForm>
              </DialogContent>
              <DialogActions>
                <CCPDefaultButton onClick={closeModalHandler} variant='outlined'>
                  {formatMessage({
                    id: 'form.employee.cta.back',
                    defaultMessage: 'Back to list',
                  })}
                </CCPDefaultButton>
                <CCPDefaultButton type='submit' disabled={!isValid} variant='contained'>
                  {formatMessage({
                    id: 'form.employee.cta.send',
                    defaultMessage: 'Send invite',
                  })}
                </CCPDefaultButton>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogTitle>
                <Box className='dialog-icon'>
                  <CheckCircleOutlinedIcon htmlColor={theme.palette.success.main} />
                </Box>
                <Typography variant='h2'>
                  {formatMessage({
                    id: 'form.employee.sent',
                    defaultMessage: 'Invitation has been sent',
                  })}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Typography variant='subtitle1'>
                  {formatMessage(
                    {
                      id: 'form.employee.sent_at',
                      defaultMessage: 'Invitation has been sent at {userEmail}',
                    },
                    { userEmail: <b>{email}</b> },
                  )}
                </Typography>
              </DialogContent>
              <DialogActions>
                <CCPDefaultButton
                  onClick={closeModalHandler}
                  variant='outlined'
                  className='wrapper__footer_item'>
                  {formatMessage({
                    id: 'form.employee.cta.back',
                    defaultMessage: 'Back to list',
                  })}
                </CCPDefaultButton>
              </DialogActions>
            </>
          )}
        </Dialog>
      </FormProvider>
    </>
  );
};

export default NewUserModal;
