import { Typography, Box } from '@mui/material';
import { useIntl } from 'react-intl';

import { NoDataWrapper } from './Invoices.styles';

const InvoicesNoData = () => {
  const intl = useIntl();
  const boxList = Array.from(Array(28), (item: any, index: number) => index);

  const renderBoxList = boxList.map(item => <Box key={item} className="skeleton__content_item" />);

  return (
    <NoDataWrapper>
      <Box className="content">
        <Box className="skeleton">
          <Box className="skeleton__top">
            <Typography className="skeleton__top_text" variant="body1">
              {intl.formatMessage({
                id: 'view.invoice.table.invoice_id',
                defaultMessage: 'Invoice Id',
              })}
            </Typography>
            <Typography className="skeleton__top_text" variant="body1">
              {intl.formatMessage({
                id: 'view.invoice.table.period',
                defaultMessage: 'Period',
              })}
            </Typography>
            <Typography className="skeleton__top_text" variant="body1">
              {intl.formatMessage({
                id: 'view.invoice.table.invoice_type',
                defaultMessage: 'Invoice Type',
              })}
            </Typography>
            <Typography className="skeleton__top_text" variant="body1">
              {intl.formatMessage({
                id: 'view.invoice.table.date_of_issue',
                defaultMessage: 'Date of issue',
              })}
            </Typography>
          </Box>
          <Box className="skeleton__content">{renderBoxList}</Box>
        </Box>
        <Box className="content__info">
          <Typography className="content__title" variant="body1">
            {intl.formatMessage({
              id: 'view.invoice.table.no_invoice',
              defaultMessage: 'No invoices being issued',
            })}
          </Typography>
          <Typography className="content__text" variant="body1">
            {intl.formatMessage({
              id: 'view.invoice.table.invoice_display',
              defaultMessage: 'If invoices are available, they are displayed here.',
            })}
          </Typography>
        </Box>
      </Box>
    </NoDataWrapper>
  );
};

export default InvoicesNoData;
