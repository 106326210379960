import { Box, Typography } from '@mui/material';
import { Spacer } from 'components';
import { useIntl } from 'react-intl';
import { FileTemplate } from './FileTemplate.styles';

export const EmptyContractTable = () => {
  const intl = useIntl();
  const templateHeaders = [
    intl.formatMessage({
      id: 'view.contract.table.header.start_date',
      defaultMessage: 'Start date',
    }),
    intl.formatMessage({
      id: 'view.contract.table.header.end_date',
      defaultMessage: 'End date',
    }),
    intl.formatMessage({
      id: 'view.contract.table.header.product',
      defaultMessage: 'Product',
    }),
    intl.formatMessage({
      id: 'view.contract.table.header.type',
      defaultMessage: 'Type',
    }),
    intl.formatMessage({
      id: 'view.contract.table.header.fees',
      defaultMessage: 'Fees',
    }),
  ];
  return (
    <Box position='relative'>
      <FileTemplate>
        <thead>
          <tr>
            {templateHeaders.map(header => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from(Array(templateHeaders.length).keys()).map((_item, index) => (
            <tr key={index}>
              {templateHeaders.map((_, i) => (
                <td key={i}></td>
              ))}
            </tr>
          ))}
        </tbody>
      </FileTemplate>
      <Box
        position='absolute'
        top={0}
        left={0}
        width='100%'
        height='100%'
        display='flex'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'>
        <Typography variant='h2'>
          {intl.formatMessage({
            id: 'view.contract.empty.title',
            defaultMessage: 'No contracts available',
          })}
        </Typography>
        <Spacer size='md' />
        <Typography variant='caption'>
          {intl.formatMessage({
            id: 'view.contract.empty.label',
            defaultMessage: 'If you have a contract you can find it here',
          })}
        </Typography>
      </Box>
    </Box>
  );
};
