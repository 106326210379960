import { FormControlLabel, styled } from '@mui/material';

export const LabelCheckboxStyled = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: -3,
  '&.error_checkbox .MuiCheckbox-root': {
    color: theme.palette.error.main,
  },
  '&.font-weight-bold .MuiFormControlLabel-label': {
    fontWeight: 'bold',
  },
  '.MuiFormControlLabel-label ': {
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
  },
  '+ .error_checkbox_helper': {
    color: theme.palette.error.main,
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    marginLeft: 33,
    display: 'block',
  },
}));
