import { styled } from '@mui/material';

export const GuestLayoutStyled = styled('div')(({ theme }) => ({
  '.container': {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100vh',
    backgroundImage: theme.palette.gradient.light,
  },
  '.content': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
}));
