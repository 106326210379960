import { Box } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StatsActions } from '@features/stats/store';
import { useAppDispatch } from '@store/store';
import { CompanySelectors } from '@features/company/store/company.selectors';
import { CheckInsWrapper } from './CheckIns.styles';
import { getStatisticCount } from '@utils/getStatisticCount';
import { getStatsMonth } from '@utils/getStatsMonth';
import { CheckInsTable, StatsInfo } from '@features/stats/components';
import { RootState } from '@store/interfaces';
import { FetchingStatus, FiltersProps } from '@localTypes/index';

const CheckInsPage = () => {
  const dispatch = useAppDispatch();
  const { checkIns } = useSelector((state: RootState) => state.stats);
  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);

  const checkInsCount = useMemo(
    () => getStatisticCount({ data: checkIns.data, type: 'checkIns' }),
    [checkIns?.data],
  );

  const getCheckInsList = useCallback(() => {
    const { startMonth, endMonth, startYear, endYear } = checkIns.filters;
    const { startMonthString, endMonthString } = getStatsMonth({ startMonth, endMonth });

    if (!activeCompany?.companyId) return;

    dispatch(
      StatsActions.getCheckIns({
        companyId: activeCompany?.companyId,
        startMonth: startMonthString + `.${startYear}`,
        endMonth: endMonthString + `.${endYear}`,
      }),
    );
  }, [activeCompany?.companyId, checkIns.filters, dispatch]);

  const updateFiltersHandler = (filters?: FiltersProps) => {
    if (!filters) {
      return;
    }
    dispatch(StatsActions.updateCheckInsFilters(filters));
  };

  useEffect(() => {
    getCheckInsList();
  }, [getCheckInsList]);

  return (
    <CheckInsWrapper>
      <Box className='container'>
        <StatsInfo
          data={checkIns?.data?.checkInsList}
          count={checkInsCount}
          filters={checkIns.filters}
          fileName='check-ins'
          className='blue'
          isLoading={checkIns.isLoading}
          // updateValues={updateValuesHandler}
          updateFilters={updateFiltersHandler}
          hasError={checkIns.fetchingStatus === FetchingStatus.REJECTED}
        />
        <Box className='content'>
          <CheckInsTable />
        </Box>
      </Box>
    </CheckInsWrapper>
  );
};

export default CheckInsPage;
