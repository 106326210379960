import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch } from '@store/index';
import GuestHeader from '@components/GuestHeader';
import Spacer from '@components/Spacer';
import GuestFormContainer from '@components/GuestFormContainer';
import Login from '@features/authentication/components/Login';
import { AuthenticationActions } from '@features/authentication/store';

const LoginPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const resetState = () => {
      dispatch(AuthenticationActions.resetAuthState());
    };

    window.addEventListener('load', resetState);
    return () => {
      window.removeEventListener('load', resetState);
    };
  }, [dispatch]);

  return (
    <Box sx={{ width: '100%' }}>
      <GuestHeader />
      <Spacer height={48} />
      <GuestFormContainer>
        <Login />
      </GuestFormContainer>
    </Box>
  );
};

export default LoginPage;
