import { styled, Box } from '@mui/material';

export const StatsHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 24,

  a: {
    textDecoration: 'unset',
  },

  button: {
    borderRadius: 18,
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.38,
    color: theme.palette.primary.main,
    textTransform: 'unset',
    border: `1px solid ${theme.palette.primary.main}`,
  },

  '.left ': {
    padding: '7px 16px 7px 14px',
  },

  '.right': {
    padding: '7px 14px 7px 16px',
  },
}));
