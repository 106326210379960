import { Box, styled } from '@mui/material';

export const ContractWrapper = styled(Box)(({ theme }) => ({
  '.mainContent': {
    background: theme.palette.neutralLight.light,
    padding: 48,
    paddingTop: 35,
  },
  '.companyAddress': {
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
  },
  '.contractList': {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
}));

export const IconContainer = styled('div')(({ theme }) => ({
  width: 'fit-content',
  marginBottom: 10,
  svg: {
    padding: 1,
    height: 40,
    width: 40,
    fill: theme.palette.warning.main,
    position: 'relative',
    top: 3,
  },
}));
