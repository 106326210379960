import { useIntl } from 'react-intl';
import { DownloadButtonWrapper } from './DownloadButton.styles';
import { Download } from '@mui/icons-material';
import { FaqsState } from 'types';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { S3CredentialsActions } from 'features/shared/s3Credentials/store';
import { getS3Credential } from 'utils/getS3Credential';
import { S3 } from 'aws-sdk';
import { showToast } from 'utils';
import { useState } from 'react';
import { CCPDefaultButton } from '@components/index';

export interface DonwloadButtonProps {
  downloadFile?: (item: any) => void;
  article: FaqsState | undefined;
}

const DonwloadButton: React.FC<DonwloadButtonProps> = ({ article }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [videoIsLoading, setVideoIsLoading] = useState<boolean>(false);
  const clickHandler = () => {
    setVideoIsLoading(true);
    getS3FAQMaterial(companyId);
  };

  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);
  const companyId = activeCompany?.companyId || 0;

  const assetPath = article?.asset || '';
  const assetIdx = assetPath.indexOf('assets') || 0;
  const assetKey = assetPath.substring(assetIdx, assetPath.length);
  const assetType = assetKey.includes(`assets/Company/${companyId}`)
    ? 'companyType'
    : 'generalType';

  const getS3FAQMaterial = async (companyId: number) => {
    try {
      const s3Response = await dispatch(
        S3CredentialsActions.getS3CredentialsCompany({
          companyId,
        }),
      ).unwrap();
      if (!s3Response) {
        return;
      }

      let s3Material = getS3Credential({
        data: s3Response,
        type: assetType === 'companyType' ? `Company/${companyId}/FAQ/Video` : 'Company/FAQ/Video',
      });

      if (!s3Material) return;

      let s3 = new S3({
        region: 'eu-central-1',
        accessKeyId: s3Material.AccessKeyId,
        secretAccessKey: s3Material.SecretAccessKey,
        sessionToken: s3Material.SessionToken,
      });

      const bucket = s3Material.s3Url?.split('/')[2];
      const params: any = { Bucket: bucket, Key: assetKey };

      const file = await s3.getObject(params).promise();
      handleDownloadFile(file);
    } catch (error: any) {
      setVideoIsLoading(false);
      if (error.statusCode === 403 && error.code === 'AccessDenied') {
        showToast('error', {
          id: 'support.articles.download_video.error',
          defaultMessage: 'Access to the video is denied',
        });
      } else {
        console.log(error);
      }
    }
  };

  const handleDownloadFile = (file: any) => {
    if (!file) {
      showToast('error', 'File error', 3000);
      return;
    }
    const fileBlob = new Blob([file?.Body], {
      type: file.ContentType,
    });
    const url = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', article?.question || 'FAQ Video');
    document.body.appendChild(link);
    link.click();
    link.remove();
    setVideoIsLoading(false);
  };

  return (
    <DownloadButtonWrapper>
      <CCPDefaultButton
        key={article?.id}
        variant='text'
        disabled={videoIsLoading}
        startIcon={<Download />}
        onClick={clickHandler}
        color='primary'>
        {intl.formatMessage({
          id: `faq.detail_page.action.download.video_asset`,
          defaultMessage: 'Download video',
        })}
      </CCPDefaultButton>
    </DownloadButtonWrapper>
  );
};

export default DonwloadButton;
