import { Box } from '@mui/material';
import { useAppSelector } from 'store';
import { TRoles } from 'types';
import { useUserSettingsTabs } from 'utils';

import {
  Invoice,
  ActivationCode,
  SelfRegistration,
  Onboarding,
  CompanyCustomField,
} from './SettingsTabs';

export interface SettingsAccountProps {
  activeTab: number;
  userRoles: TRoles;
  isShowTab: (tab: { value: string; label: string; role: TRoles }) => boolean;
}

const SettingContent: React.FC<SettingsAccountProps> = ({ activeTab, userRoles, isShowTab }) => {
  const autoConfirmSignup = useAppSelector(
    state => state.settings.defaultSelfRegistration.autoConfirmSignup,
  );
  //very strange logic, I hope somebody rewrite it who will do CCP-630
  //current logic needs to correct show userAccountTabs after refresh page(by F5) if permissions were changed
  const userSettingsTabs = useUserSettingsTabs();
  const displayedUserSettings = userSettingsTabs.map((tab, index) => {
    return {
      show: isShowTab(tab),
      idx: index,
    };
  });
  const userAccountTabs = [
    <Invoice />,
    <ActivationCode />,
    <SelfRegistration />,
    <Onboarding />,
    <CompanyCustomField />,
  ].map((item, index) => (displayedUserSettings[index].show ? item : <div></div>));

  return <Box className="content">{userAccountTabs[activeTab]}</Box>;
};

export default SettingContent;
