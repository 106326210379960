import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FetchingStatus, RootState } from 'types';
import { StatsActions } from 'features/stats/store';
import { useAppDispatch } from 'store';
import { CompanySelectors } from 'features/company/store/company.selectors';

import { ActiveMembersWrapper } from './ActiveMembers.styles';
import { StatsInfo, MembersTable } from '../../components';
import { getStatsMonth } from '../../../../utils';

const ActiveMembersPage = () => {
  const dispatch = useAppDispatch();
  const { activeMembers } = useSelector((state: RootState) => state.stats);
  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);

  const { startMonth, endMonth, startYear, endYear } = activeMembers?.filters;
  const activeMembersCount = activeMembers?.data?.explain?.Count || 0;

  const getActiveMembersList = async () => {
    const { startMonthString, endMonthString } = getStatsMonth({ startMonth, endMonth });
    dispatch(
      StatsActions.getActiveMembers({
        companyId: activeCompany?.companyId,
        startMonth: startMonthString + `.${startYear}`,
        endMonth: endMonthString + `.${endYear}`,
      }),
    );
  };

  const updateFiltersHandler = (filters: any): void => {
    dispatch(StatsActions.updateActiveMembersFilters(filters));
  };

  const updateValuesHandler = (values: any): void => {
    dispatch(StatsActions.getActiveMembers(values));
  };

  useEffect(() => {
    getActiveMembersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ActiveMembersWrapper>
      <Box className="container">
        <StatsInfo
          data={activeMembers?.data?.activeMembersList}
          count={activeMembersCount}
          filters={activeMembers.filters}
          fileName="active-members"
          className="green"
          isLoading={activeMembers.isLoading}
          updateValues={updateValuesHandler}
          updateFilters={updateFiltersHandler}
          hasError={activeMembers.fetchingStatus === FetchingStatus.REJECTED}
        />
        <Box className="content">
          <MembersTable />
        </Box>
      </Box>
    </ActiveMembersWrapper>
  );
};

export default ActiveMembersPage;
