import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, SettingsState } from 'types';
import { deleteCompanyCustomField } from './deleteCompanyCustomField';

export const deleteCompanyCustomFieldExtraReducer = (
  builder: ActionReducerMapBuilder<SettingsState>,
) => {
  builder.addCase(deleteCompanyCustomField.pending, (state, action) => {
    const companyId = action?.meta?.arg?.companyId;
    if (typeof companyId === 'number') {
      state.companyCustomField[companyId].fetchingStatus = FetchingStatus.PENDING;
    }
  });
  builder.addCase(deleteCompanyCustomField.fulfilled, (state, action) => {
    const companyId = action?.meta?.arg?.companyId;
    if (typeof companyId === 'number') {
      console.log(action.payload);
      // const oldData = [...(state.companyCustomField[companyId].data || [])];
      // oldData.push(action.payload);
      state.companyCustomField[companyId].fetchingStatus = FetchingStatus.FULFILLED;
      state.companyCustomField[companyId].isRemoved = true;
      //state.companyCustomField[companyId].data = oldData;
    }
  });
  builder.addCase(deleteCompanyCustomField.rejected, (state, action) => {
    const companyId = action?.meta?.arg?.companyId;
    if (typeof companyId === 'number') {
      state.companyCustomField[companyId].fetchingStatus = FetchingStatus.REJECTED;
    }
  });
};
