import React, { useEffect, useMemo, useState } from 'react';
import SyncIcon from '@mui/icons-material/Sync';
import AddEmployeeModal from 'features/employeeManagement/components/EmployeeOverview/Modals/AddEmployeeModal';
import SuccessInvitationModal from 'features/employeeManagement/components/EmployeeOverview/Modals/SuccessInvitationModal';
import { EmployeeManagementActions } from 'features/employeeManagement/store';
import { useAppDispatch, useAppSelector } from 'store';
import { EmployeeManagementSelectors } from '../../store/employeeManagement.selectors';
import { BULK_IMPORT_STEPS, FetchingStatus, ListType } from 'types';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { CCPDefaultButton, CCPGrayButton, Spacer } from 'components';
import {
  Container,
  HeaderContainer,
  HeaderWrapper,
  UploadInProgress,
} from './EmployeeOverview.styles';
import { Link, useNavigate } from 'react-router-dom';
import EmployeeTable from '../../components/EmployeeOverview/EmployeeTable';
import { Auth } from 'aws-amplify';
import { S3CredentialsActions } from 'features/shared/s3Credentials/store';
import { Box } from '@mui/system';
import { useIntl } from 'react-intl';
import { Stack, Typography } from '@mui/material';
import { CompanyActions } from 'features/company/store';
import { useLanguage } from 'hooks';
import { SettingsActions } from 'features/settings/store';
import CompanyCustomFieldModal from 'features/employeeManagement/pages/EmployeeBulkUpload/CompanyCustomFieldModal';
import { useCookies } from 'react-cookie';
import { FormatDate } from 'components/FormatDate';
import { checkTypeParameter } from 'utils/contractDependencies';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import GoToSettings from 'components/Modal/GoToSettings';
import GetSignupLinkModal from 'features/employeeManagement/components/EmployeeOverview/Modals/GetSignupLinkModal';
import { default as theme } from '@theme/theme';

const aDayTS = 86400;
const currentTimestamp = +new Date();

const EmployeeOverviewPage: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const activeLanguage = useLanguage();
  const dispatch = useAppDispatch();
  const { data: employees, fetchingStatus } = useAppSelector(
    EmployeeManagementSelectors.getGetEmployeesApi,
  );
  const [userId, setUserId] = useState('');
  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const companyApi = useAppSelector(CompanySelectors.getApi);
  const company = companyApi.getCompany;
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const settingsSettings = useAppSelector(SettingsSelectors.settingsSettings);
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const activationLetterDistributionListFetchingStatus = useAppSelector(
    SettingsSelectors.activationLetterDistributionListFetchingStatus,
  );
  const step = useAppSelector(EmployeeManagementSelectors.currentStep);
  const bulkUploadFiles = useAppSelector(EmployeeManagementSelectors.getBulkUploadFiles);
  const pendingEmployeesList = employees?.filter((employee: any) => employee.status === 'applied');
  const [showCustomFieldModal, setShowCustomFieldModal] = useState<boolean>(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [needsToFillAL, setNeedsToFillAL] = useState<boolean>(false);
  const isDirectPay = useAppSelector(store => store.settings.selfRegistration.directPay);
  const [cookies] = useCookies(['hideCustomerField']);
  const hideCustomerField = cookies.hideCustomerField || {};

  const isHansefitUpload = Boolean(bulkUploadFiles?.data?.hansefitBulkUpload);
  const hasBulkUpload =
    bulkUploadFiles.fetchingStatus === FetchingStatus.FULFILLED &&
    (bulkUploadFiles.data.companyBulkUpload || bulkUploadFiles.data.hansefitBulkUpload);

  const getCompanyCustomFieldList = async () => {
    if (!activeCompany?.companyId) {
      return;
    }
    const companyCustomFieldData = await dispatch(
      SettingsActions.getCompanyCustomField({
        companyId: activeCompany?.companyId,
      }),
    ).unwrap();

    if (
      companyCustomFieldData &&
      !companyCustomFieldData.length &&
      !showCustomFieldModal &&
      !hideCustomerField[activeCompany.companyId]
    ) {
      setShowCustomFieldModal(true);
    }
  };

  useEffect(() => {
    if (
      activeCompany?.companyId &&
      ((company.fetchingStatus === FetchingStatus.FULFILLED &&
        company?.data?.id !== activeCompany.companyId) ||
        company.fetchingStatus === FetchingStatus.IDLE)
    ) {
      dispatch(CompanyActions.getCompany({ companyId: activeCompany.companyId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany?.companyId, dispatch]);

  useEffect(() => {
    if (activeCompany?.companyId) {
      const companyId = activeCompany.companyId;
      dispatch(EmployeeManagementActions.getEmployees({ companyId }));
      dispatch(EmployeeManagementActions.getBulkUploadFile({ companyId }));
      getCompanyCustomFieldList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany?.companyId]);

  useEffect(() => {
    if (
      bulkUploadFiles.data &&
      bulkUploadFiles.data.companyBulkUpload &&
      bulkUploadFiles.data.companyBulkUpload.filePath &&
      bulkUploadFiles.fetchingStatus === FetchingStatus.FULFILLED &&
      activeCompany?.companyId &&
      step !== BULK_IMPORT_STEPS.REVIEW_STEP
    ) {
      dispatch(EmployeeManagementActions.setCurrentStep(BULK_IMPORT_STEPS.REVIEW_STEP));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkUploadFiles.data, activeCompany?.companyId]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setUserId(user.username);
      })
      .catch(err => console.log(err));

    dispatch(SettingsActions.getRegistrationSettings({}));
    if (activeCompany) {
      const { companyId } = activeCompany;

      dispatch(SettingsActions.getSettings({ companyId }));
      dispatch(
        SettingsActions.getDistributionList({ companyId, listType: ListType.ACTIVATION_LETTER }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      settingsSettings &&
      activationLetterDistributionListFetchingStatus === FetchingStatus.FULFILLED
    ) {
      setNeedsToFillAL(
        settingsSettings.activationLetterRecipientMode.toLowerCase() === 'cu' &&
          activationLetterDistributionList.length === 0,
      );
    }
  }, [
    activationLetterDistributionList,
    activationLetterDistributionListFetchingStatus,
    settingsSettings,
    settingsSettings.activationLetterRecipientMode,
  ]);

  useEffect(() => {
    if (
      step === BULK_IMPORT_STEPS.REVIEW_STEP &&
      bulkUploadFiles.data &&
      bulkUploadFiles.data.companyBulkUpload &&
      bulkUploadFiles.data.companyBulkUpload.lastModifiedAtTS - currentTimestamp > aDayTS
    ) {
      dispatch(EmployeeManagementActions.resetBulkImportCsvFile());
      dispatch(EmployeeManagementActions.setCurrentStep(BULK_IMPORT_STEPS.UPLOAD_STEP));
    }
  }, [dispatch, step, bulkUploadFiles.data]);

  const onCancelImportProgress = () => {
    if (
      activeCompany?.companyId &&
      bulkUploadFiles.data &&
      bulkUploadFiles.data.companyBulkUpload
    ) {
      dispatch(
        EmployeeManagementActions.cancelImportProgress({
          companyId: activeCompany?.companyId,
          confirmationId: bulkUploadFiles.data.companyBulkUpload?.confirmationId,
        }),
      );
      dispatch(EmployeeManagementActions.resetBulkImportCsvFile());
      dispatch(S3CredentialsActions.resetGetS3CredentialsState());
      dispatch(EmployeeManagementActions.setCurrentStep(BULK_IMPORT_STEPS.UPLOAD_STEP));
    }
  };

  const goToBulkUpload = () => {
    if (!checkActivationLettersSettingsIsCorrect()) {
      return;
    }
    navigate(`/${activeLanguage}/employee-management/bulk-upload`);
  };

  const checkActivationLettersSettingsIsCorrect = () => {
    if (needsToFillAL) {
      setShowSettingsModal(true);
      return false;
    }
    return true;
  };

  const canCreateAndEdit = useMemo(() => {
    if (isDirectPay) {
      return false;
    }
    return activeContract && checkTypeParameter(activeContract, 'createEditEUPossible');
  }, [isDirectPay, activeContract]);

  return (
    <Container>
      <HeaderContainer>
        <Typography data-test-id='pageTitle' variant='h2'>
          {intl.formatMessage({
            id: 'employee_management.overview.title',
            defaultMessage: 'Employees',
          })}
        </Typography>
        {isDirectPay && <GetSignupLinkModal />}
        {canCreateAndEdit && (
          <HeaderWrapper>
            <Stack direction='row' justifyContent='flex-end' spacing={2}>
              {step === BULK_IMPORT_STEPS.UPLOAD_IN_PROGRESS ? (
                <CCPGrayButton
                  variant='outlined'
                  sx={{ marginRight: '10px' }}
                  data-test-id='uploadIsInProgressButton'>
                  {intl.formatMessage({
                    id: 'employee_management.overview.upload.progress',
                    defaultMessage: 'Upload in progress',
                  })}
                  &nbsp; <SyncIcon />
                </CCPGrayButton>
              ) : step === BULK_IMPORT_STEPS.IMPORT_IN_PROGRESS || isHansefitUpload ? (
                bulkUploadFiles.data &&
                bulkUploadFiles.data.companyBulkUpload &&
                userId === bulkUploadFiles.data.companyBulkUpload.owner ? (
                  <CCPGrayButton
                    disabled={isHansefitUpload}
                    sx={{ marginRight: '10px' }}
                    variant='outlined'
                    onClick={onCancelImportProgress}
                    data-test-id='importIsInProgressButton'>
                    {intl.formatMessage({
                      id: 'employee_management.overview.import.progress',
                      defaultMessage: 'Import in progress',
                    })}
                    &nbsp; <SyncIcon />
                  </CCPGrayButton>
                ) : (
                  <CCPGrayButton
                    sx={{ marginRight: '10px' }}
                    variant='outlined'
                    disabled={isHansefitUpload}
                    data-test-id='importIsInProgressButton'>
                    {intl.formatMessage({
                      id: 'employee_management.overview.import.progress',
                      defaultMessage: 'Import in progress',
                    })}
                    &nbsp; <SyncIcon />
                  </CCPGrayButton>
                )
              ) : step === BULK_IMPORT_STEPS.REVIEW_STEP ? (
                <CCPDefaultButton
                  variant='outlined'
                  onClick={goToBulkUpload}
                  data-test-id='importReviewButton'>
                  {intl.formatMessage({
                    id: 'employee_management.overview.cta.review',
                    defaultMessage: 'Review import',
                  })}
                </CCPDefaultButton>
              ) : (
                <CCPDefaultButton
                  variant='outlined'
                  onClick={goToBulkUpload}
                  data-test-id='importEmployeesButton'>
                  {intl.formatMessage({
                    id: 'employee_management.overview.cta.import',
                    defaultMessage: 'Import employees',
                  })}
                </CCPDefaultButton>
              )}
              <AddEmployeeModal
                checkActivationLettersSettingsIsCorrect={checkActivationLettersSettingsIsCorrect}
              />
            </Stack>
            <GoToSettings
              isUserCreate
              isOpen={showSettingsModal}
              handleClose={() => setShowSettingsModal(false)}
              data-test-id='goToSettingsButton'
            />
          </HeaderWrapper>
        )}
        <SuccessInvitationModal />
      </HeaderContainer>
      {isHansefitUpload && (
        <UploadInProgress>
          <Box display='flex'>
            <Box
              bgcolor={theme.palette.primary.main}
              color={theme.palette.neutralLight.light}
              borderRadius='4px'
              padding='2px 5px'
              mr={2}>
              <Typography className='hansefitText'> HANSEFIT</Typography>
            </Box>
            <Box>
              <Typography className='textProgress'>
                {intl.formatMessage({
                  id: 'employee_management.overview.import.progress',
                  defaultMessage: 'Import in progress',
                })}
                :{' '}
                <span>
                  {intl.formatMessage({
                    id: 'employee_management.overview.import.progress.detail',
                    defaultMessage:
                      'it might takes some time. We will notify you once it’s completed',
                  })}
                </span>
              </Typography>
            </Box>
          </Box>
        </UploadInProgress>
      )}
      {step === BULK_IMPORT_STEPS.IMPORT_IN_PROGRESS && !isHansefitUpload && (
        <UploadInProgress>
          <div>
            <b>
              {intl.formatMessage({
                id: 'employee_management.overview.upload.progress',
                defaultMessage: 'Upload in progress',
              })}
              :
            </b>{' '}
            {intl.formatMessage({
              id: 'employee_management.overview.upload.progress.notify',
              defaultMessage: 'It might take some time. We will notify you once it’s completed.',
            })}
          </div>
          {bulkUploadFiles.data &&
            bulkUploadFiles.data.companyBulkUpload &&
            bulkUploadFiles.data.companyBulkUpload?.lastModifiedAtTS && (
              <div className='started-date'>
                {intl.formatMessage(
                  {
                    id: 'employee_management.overview.stored_time.label',
                    defaultMessage: 'Your import is temporarily stored until {date}',
                  },
                  {
                    date: FormatDate(
                      bulkUploadFiles.data.companyBulkUpload!.lastModifiedAtTS! * 1000 +
                        aDayTS * 1000,
                      bulkUploadFiles.data.companyBulkUpload!.lastModifiedAtTS! * 1000 +
                        aDayTS * 1000,
                    ),
                  },
                )}
              </div>
            )}
        </UploadInProgress>
      )}
      {step === BULK_IMPORT_STEPS.REVIEW_STEP && (
        <UploadInProgress>
          <div>
            <b>
              {intl.formatMessage({
                id: 'employee_management.overview.review.label',
                defaultMessage: 'Review import',
              })}
              :
            </b>{' '}
            {intl.formatMessage({
              id: 'employee_management.overview.review.content',
              defaultMessage: 'Please review and apply the imported employees.',
            })}
          </div>
          {bulkUploadFiles.data &&
            bulkUploadFiles.data.companyBulkUpload &&
            bulkUploadFiles.data.companyBulkUpload?.lastModifiedAtTS && (
              <div className='started-date'>
                {intl.formatMessage(
                  {
                    id: 'employee_management.overview.stored_time.label',
                    defaultMessage: 'Your import is temporarily stored until {date}',
                  },
                  {
                    date: FormatDate(
                      bulkUploadFiles.data.companyBulkUpload!.lastModifiedAtTS! * 1000 +
                        aDayTS * 1000,
                      bulkUploadFiles.data.companyBulkUpload!.lastModifiedAtTS! * 1000 +
                        aDayTS * 1000,
                    ),
                  },
                )}
                <Link
                  to={`/${activeLanguage}/employee-management/bulk-upload`}
                  className='btn-import'>
                  <Box display='inline-block' ml={2}>
                    {intl.formatMessage({
                      id: 'employee_management.overview.review.label',
                      defaultMessage: 'Review import',
                    })}
                  </Box>
                </Link>
              </div>
            )}
        </UploadInProgress>
      )}
      {!!pendingEmployeesList?.length && (
        <Box className='pending'>
          <Typography className='pending__text' variant='body1'>
            <strong>
              {intl.formatMessage({
                id: 'employee_management.overview.pending.title',
                defaultMessage: 'Pending requests:',
              })}
            </strong>{' '}
            {intl.formatMessage(
              {
                id: 'employee_management.overview.pending',
                defaultMessage: 'you have {amount} pending requests',
              },
              { amount: pendingEmployeesList.length },
            )}
          </Typography>
          <Link
            className='pending__link'
            to={`/${activeLanguage}/employee-management/pending-requests`}>
            {intl.formatMessage({
              id: 'employee_management.overview.pending.view',
              defaultMessage: 'View requests',
            })}
          </Link>
        </Box>
      )}
      <Spacer size='lg' />
      <EmployeeTable isDirectPay={isDirectPay} />
      {showCustomFieldModal &&
        fetchingStatus === FetchingStatus.FULFILLED &&
        bulkUploadFiles.fetchingStatus === FetchingStatus.FULFILLED &&
        !employees?.length &&
        !hasBulkUpload && (
          <CompanyCustomFieldModal
            open={showCustomFieldModal}
            handleClose={() => setShowCustomFieldModal(false)}
            activeCompanyId={activeCompany?.companyId}
          />
        )}
    </Container>
  );
};

export default EmployeeOverviewPage;
