import { styled, Box } from '@mui/material';

export const MonthPickerWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',

  '.header': {
    background: theme.palette.neutralLight.light,
    outline: `1px solid  ${theme.palette.neutralLight.dark}`,
    borderRadius: 4,
    padding: '8px 7px 8px 14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    userSelect: 'none',

    '&__title': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0.15,
      color: theme.palette.neutralDark.contrastText,
      marginRight: 1,
    },

    '&__arrow': {
      transition: 'all 0.3s ease-in',
    },
  },

  '.content': {
    top: 48,
    position: 'absolute',
    borderRadius: 4,
    boxShadow: `0 5px 6px -3px ${theme.palette.transparent.boxShadowDark01}, 0 3px 8px 2px ${theme.palette.transparent.boxShadowDark02}`,
    background: theme.palette.neutralLight.light,
    zIndex: 2,

    '&__header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `2px solid ${theme.palette.primaryLight.main}`,
      padding: 16,

      '&_item': {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },

    '&__wrapper': {
      gap: 16,
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      padding: '0 16px',
      borderBottom: `2px solid ${theme.palette.primaryLight.main}`,

      '&_divider': {
        width: 2,
        flex: 1,
        background: theme.palette.primaryLight.main,
      },
    },

    '&__bottom': {
      gap: 16,
      padding: '9px 16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
}));

export const MonthRangeWrapper = styled(Box)(({ theme }) => ({
  padding: '16px 0',

  '.top': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&__wrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      paddingLeft: 17,
    },

    '&_text': {
      fontFamily: 'Roboto-Bold',
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: '22px',
      color: theme.palette.neutralDark.contrastText,
    },

    svg: {
      cursor: 'pointer',
      transition: 'all 0.3s ease-in',

      '&:first-child': {
        transform: 'rotate(180deg)',
      },
    },
  },

  '.list': {
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(3,1fr)',
    margin: '16px 0 0',

    '&_item': {
      padding: '1px 0',
      textTransform: 'unset',
      borderRadius: 12,
      boxShadow: 'none !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      fontSize: 16,
      lineHeight: '22px',
      color: theme.palette.neutralDark.contrastText,

      '&_active': {
        color: theme.palette.primaryLight.main,
      },
    },
  },
}));
