import { useIntl } from 'react-intl';
import clsx from 'clsx';
import {
  Container,
  WarningIcon,
  ErrorTitle,
  ErrorDescription,
  TextContainer,
  InfoIcon,
} from './ModalError.styles';

const ModalError: React.FC<{
  headerErrorMessage?: string;
  descriptionErrorMessage?: string;
  isErrorTitle?: boolean;
  errorContainerClassName?: string;
  isInfo?: boolean;
}> = ({
  headerErrorMessage = 'Error title',
  descriptionErrorMessage = 'Error description',
  isErrorTitle = true,
  errorContainerClassName,
  isInfo = false,
}) => {
  const intl = useIntl();
  return (
    <>
      <Container className={clsx(errorContainerClassName)}>
        {isInfo ? <InfoIcon /> : <WarningIcon />}
        <TextContainer>
          {isErrorTitle && (
            <ErrorTitle>
              {headerErrorMessage ||
                intl.formatMessage({ id: 'popup.error.title', defaultMessage: 'Error title' })}
            </ErrorTitle>
          )}
          {descriptionErrorMessage && (
            <ErrorDescription>
              {descriptionErrorMessage ||
                intl.formatMessage({ id: 'popup.error.title', defaultMessage: 'Error title' })}
            </ErrorDescription>
          )}
        </TextContainer>
      </Container>
    </>
  );
};

export default ModalError;
