import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Table, Paper, TableRow, TableCell, Pagination, TableContainer } from '@mui/material';
import { StyledTableHead, StyledTableBody, StyledTablePagination } from './Table.styles';
import { useIntl } from 'react-intl';
import TableNoData from './TableNoData';

interface CusomTableProps {
  columnsList: { columnName: string; columnTitle: string; intlId: string }[];
  renderRow: (item: any) => ReactNode;
  listData: any[];
  additionalButton?: any;
  noDataText?: string;
  noDataHelperText?: string;
}

const CustomTable: React.FC<CusomTableProps> = ({
  columnsList,
  renderRow,
  listData,
  additionalButton,
  noDataText,
  noDataHelperText,
}) => {
  const intl = useIntl();
  const [page, setPage] = useState<number>(0);

  const rowsPerPage = 10;
  const offset = page * rowsPerPage;
  const limit = offset + rowsPerPage;
  const paginationListCount = Math.ceil(listData.length / rowsPerPage);

  useEffect(() => {
    setPage(0);
  }, [listData]);
  const renderRows = listData?.slice(offset, limit).map(item => renderRow(item));

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  const isRightAlign = useCallback(
    (index: number) => columnsList.length === 2 && index === 1,
    [columnsList],
  );

  return listData.length > 0 ? (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <StyledTableHead>
            <TableRow>
              {columnsList.map((item, index) => (
                <TableCell
                  className={`table_cell ${isRightAlign(index) ? 'right-align' : ''}`}
                  key={`${item.columnName}`}>
                  {item.intlId
                    ? intl.formatMessage({
                        id: item.intlId,
                        defaultMessage: item.columnTitle,
                      })
                    : item.columnTitle}
                </TableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <StyledTableBody>{renderRows}</StyledTableBody>
        </Table>
      </TableContainer>
      <StyledTablePagination>
        {additionalButton}
        {paginationListCount > 1 && (
          <Pagination
            showFirstButton
            showLastButton
            page={page + 1}
            count={paginationListCount}
            onChange={handleChange}
            style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}
          />
        )}
      </StyledTablePagination>
    </>
  ) : (
    <TableNoData
      columnsList={columnsList}
      noDataText={noDataText}
      noDataHelperText={noDataHelperText}
      additionalButton={additionalButton}
    />
  );
};

export default CustomTable;
