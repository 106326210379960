import { useState } from 'react';
import { useIntl } from 'react-intl';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from 'store';
import { SettingsActions } from 'features/settings/store';
import { DeleteEmailModal, EmailDistributionModal } from 'components';
import { TableRow, Typography, TableCell, Stack } from '@mui/material';
import { CompanySelectors } from 'features/company/store/company.selectors';
import {
  ListEntries,
  EmailDistributionModalValuesProps,
  ListType,
  EditDistributionListRequestParams,
  DeleteDistributionListRequestParams,
} from 'types';
import { FormatDate } from 'components/FormatDate';
import { default as theme } from '@theme/theme';
import Loader from '@components/Loader';

export type InvoiceTable = {
  item: ListEntries;
};

const ExternalUserRow: React.FC<InvoiceTable> = ({ item }) => {
  const intl = useIntl();
  const { email, creationDate, description } = item;

  const dispatch = useAppDispatch();
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);

  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const editModalToggler = (): void => {
    setIsOpenEditModal(!isOpenEditModal);
  };

  const deleteModalToggler = (): void => {
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const updateTableRow = async (
    method: Function,
    params: EditDistributionListRequestParams | DeleteDistributionListRequestParams,
    modalToggler: Function,
  ) => {
    setIsLoading(true);
    await dispatch(method(params));
    modalToggler();
    setIsLoading(false);
  };

  const submitEditModalHandler = (data: EmailDistributionModalValuesProps): void => {
    updateTableRow(
      SettingsActions.editRecordInDistributionList,
      {
        companyId: activeCompany?.companyId,
        email: data?.email,
        oldEmail: email,
        description: data?.description,
        listType: ListType.INVOICES,
      },
      editModalToggler,
    );
  };

  const submitDeleteModalHandler = (): void => {
    updateTableRow(
      SettingsActions.deleteRecordFromDistributionList,
      {
        companyId: activeCompany?.companyId,
        email,
        listType: ListType.INVOICES,
      },
      deleteModalToggler,
    );
  };

  return (
    <>
      <Loader show={isLoading} />
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>{email}</TableCell>
        <TableCell>{FormatDate(creationDate)}</TableCell>
        <TableCell>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <Typography variant='body1'>{description}</Typography>
            <Stack direction='row' spacing={2}>
              <Stack
                direction='row'
                spacing={1}
                onClick={editModalToggler}
                style={{ cursor: 'pointer' }}>
                <EditIcon htmlColor={theme.palette.neutralDark.main} fontSize='small' />
                <Typography variant='body1'>
                  {intl.formatMessage({
                    id: 'employee_management.bulk_upload.table.column.action.edit',
                    defaultMessage: 'Edit',
                  })}
                </Typography>
              </Stack>
              <Stack
                direction='row'
                spacing={1}
                onClick={deleteModalToggler}
                style={{ cursor: 'pointer' }}>
                <DeleteIcon htmlColor={theme.palette.neutralDark.main} fontSize='small' />
                <Typography variant='body1'>
                  {intl.formatMessage({
                    id: 'employee_management.bulk_upload.table.column.action.delete',
                    defaultMessage: 'Delete',
                  })}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>
      <EmailDistributionModal
        isEditPopup
        isOpen={isOpenEditModal}
        onSubmit={submitEditModalHandler}
        closeModal={editModalToggler}
        email={email}
        description={description}
      />
      <DeleteEmailModal
        email={email}
        isOpen={isOpenDeleteModal}
        onSubmit={submitDeleteModalHandler}
        closeModal={deleteModalToggler}
      />
    </>
  );
};

export default ExternalUserRow;
