import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper, Table, TableRow, TableCell, TableContainer, Pagination } from '@mui/material';

import { FetchingStatus, RootState } from 'types';

import TableLoader from '../TableLoader';
import TableCellRow from './TableCellRow';
import TableEmptyData from '../TableEmptyData';
import { StyledTableHead, StyledTableBody, PaginationWrapper } from './MembersTable.styles';
import { useIntl } from 'react-intl';
import { TableErrorData } from '../TableErrorData.ts';
import { useLanguage } from 'hooks';
import { useAppSelector } from 'store';

const MembersTable = () => {
  const intl = useIntl();
  const activeLanguage = useLanguage();
  const { activeMembers } = useAppSelector(state => state.stats);
  const [page, setPage] = useState<number>(0);
  const activeMembersList = activeMembers.data!?.activeMembersList;
  const hasError = activeMembers.fetchingStatus === FetchingStatus.REJECTED;

  const rowsPerPage = 10;
  const offset = page * rowsPerPage;
  const limit = offset + rowsPerPage;
  const paginationListCount = Math.ceil(activeMembersList?.length / rowsPerPage);
  const calculatePatinationResults =
    limit + 1 >= activeMembersList?.length ? activeMembersList?.length : limit;

  useEffect(() => {
    setPage(0);
  }, [activeMembers]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  if (activeMembers.isLoading) {
    return <TableLoader />;
  }

  if (hasError) {
    return <TableErrorData />;
  }

  return activeMembersList?.length ? (
    <>
      <TableContainer className="coupon_table" component={Paper} elevation={0}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell className="table_cell">
                {intl.formatMessage({ id: 'view.stats.table.id', defaultMessage: 'ID' })}
              </TableCell>
              <TableCell className="table_cell">
                {intl.formatMessage({
                  id: 'view.stats.table.member_name',
                  defaultMessage: 'Member Full name',
                })}
              </TableCell>
              <TableCell className="table_cell">
                {' '}
                {intl.formatMessage({
                  id: 'view.stats.table.registration_date',
                  defaultMessage: 'Registration date',
                })}
              </TableCell>
            </TableRow>
          </StyledTableHead>
          <StyledTableBody>
            {activeMembersList?.slice(offset, limit).map((item, index) => (
              <TableCellRow
                key={`${item}-${index}`}
                data={item}
                borderEnd={page + 1 === paginationListCount}
                activeLanguage={activeLanguage}
              />
            ))}
          </StyledTableBody>
        </Table>
      </TableContainer>
      <PaginationWrapper>
        <Box className="results">
          {intl.formatMessage(
            {
              id: 'table.pagination.result',
              defaultMessage: '{first}-{last} from Results {result}',
            },
            {
              first: offset + 1,
              last: calculatePatinationResults,
              result: activeMembersList?.length,
            },
          )}
        </Box>
        <Pagination
          showFirstButton
          showLastButton
          page={page + 1}
          count={paginationListCount}
          onChange={handleChange}
        />
      </PaginationWrapper>
    </>
  ) : (
    <>
      <TableContainer className="coupon_table" component={Paper} elevation={0}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell className="table_cell">
                {intl.formatMessage({ id: 'view.stats.table.id', defaultMessage: 'ID' })}
              </TableCell>
              <TableCell className="table_cell">
                {intl.formatMessage({
                  id: 'view.stats.table.member_name',
                  defaultMessage: 'Member Full name',
                })}
              </TableCell>
              <TableCell className="table_cell">
                {' '}
                {intl.formatMessage({
                  id: 'view.stats.table.registration_date',
                  defaultMessage: 'Registration date',
                })}
              </TableCell>
            </TableRow>
          </StyledTableHead>
        </Table>
      </TableContainer>
      <TableEmptyData />
    </>
  );
};

export default MembersTable;
