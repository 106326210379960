import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import basic_icon from 'assets/icons/hf-basic.svg';
import pro_icon from 'assets/icons/hf-pro.svg';
import best_icon from 'assets/icons/hf-best.svg';
import disabled_icon from 'assets/icons/hf-disabled.svg';
import { useLanguage } from '@hooks/useLanguage';
import { TranslationsKeys } from '@localTypes/user';
import { useMemo } from 'react';
import Spacer from '@components/Spacer';

const ContractProduct = ({ products, isDisabled }: any) => {
  const activeLanguage = useLanguage();

  const anotherTranslation: TranslationsKeys = useMemo(
    () => (activeLanguage === 'de' ? 'en' : 'de'),
    [activeLanguage],
  );

  const checkProductType = (product: string) => {
    if (!product) {
      return null;
    }
    const productType = product.toLocaleLowerCase();
    if (productType.includes('basic')) {
      return <img src={basic_icon} alt='' />;
    }
    if (productType.includes('pro')) {
      return <img src={pro_icon} alt='' />;
    }
    if (productType.includes('best')) {
      return <img src={best_icon} alt='' />;
    }
    return <img src={pro_icon} alt='' />;
  };

  return products?.map((product: any, index: number) => (
    <Box key={index} display='flex' flexDirection='column' alignItems='flex-start'>
      <Box
        mr={2.5}
        display='flex'
        className={clsx('productChip', 'productChipWithIcon', {
          expired: isDisabled,
        })}>
        {isDisabled ? <img src={disabled_icon} alt='' /> : checkProductType(product.name)}
        {product.name}
      </Box>
      <Spacer size='md' />
      <Typography variant='caption'>
        {product.localizedFields[activeLanguage]?.description ||
          product.localizedFields[anotherTranslation]?.description ||
          ''}
      </Typography>
    </Box>
  ));
};

export default ContractProduct;
