import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import { useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useUserSettingsTabs } from 'utils';

import SettingContent from './SettingContent';
import { SettingsWrapper } from './Settings.styles';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store';
import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import { CompanyRole, TRoles } from 'types';
import { SettingsActions } from 'features/settings/store';
import Spacer from '@components/Spacer';

const Settings = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { activeTab, language } = useParams();
  const { userInfo } = useAppSelector(AuthenticationSelectors.getAuthentication);
  const userRoles = userInfo?.role || [];
  const onlyUserMngerRole = userRoles.length === 1 && userRoles?.includes(CompanyRole.Usermgmt);

  const autoConfirmSignup = useAppSelector(
    state => state.settings.defaultSelfRegistration.autoConfirmSignup,
  );
  const dispatch = useAppDispatch();

  const changeActiveTab = useCallback(
    (newTab: number): void => {
      navigate(`/${language}/settings/${newTab}`);
    },
    [navigate, language],
  );

  useEffect(() => {
    if (onlyUserMngerRole && activeTab === '0') changeActiveTab(1);
  }, [changeActiveTab, onlyUserMngerRole, activeTab]);

  useEffect(() => {
    dispatch(SettingsActions.getRegistrationSettings({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isShowTab = (tab: { value: string; label: string; role: TRoles }) =>
    (tab.value !== 'onboarding_emails' &&
      tab.value !== 'Company custom field' &&
      tab.role.filter(tabRole => userInfo?.role.includes(tabRole)).length !== 0) ||
    (tab.value === 'onboarding_emails' &&
      !autoConfirmSignup &&
      tab.role.filter(tabRole => userInfo?.role.includes(tabRole)).length !== 0) ||
    (tab.value === 'Company custom field' &&
      (!!userInfo?.role.includes(CompanyRole.Usermgmt) ||
        !!userInfo?.role.includes(CompanyRole.Admin)));

  const userSettingsTabs = useUserSettingsTabs();
  const renderAccountTabs = userSettingsTabs.map((tab, index) => (
    <Box
      hidden={!isShowTab(tab)}
      onClick={() => changeActiveTab(index)}
      className={clsx('wrapper__sidebar_item', {
        wrapper__sidebar_item_active: Number(activeTab) === index,
      })}
      key={tab.value}>
      {tab.label}
    </Box>
  ));

  if (renderAccountTabs.length < Number(activeTab)) changeActiveTab(0);

  return (
    <SettingsWrapper>
      <Typography variant='h2'>
        {intl.formatMessage({ id: 'navigation.settings', defaultMessage: 'Settings' })}
      </Typography>
      <Spacer size='lg' />
      <Box className='wrapper'>
        <Box className='wrapper__sidebar'>
          <Box>{renderAccountTabs}</Box>
        </Box>
        {userInfo?.role && (
          <SettingContent
            activeTab={Number(activeTab)}
            userRoles={userInfo?.role}
            isShowTab={isShowTab}
          />
        )}
      </Box>
    </SettingsWrapper>
  );
};

export default Settings;
