import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, TextField } from '@mui/material';

import { AddEmailSchema } from 'shared/schemas';
import { useAppDispatch } from 'store';
import { ResetWithEmailProps } from 'types';
import { AuthenticationActions } from 'features/authentication/store';
import { useIntl } from 'react-intl';
import { CCPDefaultButton, Spacer } from '@components/index';
import { default as theme } from '@theme/theme';

const ResetWithEmail: React.FC<ResetWithEmailProps> = ({ changeEmail }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(AddEmailSchema),
  });

  const onSubmit = (data: any) => {
    changeEmail(data.email);
    dispatch(
      AuthenticationActions.forgotPassword({
        email: data.email,
      }),
    );
  };

  const navigateToLoginHandler = () => {
    navigate('/de/login');
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} className='wrapper'>
      <Box>
        <Typography variant='h2'>
          {intl.formatMessage({
            id: 'reset_password_code.title.reset_password',
            defaultMessage: 'Reset password',
          })}
        </Typography>
        <Spacer size='lg' />
        <Typography variant='subtitle1'>
          {intl.formatMessage({
            id: 'reset_password_code.subtitle.email',
            defaultMessage: 'Please enter your email address to reset the password.',
          })}
        </Typography>
        <Spacer size='xl' />
        <TextField
          className='wrapper__field'
          variant='outlined'
          label='Email'
          {...register('email')}
          error={!!errors.email}
        />
        <Typography variant='inherit' color={theme.palette.error.main}>
          {/* {errors.email?.message} */}
        </Typography>
      </Box>
      <Spacer size='3xl' />
      <Box className='wrapper__footer'>
        <CCPDefaultButton onClick={navigateToLoginHandler} variant='outlined'>
          {intl.formatMessage({
            id: 'reset_password_code.body.cta.cancel',
            defaultMessage: 'Cancel',
          })}
        </CCPDefaultButton>
        <CCPDefaultButton disabled={!isValid} color='primary' type='submit' variant='contained'>
          {intl.formatMessage({
            id: 'reset_password_code.body.cta.continue',
            defaultMessage: 'Continue',
          })}
        </CCPDefaultButton>
      </Box>
    </Box>
  );
};

export default ResetWithEmail;
