import { styled, Box } from '@mui/material';

export const ResetPasswordWrapper = styled(Box)(({ theme }) => ({
  width: '100%',

  '.wrapper': {
    background: theme.palette.neutralLight.light,
    borderRadius: 4,
    padding: '48px 48px 36px',
    maxWidth: 620,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 auto 80px',

    '&__field': {
      width: '100%',
    },

    '&__footer': {
      display: 'flex',
      gap: 16,
      width: '100%',
      justifyContent: 'flex-end',
    },
  },

  '.verify': {
    alignItems: 'center',
    justifyContent: 'flex-start',

    '&__subtitle': {
      textAlign: 'center',
    },

    '&__text': {
      textAlign: 'center',

      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
    },

    '&__link': {
      width: 'fit-content',
      cursor: 'pointer',
      textAlign: 'center',
      margin: '0 auto',

      p: {
        fontFamily: 'Roboto-Medium',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 1.38,
        color: theme.palette.primary.main,
        margin: '8px 0 24px',
      },
    },

    '&__divider': {
      marginBottom: 24,
    },

    '&__field': {
      gap: 30,
      display: 'flex',
      flexDirection: 'column',
      margin: '32px 0 46px',
    },

    '&__code': {
      gap: 10,
      margin: '14px 0 16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&_item': {
        width: '56px !important',
        height: 56,

        fontSize: 16,
        lineHeight: 1.38,
        color: theme.palette.neutralDark.contrastText,
        border: `1px solid  ${theme.palette.neutralLight.dark}`,
        userSelect: 'none',
        borderRadius: 4,

        '&_active': {
          border: `1px solid ${theme.palette.success.main}`,
          transition: 'border 0.4s ease',
        },

        '&:focus': {
          outline: 'none',
        },
      },
    },

    '&__footer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
}));
