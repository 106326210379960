import { Chip, styled } from '@mui/material';
import { StatusChipProps } from './interfaces';

export const StatusChip = styled(Chip)<StatusChipProps>(({ background, theme }) => ({
  background,
  borderRadius: 4,
  padding: '2px 8px',
  fontFamily: 'Roboto-Medium',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 1.29,
  color: theme.palette.neutralLight.light,
  textTransform: 'uppercase',
  height: 'auto',
  span: {
    padding: 0,
  },
  '&.isBlur': {
    opacity: 0.6,
  },
}));
