import { Box, styled } from '@mui/material';

export const EmployeeDetailsStyled = styled(Box)(({ theme }) => ({
  flexGrow: '1',
  '.rightSideHeader': {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 14,
    fontWeight: 900,
    lineHeight: 1.57,
    color: theme.palette.neutralDark.contrastText,
    textTransform: 'uppercase',
  },
  '.employeeName': {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.22,
    color: theme.palette.neutralDark.contrastText,
  },
  '.employeeStatus': {
    padding: '2px 8px',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.29,
    textAlign: 'right',
    color: theme.palette.neutralLight.light,
    opacity: '60%',
    textTransform: 'uppercase',
  },
  '.employeeUnderNameText': {
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.main,
  },
  '.employeeInfoHeader': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    color: theme.palette.neutralDark.main,
    textTransform: 'uppercase',
    fontFamily: 'Roboto-Medium',
  },
  '.infoColumn': {
    minWidth: 104,
    p: {
      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
    },
    '.infoData': {
      fontWeight: 700,
    },
  },
  '.productChip': {
    padding: '1px 12px',
    borderRadius: 11,
    border: `1px solid  ${theme.palette.neutralLight.dark}`,
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
  },
  '.gender': {
    textTransform: 'capitalize;',
  },
  '.customerField': {
    fontSize: 16,
  },
}));
