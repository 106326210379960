import { Box, styled } from '@mui/material';

export const EmployeeMembershipStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '.planned-transition-row': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    backgroundColor: theme.palette.primaryLight.dark,
  },

  '.membershipTitle': {
    minWidth: 80,
    marginRight: 10,
  },
  '.employeeName': {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.22,
    color: theme.palette.neutralDark.contrastText,
  },
  '.employeeStatus': {
    padding: '2px 8px',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.29,
    textAlign: 'right',
    color: theme.palette.neutralLight.light,
    textTransform: 'uppercase',
    backgroundColor: theme.palette.warning.main,
    '&.pausedStatus': {
      backgroundColor: theme.palette.warning.main,
    },
    '&.inactiveStatus': {
      backgroundColor: theme.palette.neutralDark.main,
    },
    '&.activeStatus': {
      backgroundColor: theme.palette.success.main,
    },
  },
  '.employeeUnderNameText': {
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.main,
  },
  '.employeeInfoHeader': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    color: theme.palette.neutralDark.main,
    textTransform: 'uppercase',
  },
  '.infoColumn': {
    minWidth: 104,
    p: {
      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
    },
    '.infoData': {
      fontWeight: 700,
    },
  },
  '.productChip': {
    padding: '1px 12px',
    borderRadius: 11,
    border: `1px solid  ${theme.palette.neutralLight.dark}`,
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
  },
  '& button': {
    letterSpacing: -1,
  },
}));
