import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

import { CustomToolbarStyled } from './CustomToolbar.styles';
import Search from './Search';
import ExportList from './ExportList';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store';
import { TablesManagementActions } from '../store';
import {
  gridFilterableColumnDefinitionsSelector,
  gridFilterModelSelector,
  gridPaginationSelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  ToolbarPropsOverrides,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { CompanySelectors } from 'features/company/store/company.selectors';
import BulkActions from './BulkActions';
import { CCPDefaultButton } from 'components';

const CustomToolbar = ({
  setOpenedPanelName,
  tableName,
  data,
  tableConfiguration,
  searchPlaceholder,
  showExportExcel = false,
  hasBulkActions = false,
  bulkActions,
  enableButton = false,
  bulkActionHandler,
  bulkActionsDisableButton = false,
  formatCell,
  rowsSelected = [],
  deselectAll,
  toolbarRef,
}: ToolbarPropsOverrides) => {
  const intl = useIntl();

  const dispatch = useAppDispatch();

  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);

  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const filterableColumns = useGridSelector(apiRef, gridFilterableColumnDefinitionsSelector);
  const { rowCount } = useGridSelector(apiRef, gridPaginationSelector);

  React.useEffect(() => {
    if (filterModel.items.length > 0 && data.length > 0) {
      filterModel.items.forEach(filterItem => {
        if (filterItem.value) {
          const filteredItem = filterableColumns.find(item => item.field === filterItem.field);
          if (
            filteredItem &&
            filteredItem.type === 'singleSelect' &&
            data.filter(
              row => row[filterItem.field].toLowerCase() === filterItem.value.toLowerCase(),
            ).length === 0
          ) {
            apiRef.current.deleteFilterItem(filterItem);
            apiRef?.current?.setPage(0);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleResetAll = useCallback(() => {
    //do something
    if (activeCompany && data) {
      dispatch(
        TablesManagementActions.resetTableConfiguration({
          tableName,
          company: {
            id: activeCompany.companyId,
            customFields: [],
          },
          tableData: data,
        }),
      );
    }
  }, [tableName, activeCompany, data, dispatch]);

  const showExportList = useMemo(() => tableName === 'employee', [tableName]);

  const showResetButton = () =>
    (tableConfiguration &&
      tableConfiguration.columns &&
      Object.keys(tableConfiguration.columns).some(item => !tableConfiguration.columns?.[item])) ||
    (tableConfiguration.filters?.items || []).filter(item => item.value?.length > 0).length > 0;

  return (
    <CustomToolbarStyled ref={toolbarRef}>
      <Stack className='toolbar-row' justifyContent='space-between'>
        <Stack flexDirection='row' flexGrow='1'>
          {hasBulkActions && (
            <BulkActions
              actionsList={bulkActions}
              onClick={bulkActionHandler}
              enableButton={enableButton}
              bulkActionsDisableButton={bulkActionsDisableButton}
            />
          )}
          <Box className='gridToolbarItems'>
            <GridToolbarColumnsButton
              slotProps={{
                button: {
                  onClick: () => setOpenedPanelName('gridToolbarColumnsButton'),
                  id: 'gridToolbarColumnsButton',
                  size: 'medium',
                  color: 'primary',
                  sx: { minWidth: 'auto', padding: '7px' },

                  startIcon:
                    tableConfiguration &&
                    tableConfiguration.columns &&
                    Object.keys(tableConfiguration.columns).some(
                      item => !tableConfiguration.columns?.[item],
                    ) ? (
                      <div className='column-badge'>
                        <ViewColumnIcon />
                      </div>
                    ) : (
                      <ViewColumnIcon />
                    ),
                },
              }}
            />

            <GridToolbarFilterButton
              slotProps={{
                button: {
                  onClick: () => setOpenedPanelName('gridToolbarFilterButton'),
                  id: 'gridToolbarFilterButton',
                  sx: { minWidth: 'auto', padding: '7px' },
                },
              }}
            />
            {showExportList && (
              <ExportList
                tableConfiguration={tableConfiguration}
                showExportExcel={showExportExcel}
                formatCell={formatCell}
              />
            )}

            {showResetButton() && (
              <>
                <Divider orientation='vertical' />
                <Button
                  variant='text'
                  className='gridToolbarBtn'
                  size='small'
                  onClick={handleResetAll}>
                  {intl.formatMessage({ id: 'table.toolbar.reset', defaultMessage: 'Reset all' })}
                </Button>
              </>
            )}
          </Box>
        </Stack>
        <Search<typeof data>
          tableConfiguration={tableConfiguration}
          tableName={tableName}
          searchPlaceholder={searchPlaceholder}
        />
      </Stack>
      {rowsSelected.length > 0 && (
        <Stack className='toolbar-row' justifyContent='space-between'>
          <Typography>
            {intl.formatMessage(
              {
                id: 'table.toolbar.selected_label',
                defaultMessage: '{selected} of {count} have been selected.',
              },
              { selected: <b>{rowsSelected.length}</b>, count: <b>{rowCount}</b> },
            )}
          </Typography>
          <CCPDefaultButton variant='outlined' onClick={deselectAll}>
            {intl.formatMessage({
              id: 'table.toolbar.deselect_button',
              defaultMessage: 'Deselect all',
            })}
          </CCPDefaultButton>
        </Stack>
      )}
    </CustomToolbarStyled>
  );
};

export default CustomToolbar;
