import { styled, TableHead, TableBody, TableContainer, Box } from '@mui/material';

export const TableContainerWrapper = styled(TableContainer)(({ theme }) => ({
  display: 'flex',
}));

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& .table_cell': {
    minWidth: 150,
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    lineHeight: 1.57,
    color: theme.palette.neutralDark.main,
    padding: 0,
    paddingBottom: 8,
    borderBottom: 'none',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  '& .table_cell_max': {
    minWidth: 200,
  },
  '& .hidden_cell': {
    opacity: 0,
  },
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  td: {
    padding: 0,
    paddingBottom: 30,

    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
    '&.expired': {
      color: theme.palette.neutralDark.main,
      '& .directPay': {
        color: theme.palette.neutralDark.main,
        borderColor: theme.palette.neutralDark.main,
      },
    },
    '& .directPay': {
      color: theme.palette.primary.main,
      fontFamily: 'Roboto-medium',
      textTransform: 'uppercase',
      fontWeight: 500,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 4,
      padding: '2px 5px',
      fontSize: 14,
    },
    '& .payWrapper': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

export const ShowAllDataWrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  cursor: 'pointer',
  marginTop: 40,
  color: theme.palette.primary.main,
  fontFamily: 'Roboto-bold',
  fontSize: 16,
  display: 'flex',
  margin: '0 auto',
}));
