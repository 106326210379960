import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { CompanyResponse } from 'types';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { TablesManagementState } from '../../interfaces';

export const setInitialTableConfiguration: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; company: CompanyResponse; tableData: any[] }>
> = (state, action) => {
  const { id, customFields } = action.payload.company;
  const tableData = action.payload.tableData;
  const columnsFromLocalStorage = sessionStorage.getItem(
    `ccp_${action.payload.tableName}_table_configuration_columns_${id}`,
  );
  const rowsPerPageFromLocalStorage = sessionStorage.getItem(
    `ccp_${action.payload.tableName}_table_configuration_rows_per_page_${id}`,
  );
  const filtersFromLocalStorage = sessionStorage.getItem(
    `ccp_${action.payload.tableName}_table_configuration_filters_${id}`,
  );

  let columns: { [key: string]: boolean } = {};
  let rowsPerPage: number = -1;
  let filters: any = {};

  if (rowsPerPageFromLocalStorage) {
    rowsPerPage = parseInt(rowsPerPageFromLocalStorage);
  } else {
    rowsPerPage = 25;
  }

  if (columnsFromLocalStorage) {
    columns = JSON.parse(columnsFromLocalStorage);
  } else if (!columnsFromLocalStorage && Object.keys(tableData).length > 0) {
    const initialColumns: { [key: string]: boolean } = {};
    if (tableData.length > 0) {
      const properties = Object.keys(tableData[0]);

      properties.forEach(property => {
        if (property !== 'customFields') {
          if (property === 'id') {
            initialColumns['userId'] = true;
          } else {
            initialColumns[property] = true;
          }
        }
      });

      customFields.forEach(customField => {
        initialColumns[customField.key] = true;
      });
    }

    columns = initialColumns;
  }

  if (filtersFromLocalStorage) {
    filters = JSON.parse(filtersFromLocalStorage);
  } else {
    filters = { items: [] };
  }
  state[action.payload.tableName]
    ? (state[action.payload.tableName].tableConfigurations[id] = {
        rowsPerPage,
        currentPage: 0,
        filters,
        columns,
        searchData: { hasVisibleSearchField: false, searchTerm: '' },
      })
    : (state[action.payload.tableName] = {
        tableConfigurations: {
          [id]: {
            rowsPerPage,
            currentPage: 0,
            filters,
            columns,
            searchData: { hasVisibleSearchField: false, searchTerm: '' },
          },
        },
      });
};

export const resetTableConfiguration: CaseReducer<
  TablesManagementState,
  PayloadAction<{
    tableName: string;
    company: Pick<CompanyResponse, 'id' | 'customFields'>;
    tableData: readonly any[];
  }>
> = (state, action) => {
  const { id, customFields } = action.payload.company;
  const tableData = action.payload.tableData;
  const columns: { [key: string]: boolean } = {};
  const rowsPerPage: number = 25;
  const filters: any = { items: [] };
  const searchData = { hasVisibleSearchField: false, searchTerm: '' };

  if (tableData.length > 0) {
    const properties = Object.keys(tableData[0]);

    properties.forEach(property => {
      if (property !== 'customFields') {
        if (property === 'id') {
          columns['userId'] = true;
        } else {
          columns[property] = true;
        }
      }
    });
  }

  customFields.forEach(customField => {
    columns[customField.key] = true;
  });

  state[action.payload.tableName].tableConfigurations[id] = {
    rowsPerPage,
    currentPage: 0,
    filters,
    columns,
    searchData,
  };

  sessionStorage.setItem(
    `ccp_${action.payload.tableName}_table_configuration_columns_${id}`,
    JSON.stringify(state[action.payload.tableName].tableConfigurations[id].columns),
  );

  sessionStorage.setItem(
    `ccp_${action.payload.tableName}_table_configuration_rows_per_page_${id}`,
    JSON.stringify(rowsPerPage),
  );

  sessionStorage.setItem(
    `ccp_${action.payload.tableName}_table_configuration_filters_${id}`,
    JSON.stringify(state[action.payload.tableName].tableConfigurations[id].filters),
  );
};

export const toggleColumn: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number; property: string; visible: boolean }>
> = (state, action) => {
  const { tableName, companyId, property, visible } = action.payload;
  state[tableName].tableConfigurations[companyId].columns[property] = visible;
  sessionStorage.setItem(
    `ccp_${tableName}_table_configuration_columns_${companyId}`,
    JSON.stringify(state[tableName].tableConfigurations[companyId].columns),
  );
};

export const toggleAllColumns: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number; visible: boolean }>
> = (state, action) => {
  const { tableName, companyId, visible } = action.payload;
  const properties = Object.keys(state[tableName].tableConfigurations[companyId].columns);

  const toggledProperties: { [key: string]: boolean } = {};
  properties.forEach(property => {
    if (property !== 'custom') toggledProperties[property] = visible;
  });

  sessionStorage.setItem(
    `ccp_${tableName}_table_configuration_columns_${companyId}`,
    JSON.stringify(toggledProperties),
  );
  state[tableName].tableConfigurations[companyId].columns = toggledProperties;
};

export const updateRowsPerPage: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number; rowsPerPage: number }>
> = (state, action) => {
  const { tableName, companyId, rowsPerPage } = action.payload;
  state[tableName].tableConfigurations[companyId].rowsPerPage = rowsPerPage;
  sessionStorage.setItem(
    `ccp_${tableName}_table_configuration_rows_per_page_${companyId}`,
    JSON.stringify(rowsPerPage),
  );
};

export const updateCurrentPage: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number; currentPage: number }>
> = (state, action) => {
  const { tableName, companyId, currentPage } = action.payload;
  state[tableName].tableConfigurations[companyId].currentPage = currentPage;
};

export const updateFilters: CaseReducer<
  TablesManagementState,
  PayloadAction<{
    tableName: string;
    companyId: number;
    model: GridFilterModel;
  }>
> = (state, action) => {
  const { tableName, companyId, model } = action.payload;
  model.items.forEach(filter => {
    if (filter.value && typeof filter.value.getMonth === 'function') {
      filter.value = filter.value.toLocaleDateString('en-US');
    }
  });

  state[tableName].tableConfigurations[companyId].filters = model;

  sessionStorage.setItem(
    `ccp_${tableName}_table_configuration_filters_${companyId}`,
    JSON.stringify(state[tableName].tableConfigurations[companyId].filters),
  );
};

export const resetFilters: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number }>
> = (state, action) => {
  const { tableName, companyId } = action.payload;
  state[tableName].tableConfigurations[companyId].filters = { items: [] };
  state[tableName].tableConfigurations[companyId].searchData = {
    hasVisibleSearchField: false,
    searchTerm: '',
  };
  if (state[tableName].tableConfigurations[companyId].currentPage !== 0) {
    state[tableName].tableConfigurations[companyId].currentPage = 0;
  }

  sessionStorage.setItem(
    `ccp_${tableName}_table_configuration_filters_${companyId}`,
    JSON.stringify(state[tableName].tableConfigurations[companyId].filters),
  );
};

export const updateSearchTerm: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number; searchTerm: string }>
> = (state, action) => {
  const { tableName, companyId, searchTerm } = action.payload;
  state[tableName].tableConfigurations[companyId].searchData.searchTerm = searchTerm;
  if (state[tableName].tableConfigurations[companyId].currentPage !== 0) {
    state[tableName].tableConfigurations[companyId].currentPage = 0;
  }
};

export const updateSearchVisibility: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number; hasVisibleSearchField: boolean }>
> = (state, action) => {
  const { tableName, companyId, hasVisibleSearchField } = action.payload;
  state[tableName].tableConfigurations[companyId].searchData.hasVisibleSearchField =
    hasVisibleSearchField;
};

export const resetSearchData: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number }>
> = (state, action) => {
  const { tableName, companyId } = action.payload;
  state[tableName].tableConfigurations[companyId].searchData = {
    hasVisibleSearchField: false,
    searchTerm: '',
  };
  if (state[tableName].tableConfigurations[companyId].currentPage !== 0) {
    state[tableName].tableConfigurations[companyId].currentPage = 0;
  }
};
