import { Typography } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { AntSwitch, TickboxWrapper } from './Tickbox.styled';

const Tickbox: React.FC<{
  label: string;
  fieldName: string;
  value: boolean;
  disabled?: boolean;
  handleChange: (key: string, value: boolean) => void;
}> = ({ label, fieldName, value, disabled = false, handleChange }) => {
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleChange(fieldName, (event.target as HTMLInputElement).checked);
    },
    [fieldName, value],
  );

  return (
    <TickboxWrapper
      label={
        <Typography variant="subtitle1" marginLeft={'10px'} fontWeight={value ? 'bold' : ''}>
          {label}
        </Typography>
      }
      control={<AntSwitch name={fieldName} checked={value} onChange={onChange} disabled={disabled} />}
    />
  );
};

export default Tickbox;
