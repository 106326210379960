import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { ISelfRegistration, SettingsState } from 'types';

export const selfRegistration: CaseReducer<
  SettingsState,
  PayloadAction<{ key: keyof ISelfRegistration; value: any }>
> = (state, { payload }) => {
  if (payload.key && state.selfRegistration.hasOwnProperty(payload.key)) {
    (state.selfRegistration[payload.key] as any) = payload.value;
  }
};
