import { styled, TableHead, TableBody, TableRow } from '@mui/material';
import { Box } from '@mui/system';

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '.table_cell': {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    color: theme.palette.neutralDark.main,
    padding: '14px 16px 12px',
    borderBottom: `2px solid ${theme.palette.neutralLight.light}`,
    background: theme.palette.primaryLight.light,
    textTransform: 'uppercase',
  },
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  td: {
    padding: '10px 16px',
    borderBottom: `2px solid ${theme.palette.neutralLight.light}`,
  },
  '.email': {
    fontSize: 16,
    lineHeight: 1.38,
  },
  '.product': {
    '& .productName': {
      borderRadius: 11,
      padding: '1px 12px',
      border: `1px solid  ${theme.palette.neutralLight.dark}`,
      marginRight: 12,
    },
    '& .more': {
      color: theme.palette.primary.main,
      borderRadius: 11,
      border: `1px solid ${theme.palette.primary.main}`,
      padding: '1px 12px',
    },
  },
  '.event > p': {
    textTransform: 'capitalize',
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  background: theme.palette.primaryLight.light,
}));

export const ShowAllDataWrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  cursor: 'pointer',
  marginTop: 30,
  color: theme.palette.primary.main,
  fontFamily: 'Roboto-bold',
  fontSize: 16,
}));
