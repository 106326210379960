import { styled, Box } from '@mui/material';

export const StatsCardWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  borderRadius: 4,
  background: theme.palette.neutralLight.light,
  padding: '36px 36px 38px',

  '&:first-child .cards__item_info_title': {
    color: theme.palette.primary.main,
  },

  '&:last-child .cards__item_info_title': {
    // color: {theme.palette.warning.main},
  },

  '& .cards__item_info_empty': {
    backgroundColor: theme.palette.transparent.blue05,
    '& .cards__item_info_text': {
      textAlign: 'center',
    },
  },

  a: {
    textDecoration: 'unset',
  },

  '&_info': {
    gap: 4,
    minHeight: 182,
    border: `2px solid ${theme.palette.primaryLight.main}`,
    padding: 16,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',

    '&_title': {
      fontSize: 36,
      fontFamily: 'NutmegHeadline-UltraBlack',
      fontWeight: 900,
      color: theme.palette.success.main,
    },

    '&_text': {
      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.main,
    },
  },

  '&_title': {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 20,
    fontWeight: 900,
    lineHeight: 1.6,
    color: theme.palette.neutralDark.contrastText,
    textTransform: 'uppercase',
    margin: '48px 0 16px',
    textAlign: 'center',
  },

  '&_text': {
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
    marginBottom: 36,
    textAlign: 'center',
  },

  button: {
    width: '100%',
  },
}));
