import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { formatNumber } from 'utils';
import { StatsPageProps } from 'types';

import { StatsCardWrapper } from './StatsCard.styles';
import { useIntl } from 'react-intl';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { CCPDefaultButton } from '@components/index';
import { default as theme } from '@theme/theme';

const StatsCard: React.FC<StatsPageProps> = ({ item, count, hasError }) => {
  const { title, description, link } = item;
  const intl = useIntl();

  return (
    <StatsCardWrapper className='cards__item'>
      <Box className={`cards__item_info ${hasError ? 'cards__item_info_empty' : ''}`}>
        {!hasError && (
          <>
            <Typography className='cards__item_info_title' variant='body1'>
              {formatNumber(count)}
            </Typography>
            <Typography className='cards__item_info_text' variant='body1'>
              {title}
            </Typography>
          </>
        )}
        {hasError && (
          <>
            <ErrorOutline
              htmlColor={theme.palette.error.main}
              className='cards__item_info_iconError'
            />
            <Typography className='cards__item_info_error' variant='body1'>
              {intl.formatMessage({
                id: 'statistic.card.error',
                defaultMessage: 'Data can’t be loaded',
              })}
            </Typography>
          </>
        )}
      </Box>
      <Typography className='cards__item_title' variant='body1'>
        {title}
      </Typography>
      <Typography className='cards__item_text' variant='body1'>
        {description}
      </Typography>
      {!hasError && (
        <Link to={link}>
          <CCPDefaultButton variant='outlined'>
            {intl.formatMessage(
              {
                id: 'cards.item.info.title_show',
                defaultMessage: 'Show',
              },
              { title },
            )}
          </CCPDefaultButton>
        </Link>
      )}
    </StatsCardWrapper>
  );
};

export default StatsCard;
